import { useNavigate } from "react-router-dom";
import Routes from "router/routes";
import CategoriesComponent from "../components/categoriesComponent";
import React, { useState } from "react";
import TestimonialGrid from "../components/testimonialGrid";
import FAQ from "../components/faqComponent";
import CenteredCarousel, {
  CarouselItems,
} from "../components/centeredCarousel";
import { InnerFundRaisingButton } from "../components/innerFundraisingButton";
import JoinGivSection from "features/landing/presentation/components/joinGivSection";
import WebsiteFooter from "common/layout/websiteFooter";

interface ICollapsibleItems {
  id: string;
  title: string;
  description: string;
}

interface OpenItemsState {
  [key: string]: boolean;
}

const HomePage = () => {
  const navigate = useNavigate();
  const [openItems, setOpenItems] = useState<OpenItemsState>({});

  const toggleItem = (id: string) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const collapseItems: Array<ICollapsibleItems> = [
    {
      id: "1",
      title: "Step 1: Set Up Your Fundraiser",
      description:
        "Create your campaign in minutes—set a target, share your story, and add a photo or video. Whether it's for a charity, a special event, or a personal goal, Giv makes it simple to get started.",
    },
    {
      id: "2",
      title: "Step 2: Share with Your Community",
      description:
        "Use built-in sharing tools to spread the word! Share your campaign link on social media, email, or messaging apps to invite friends, family, and supporters to contribute.",
    },
    {
      id: "3",
      title: "Step 3: Receive Contributions",
      description:
        "Watch contributions roll in securely, track your progress in real-time, and easily thank each donor with a single click. Stay connected with your supporters through personalized messages and updates as you work towards your goal!",
    },
  ];

  const carouselItems: Array<CarouselItems> = [
    {
      id: "1",
      title: "HERITAGE HOMES",
      description: "Raised $15,000",
      image: "/assets/images/carousel-1.svg",
    },
    {
      id: "2",
      title: "HERITAGE HOMES",
      description: "Raised $15,000",
      image: "/assets/images/carousel-1.svg",
    },
    {
      id: "3",
      title: "HERITAGE HOMES",
      description: "Raised $15,000",
      image: "/assets/images/carousel-1.svg",
    },
    {
      id: "4",
      title: "HERITAGE HOMES",
      description: "Raised $15,000",
      image: "/assets/images/carousel-1.svg",
    },
    {
      id: "5",
      title: "HERITAGE HOMES",
      description: "Raised $15,000",
      image: "/assets/images/carousel-1.svg",
    },
  ];

  const StartFundraisingButton = (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className="flex h-[56px] w-[245px] transform items-center justify-center rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:h-[72px]"
    >
      <span className="mr-2">Start Fundraising</span>
      <img className="h-5 w-5" src="/assets/icons/Arrow.svg" alt="arrow" />
    </button>
  );

  return (
    <>
      <div className="mx-[20px] mt-[56px] flex max-w-[1440px] flex-col items-center justify-center lg:mx-[80px] lg:mt-[13px] lg:flex-row">
        <div className="flex-1 items-center justify-center">
          <h1 className="font-dm-sans text-[48px] font-medium leading-[60px] tracking-[-0.04em] text-neutral-900 lg:text-left lg:text-[100px] lg:font-medium lg:leading-[120px] lg:tracking-[-0.09em]">
            The Platform <br />
            For Charity & Contributions
          </h1>
          <div className="flex lg:mt-[56px]">{StartFundraisingButton}</div>
        </div>

        <div className="flex h-[764px] flex-1 justify-center">
          <img
            src="/assets/images/site-right-display.svg"
            alt="Site Display"
            height="100%"
          />
        </div>
      </div>
      <CategoriesComponent />
      <div className="flex flex-col items-center justify-center bg-[#FFF3ED] px-7 lg:h-[755px] lg:flex-row lg:px-20">
        {/* First Section */}
        <div className="mt-[56px] flex h-full flex-1 lg:mr-[64px]">
          <img
            src="/assets/images/financial-aid.svg"
            alt="financial aid"
            className=""
          />
        </div>
        <div className="h-[300px] flex-1 flex-col">
          <p className="mt-12 font-dm-sans text-[42px] font-medium leading-[50px] tracking-[-0.03em] md:text-[56px] md:leading-[65px] lg:text-[72px] lg:leading-[80px] lg:tracking-[-0.06em]">
            Financial Aid <br />
            Without Boundaries.
          </p>
          <p className="mt-4 w-full text-left font-inter text-body-xl font-thin text-[#5A5A59] md:max-w-xl lg:mt-[32px] lg:w-[603px]">
            Fundraising has been made transparent, making it easy to help those
            in need to raise funds and receive their donations without the
            hindrances experienced with using most platforms.
          </p>
          <InnerFundRaisingButton isTransparent={false} className="" />
        </div>
      </div>

      {/* Second Section */}
      <div className="flex flex-col items-center justify-center bg-[#FFF3ED] px-7 lg:min-h-[755px] lg:flex-row lg:px-20">
        <div className="order-1 mt-[56px] flex h-full flex-1 lg:order-2 lg:mr-[64px]">
          <img
            src="/assets/images/private-contributions.svg"
            alt="private contributions"
            className=""
          />
        </div>
        <div className="order-2 flex flex-1 flex-col md:px-4 lg:order-1 lg:mt-[189px]">
          <p className="mt-12 font-dm-sans text-[42px] font-medium leading-[50px] tracking-[-0.03em] md:text-[56px] md:leading-[65px] lg:text-[72px] lg:leading-[80px] lg:tracking-[-0.06em]">
            Make Private <br />
            Contributions
          </p>
          <p className="mt-4 w-full text-left font-inter text-body-xl font-thin text-[#5A5A59] md:max-w-xl lg:mt-[32px] lg:w-[603px]">
            Giv makes fundraising perfectly organised for private financial
            targets towards personal or group projects and events.
          </p>
          <div className="mb-8">
            <InnerFundRaisingButton isTransparent={false} />
          </div>
        </div>
      </div>
      {/** Launch your fundraiser Section **/}
      <div className="mb-[36px] mt-[16px] flex max-h-[1048px] flex-col px-[16px] lg:mb-[120px] lg:mt-[120px] lg:flex-row lg:px-[75px]">
        <div className="order-3 flex max-h-[808px] flex-1 items-center justify-center lg:order-1">
          {" "}
          <img
            src="/assets/images/start-giving.svg"
            alt="start giving"
            className=""
          />
        </div>
        <div className="order-1 flex-1 px-[16px] lg:order-2 lg:ml-[64px] lg:pl-[96px]">
          <p className="font-dm-sans text-[42px] font-medium leading-[50px] tracking-[-0.03em] md:text-[56px] md:leading-[65px] lg:mt-[43px] lg:text-[72px] lg:leading-[80px] lg:tracking-[-0.06em]">
            Launch your Fundraiser in just 3 steps
          </p>

          <div className="mt-8 space-y-6 md:mt-12">
            {collapseItems.map((item) => (
              <div
                key={item.id}
                className={`transition-all duration-300 ${
                  openItems[item.id] ? "rounded-xl bg-[#F2FFF8] p-6" : ""
                }`}
              >
                <button
                  onClick={() => toggleItem(item.id)}
                  className="flex w-full items-center justify-between"
                >
                  <div className="flex items-center gap-4">
                    <div
                      className={`text-lg md:text-xl lg:text-2xl flex h-8 w-8 items-center justify-center rounded-full transition-colors md:h-10 md:w-10 lg:h-12 lg:w-12 ${openItems[item.id] ? "bg-[#4FBA6D] text-white" : "bg-gray-100 text-gray-600"} `}
                    >
                      {item.id}
                    </div>
                    <h2 className="text-lg md:text-xl lg:text-2xl text-left font-medium text-[#022928]">
                      {item.title}
                    </h2>
                  </div>
                  <img
                    src={`/assets/icons/${openItems[item.id] ? "Arrow-Up" : "Arrow-Down"}.svg`}
                    className="h-6 w-6"
                    alt="toggle arrow"
                  />
                </button>

                {openItems[item.id] && (
                  <div className="text-base md:text-lg lg:text-xl ml-12 mt-4 text-gray-500 md:ml-14 lg:ml-16">
                    {item.description}
                  </div>
                )}
              </div>
            ))}
          </div>

          <InnerFundRaisingButton
            isTransparent={false}
            className="my-8 md:my-12 lg:my-16"
          />
        </div>
      </div>

      {/** display dart here **/}
      <div className="hidden min-h-[2647.23px] flex-col items-center bg-[#022928] lg:flex">
        <div className="flex w-full flex-row justify-center lg:mt-[120px]">
          <img
            src="/assets/icons/dart.svg"
            alt="dart"
            className="mr-[30px] h-32 w-32"
          />
          <p className="w-[722px] text-center font-dm-sans text-[64px] font-medium leading-[64px] tracking-[-0.06em] text-[#FFF3ED]">
            Making Impact, <br />
            One Fundraiser at a Time.
          </p>
        </div>
        {/* <div className="flex max-w-[1280px] flex-col lg:ml-[80px] lg:mr-[80px] lg:mt-[96px] lg:flex-row"> */}
        <div className="flex max-h-[473.81px] w-full max-w-[1280px] flex-col p-4 lg:mt-[96px] lg:flex-row">
          <div className="flex-1">
            <img src="/assets/images/create-account.svg" alt="create account" />
          </div>
          <div className="flex flex-1 flex-col items-start justify-center lg:pl-[96px]">
            <p className="text-[58px] leading-[64px] tracking-[-2px] text-white">
              Perfect for All Kinds of Needs
            </p>
            <p className="text-lg leading-7 text-left font-inter font-normal tracking-[-0.25px] text-white lg:mt-[32px]">
              Organise private fundraisers for fun group goals and events or
              campaigns to solicit donations from the public.
            </p>
            <InnerFundRaisingButton
              isTransparent={true}
              className="mt-[40px]"
            />
          </div>
        </div>
        <div className="flex max-h-[473.81px] w-full max-w-[1280px] flex-col p-4 lg:mt-[96px] lg:flex-row">
          <div className="flex-1">
            <p className="text-[58px] leading-[64px] tracking-[-2px] text-white">
              Contribute & Receive in Naira
            </p>
            <p className="text-lg leading-7 text-left font-inter font-normal tracking-[-0.25px] text-white lg:mt-[32px]">
              Simplify the fundraising process for your givers and help them
              avoid international rates with every donation.
            </p>
            <InnerFundRaisingButton
              isTransparent={true}
              className="mt-[40px]"
            />
          </div>
          <div className="flex flex-1 flex-col items-start justify-center lg:pl-[96px]">
            <img
              src="/assets/images/fundraiser-target.svg"
              alt="fundraiser target"
            />
          </div>
        </div>
        <div className="flex max-h-[473.81px] w-full max-w-[1280px] flex-col p-4 lg:mt-[96px] lg:flex-row">
          <div className="flex-1">
            <img
              src="/assets/images/fundraiser-published.svg"
              alt="fundraiser published"
            />
          </div>
          <div className="flex flex-1 flex-col items-start justify-center lg:pl-[96px]">
            <p className="text-[58px] leading-[64px] tracking-[-2px] text-white">
              Share Your Campaign Easily
            </p>
            <p className="text-lg leading-7 text-left font-inter font-normal tracking-[-0.25px] text-white lg:mt-[32px]">
              Amplify your cause and easily reach more donors with a one-click
              across all platforms.
            </p>
            <InnerFundRaisingButton
              isTransparent={true}
              className="mt-[40px]"
            />
          </div>
        </div>
        <div className="flex max-h-[473.81px] w-full max-w-[1280px] flex-col p-4 lg:mt-[96px] lg:flex-row">
          <div className="flex-1">
            <p className="text-[58px] leading-[64px] tracking-[-2px] text-white">
              Every Campaign is Held Accountable
            </p>
            <p className="text-lg leading-7 text-left font-inter font-normal tracking-[-0.25px] text-white lg:mt-[32px]">
              Share the necessary verification requirements to ensure your
              campaign is run with trust and credibility.
            </p>
            <InnerFundRaisingButton
              isTransparent={true}
              className="mt-[40px]"
            />
          </div>
          <div className="flex flex-1 flex-col items-start justify-center lg:pl-[96px]">
            <img
              src="/assets/images/funding-comments.svg"
              alt="fundraiser target"
            />
          </div>
        </div>
      </div>
      {/* Completed Fundraisers carousel */}
      <div className="hidden h-[999px] flex-col items-center bg-[#FFF3ED]">
        <p className="w-[897px] text-center font-dm-sans text-[64px] font-medium leading-[64px] tracking-[-0.06em] lg:mt-[120px]">
          Fundraisers Fuelling Change & <br />
          Compassion
        </p>
        <CenteredCarousel items={carouselItems} />
      </div>
      <div className="relative flex min-h-[200px] flex-col items-center bg-[#022928] lg:h-[1000px]">
        <div className="mt-[56px] flex w-full flex-row justify-center lg:mt-[120px]">
          <img
            src="/assets/icons/messaging.svg"
            alt="messaging"
            className="ml-[39px] mr-4 h-[60px] w-[40px] lg:mr-8 lg:h-auto lg:w-auto"
          />

          <p className="mr-4 text-center font-dm-sans text-[42px] font-medium leading-[42px] tracking-[-0.03em] text-white lg:mr-8 lg:text-[72px] lg:leading-[72px]">
            Voices of Gratitude
          </p>
          <img
            src="/assets/icons/heart-chat.svg"
            alt="messaging"
            className="mr-[32.81px] h-[60px] w-[40px] lg:h-auto lg:w-auto"
          />
        </div>
        <TestimonialGrid />
      </div>
      <JoinGivSection />
      <div className="lg:mt-[120px]">
        <FAQ />
      </div>
      <WebsiteFooter />
    </>
  );
};

export default HomePage;
