import { FC, useEffect } from "react";
import KroTextField from "features/components/inputs/kroTextField";
import KroDropdown from "features/components/dropdowns/kroDropdown";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useFetchCampaignCategories } from "../hooks/useFetchCampaignCategories";
import { useUpdateCampaignInformation } from "../hooks/useUpdateCampaignInformation";
import {
  CampaignVisibility,
  UpdateCampaignInformationPayloadWithPrivacy
} from "features/campaign/domain/models/updateCampaignInformationPayload";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateCampaignForm } from "../context/CreateCampaignFormProvider";
import Routes from "router/routes";
import { useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import { RELATIONSHIP_TYPES } from "../forms/campaignInformation";
import CampaignCounterComponent from "../components/campaignCreate/campaignPageCounterComponent";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import KroRadioButtonGroup from "features/components/inputs/KroRadioButtonGroup";

const STORY_MAX_COUNT = 200;

const CampaignInformationPage: FC = () => {
  const { formData, setFormData, validateStepAccess } = useCreateCampaignForm();
  const navigate = useNavigate();
  // const { showSnackbar } = useSnackbar();

  const location = useLocation();
  useEffect(() => {
    validateStepAccess(location.pathname);
  }, [location, validateStepAccess]);

  const {
    categories,
    isLoading: isFetchingCategories,
    error
  } = useFetchCampaignCategories();
  const form = useForm<{
    title: string;
    visibility: CampaignVisibility;
    category: string;
    story: string;
    beneficiaryName: string;
    relationshipWithBeneficiary: string;
  }>({
    defaultValues: {
      title: formData.title ?? "",
      visibility: formData.visibility,
      category: formData.category ?? "",
      story: formData.story ?? "",
      beneficiaryName:
        (formData.beneficiaryName ?? formData.campaignType !== 1) ? "N/A" : "",
      relationshipWithBeneficiary: formData.relationshipWithBeneficiary ?? ""
    },

    onSubmit: (values) => {
      const payload: UpdateCampaignInformationPayloadWithPrivacy = {
        id: formData.id,
        beneficiaryName: values.value.beneficiaryName ?? "N/A",
        relationshipWithBeneficiary: values.value.relationshipWithBeneficiary,
        category: values.value.category,
        story: values.value.story,
        visibility: values.value.visibility,
        title: values.value.title
      };

      mutation.mutate(payload);
    }
  });

  const mutation = useUpdateCampaignInformation(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: Boolean, variables: UpdateCampaignInformationPayloadWithPrivacy) => {
      setFormData((prevData) => ({
        ...prevData,
        category: variables.category,
        story: variables.story,
        visibility: variables.visibility,
        title: variables.title,
        beneficiaryName: variables.beneficiaryName,
        relationshipWithBeneficiary: variables.relationshipWithBeneficiary
      }));
      navigate(Routes.CREATE_CAMPAIGN_GALLERY);
    }
  );

  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.CREATE_CAMPAIGN_INFORMATION);
    }
  };

  if (error) {
    return (
      <KroLoadingIndicator
        error={error?.message}
        onRetry={() => window.location.reload()}
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="flex h-full w-full max-w-[606px] animate-fadeIn flex-col"
    >
      <div className="flex w-full flex-1 flex-col md:max-w-[500px]">
        <div className="md:my-auto md:pt-14">
          <CampaignCounterComponent
            pageCount={2}
            title={"Enter fundraiser details"}
            showSkip={false}
          />
          <form.Field
            name="title"
            validators={{
              onChange: ({ value }) => Validation.required(value)
            }}
            children={(field) => {
              return (
                <KroTextField
                  id={field.name}
                  name={field.name}
                  errorMessage={field.state.meta.errors.join(", ")}
                  value={field.state.value}
                  type={KroInputFieldType.TEXT}
                  onChange={(e) => field.handleChange(e.target.value)}
                  label="Give your fundraiser a title"
                  placeHolder="Donate to help..."
                />
              );
            }}
          />

          <form.Field
            name="category"
            validators={{
              onChange: ({ value }) => Validation.required(value)
            }}
            children={(field) => {
              return (
                <KroDropdown
                  className="mb-6"
                  id={field.name}
                  errorMessage={field.state.meta.errors.join(", ")}
                  options={
                    !isFetchingCategories
                      ? (categories?.map((category) => category.name) ?? [])
                      : []
                  }
                  label="What type of fundraiser is it?"
                  onDropdownItemClicked={(option) => field.handleChange(option)}
                  placeHolder="Select fundraiser type"
                  selectedValue={field.state.value}
                />
              );
            }}
          />

          <form.Field
            name="visibility"
            validators={{
              onChange: ({ value }) => {
                return Validation.required(value);
              }
            }}
            children={(field) => (
              <div className="flex w-full flex-col gap-2 mb-[14px]" id={field.name}>
                <label
                  className="text-md font-medium-regular mb-2 block text-left font-inter leading-[22px] tracking-[-0.005em]">
                  How visible do you want it to be?{" "}
                  <span className="italic text-red-500">
                    {field.state.meta.errors.join(", ")}
                  </span>
                </label>
                <KroRadioButtonGroup
                  name={field.name}
                  options={[
                    {
                      id: "public",
                      label: "Public - Your campaign will be visible on the Giv website. It's best for public outreach campaigns.",
                      value: "public"
                    },
                    {
                      id: "private",
                      label: "Private - Your campaign will stay hidden. Only people you share your campaign link with will see it.",
                      value: "private"
                    }
                  ]}
                  onChange={(value) => {
                    field.handleChange(value === "public" ? "public" : "private");
                  }}
                  selectedValue={field.state.value}
                  direction="column"
                />
              </div>
            )}
          />

          {formData.campaignType === 1 && (
            <form.Field
              name="beneficiaryName"
              validators={{
                onChange: ({ value }) => {
                  const hasValue = Validation.required(value);

                  if (hasValue) return;

                  return Validation.minLength(value, 7);
                }
              }}
              children={(field) => {
                return (
                  <KroTextField
                    id={field.name}
                    name={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    value={field.state.value}
                    type={KroInputFieldType.TEXT}
                    onChange={(e) => {
                      if (/\d/.test(e.target.value)) return;

                      return field.handleChange(e.target.value);
                    }}
                    label="What is the beneficiary's name"
                    placeHolder="Enter their name here"
                  />
                );
              }}
            />
          )}
          {formData.campaignType === 1 && (
            <form.Field
              name="relationshipWithBeneficiary"
              validators={{
                onChange: ({ value }) => Validation.required(value)
              }}
              children={(field) => {
                return (
                  <KroDropdown
                    className="mb-6"
                    id={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    options={RELATIONSHIP_TYPES}
                    label="How do you know the beneficiary"
                    onDropdownItemClicked={(option) => {
                      field.handleChange(option);
                    }}
                    customHint="Input relationship"
                    placeHolder="Select relationship"
                    selectedValue={field.state.value}
                  />
                );
              }}
            />
          )}

          <form.Field
            name="story"
            validators={{
              onChange: ({ value }) => {
                const minLengthValidation = Validation.minLength(
                  value,
                  STORY_MAX_COUNT
                );

                if (minLengthValidation !== undefined)
                  return minLengthValidation;
                return Validation.noGibberish(value);
              }
            }}
            children={(field) => {
              return (
                <>
                  <KroTextField
                    label="Tell Your Story"
                    id="story"
                    errorMessage={field.state.meta.errors.join(", ")}
                    type={KroInputFieldType.TEXTAREA}
                    placeHolder="Type here..."
                    value={field.state.value}
                    showCount={true}
                    labelClassName="mt-[24px]"
                    maxCharacters={STORY_MAX_COUNT}
                    ckEditorChange={(e) => {
                      field.handleChange(e ?? "");
                    }}
                    useRichText={true}
                  />

                  <div className="rounded-md border bg-gray-50 p-4">
                    <h3 className="mb-2 text-body-lg font-semibold">
                      Need tips for sharing a great story?
                    </h3>
                    <ul className="list-inside list-disc space-y-1 text-body-md leading-22">
                      <li>
                        Share a personal introduction about yourself or who you
                        are fundraising for.
                      </li>
                      <li>
                        Let people know why the fundraiser matters to you and
                        how their money will help.
                      </li>
                      <li>
                        Try to ensure your story is easy to read, heartfelt, and
                        memorable to inspire people to donate towards your goal.
                      </li>
                    </ul>
                  </div>
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="flex h-auto w-full flex-col-reverse justify-between gap-3 py-[51px] md:flex-row">
        <KroBackButton onclick={handleBackButton} />
        <KroButton
          type="submit"
          onclick={form.handleSubmit}
          isDisabled={isFetchingCategories}
          isLoading={mutation.isPending}
          title={"Continue"}
        />
      </div>
    </form>
  );
};

export default CampaignInformationPage;
