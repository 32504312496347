import { SettingsProps } from "features/dashboard/domain/model/settingsProps";
import { TabController } from "features/components/tab/kroTabBar";
import KroToggleSwitch from "features/components/inputs/kroToggleSwitch";
import { useState } from "react";
import { UserModel } from "common/models/userModel";
import { useUpdatePrivacySettings } from "../../hooks/useUpdateSettings";
import { toast } from "react-toastify";

const LoginAndSecurityTab = ({
  tabController,
  userProfile,
}: {
  tabController: TabController;
  userProfile: UserModel;
}) => {
  const [privacySettings, setPrivacySettings] = useState({
    privateCampaigns:
      userProfile.privacySettings?.makeCampaignsPrivate ?? false,
    privateProfile: userProfile.privacySettings?.keepProfilePrivate ?? false,
    anonymousDonations: userProfile.privacySettings?.donateAnonymously ?? false,
  });

  const updatePrivacyMutation = useUpdatePrivacySettings(
    (error) => {
      toast.error(error.message);
    },
    () => {
      toast.success("Privacy settings updated successfully");
    },
  );

  const handleToggle = (setting: string) => {
    const updatedSettings = {
      ...privacySettings,
      [setting]: !privacySettings[setting as keyof typeof privacySettings],
    };
    setPrivacySettings(updatedSettings);

    // Transform to match API payload
    const payload = {
      make_campaigns_private: updatedSettings.privateCampaigns,
      keep_profile_private: updatedSettings.privateProfile,
      donate_anonymously: updatedSettings.anonymousDonations,
    };
    updatePrivacyMutation.mutate(payload);
  };

  const accountPrivacyList: Array<SettingsProps> = [
    {
      title: "Make Campaigns Private",
      description: "Don't display my campaigns publicly by default.",
      enabled: privacySettings.privateCampaigns,
      key: "privateCampaigns",
    },
    {
      title: "Keep Profile Private",
      description: "Don't display my profile publicly by default.",
      enabled: privacySettings.privateProfile,
      key: "privateProfile",
    },
  ];

  const donationsList: Array<SettingsProps> = [
    {
      title: "Donate Anonymously",
      description: "Don't display my name publicly on fundraisers by default.",
      enabled: privacySettings.anonymousDonations,
      key: "anonymousDonations",
    },
  ];

  return (
    <div className="min-h-[calc(100vh-400px)]">
      <div className="mt-[40px]">
        <div className="border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
          <p className="mb-[10px]">ACCOUNT PRIVACY</p>
        </div>
        {accountPrivacyList.map((setting, index) => (
          <div className="mt-[24px] flex flex-row gap-[24px]" key={index}>
            <div className="w-full flex-1">
              <p className="mb-[4px] font-dm-sans text-[14px] font-semibold leading-[20px] tracking-[-0.6%] text-strong-950">
                {setting.title}
              </p>
              <p className="font-dm-sans text-[14px] leading-[18px] tracking-[0%] text-sub-600">
                {setting.description}
              </p>
            </div>
            <div className="flex-2">
              <KroToggleSwitch
                isOn={setting.enabled}
                handleToggle={() => handleToggle(setting.key!)}
                disabled={updatePrivacyMutation.isPending}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-[40px]">
        <div className="border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
          <p className="mb-[10px]">DONATIONS</p>
        </div>
        {donationsList.map((setting, index) => (
          <div className="mt-[24px] flex flex-row gap-[24px]" key={index}>
            <div className="w-full flex-1">
              <p className="mb-[4px] font-dm-sans text-[14px] font-semibold leading-[20px] tracking-[-0.6%] text-strong-950">
                {setting.title}
              </p>
              <p className="font-dm-sans text-[14px] leading-[18px] tracking-[0%] text-sub-600">
                {setting.description}
              </p>
            </div>
            <div className="flex-2">
              <KroToggleSwitch
                isOn={setting.enabled}
                handleToggle={() => handleToggle(setting.key!)}
                disabled={updatePrivacyMutation.isPending}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoginAndSecurityTab;
