import KroModal from "features/components/modals/kroModal";
import CurrentAndClosedCampaignComponent from "../components/profile/currentAndClosedCampaignComponent";
import LinkHolderComponent from "../components/profile/linkHolderComponent";
import ProfileSocialMediaComponent from "../components/profile/profileSocialMediaComponent";
import SocialMediaModal from "../components/profile/socialMediaModal";
import { useState } from "react";
import KroProfileShareModal from "../components/profile/profileShareModal";
import useFetchProfile from "../hooks/useFetchProfile";
import { getSessionUser } from "common/store/useAuthStore";
import { UserType } from "../../domain/enums/userType.enum";
import { useNavigate, useParams } from "react-router-dom";
import SocialMediaPill from "../components/profile/socialMediaPill";
import Routes from "router/routes";
// import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const [isSocialMediaModalOpen, setSocialMediaModalOpen] =
    useState<boolean>(false);

  const { slug } = useParams<{ slug: string }>();

  const closeSocialMediaModal = () => {
    setSocialMediaModalOpen(false);
  };

  const openSocialMediaModal = () => {
    setSocialMediaModalOpen(true);
  };

  const openProfileShareModal = () => {
    setShowProfileShareModal(true);
  };

  const closeProfileShareModal = () => {
    setShowProfileShareModal(false);
  };
  const user = getSessionUser();

  const [showProfileShareModal, setShowProfileShareModal] = useState(false);

  const { isLoading, profile } = useFetchProfile(slug ?? "");

  const navigate = useNavigate();

  if (isLoading) return <ProfilePageShimmer isLoading />;

  const initials =
    profile?.userType === UserType.individual
      ? profile?.firstName?.at(0) + "" + profile?.lastName?.at(0)
      : profile?.organisationName?.split(" ")[0]?.at(0)?.toUpperCase() +
      "" +
      profile?.organisationName?.split(" ")[1]?.at(0)?.toUpperCase();

  const fullName =
    profile?.userType === UserType.individual
      ? `${profile?.firstName} ${profile?.lastName}`
      : profile?.organisationName;

  return (
    <div className="overflow-hidden">
      <KroProfileShareModal
        profileId={slug ?? ""}
        isOpen={showProfileShareModal}
        onClose={closeProfileShareModal}
      />
      <KroModal
        closeOnOutsideClick={true}
        maxWidth={608}
        className="px-0 py-0"
        title="Add Social Media"
        maxHeight={626}
        isOpen={isSocialMediaModalOpen}
        onClose={closeSocialMediaModal}
      >
        <SocialMediaModal onClose={closeSocialMediaModal} profile={profile} />
      </KroModal>
      <div className="mt-[40px] h-[120px] lg:mt-[39px] lg:h-[160px]">
        <img
          src={profile?.bannerPicture || "/assets/images/profile-banner.svg"}
          alt="profile-banner"
          className="h-[240px] w-full max-w-full object-cover"
        />
      </div>
      <div className="mx-auto mt-[50px] flex h-full w-full max-w-[560px] flex-col items-center px-4 lg:mt-[60px]">
        <div
          className={`flex h-[120px] w-[120px] items-center justify-center rounded-[100px] border border-neutral-100 bg-[#E1E4EA]`}
        >
          {profile?.profilePicture === "" ||
          profile?.profilePicture === null ? (
            <p className="font-dm-sans text-[48px] font-semibold leading-[62.5px] text-[#3D4657]">
              {initials}
            </p>
          ) : (
            <img
              src={profile?.profilePicture}
              alt="profile-pic"
              className="rounded-full"
            />
          )}
        </div>
        <p
          className="mt-[24px] flex text-center font-dm-sans text-[20px] font-bold leading-[26.04px] lg:text-[32px] lg:leading-[33.6px]">
          {fullName}
        </p>
        {profile?.website && <LinkHolderComponent link={profile?.website} />}
        {profile?.socials === null ? (
          <ProfileSocialMediaComponent
            onClick={openSocialMediaModal}
            profileData={profile}
          />
        ) : (
          <SocialMediaPill profileData={profile} />
        )}
        {profile?.bio && (
          <div
            className="mt-[16px] text-left font-dm-sans text-[16px] font-normal leading-[24px] text-[#6F6F6F]"
            dangerouslySetInnerHTML={{
              __html: profile.bio
                .replace(/<p>(&nbsp;|\s)*<\/p>/g, "")
                .replace(/<p>/g, "<p class=\"mb-4\">")
                .trim()
            }}
          />
        )}

        <div className="flex flex-row gap-[12px]">
          <ActionButton
            title="Share"
            onClick={openProfileShareModal}
            imgSrc="/assets/icons/share_large.svg"
          />
          {user?.slug === slug && (
            <ActionButton
              title="Edit Profile"
              onClick={() => navigate(Routes.SETTINGS)}
              imgSrc="/assets/icons/edit-outline.svg"
            />
          )}
        </div>

        <CurrentAndClosedCampaignComponent slug={slug} />
      </div>
    </div>
  );
};

const ActionButton = ({
                        title,
                        onClick,
                        imgSrc,
                        isLoading
                      }: {
  title: string;
  onClick: () => void;
  imgSrc: string;
  isLoading?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`${isLoading ? "w-[140px]" : ""} mt-[24px] flex flex-row gap-[12px] rounded-[32px] border border-neutral-200 ${!isLoading ? "bg-neutral-50" : "bg-gray-200"} lg:mt-[16px] ${isLoading ? "animate-pulse" : ""}`}
    >
      <div className="mx-[16px] flex flex-row items-center justify-center">
        <p className="font-dm-sans text-[14px] font-medium leading-[18px] tracking-[-0.5%] text-[#0F0F0F]">
          {!isLoading && title}
        </p>
        <div className="my-[6px] ml-[12px] flex h-[32px] w-[32px] items-center justify-center rounded-full bg-white">
          {!isLoading ? <img src={imgSrc} alt="share" className="" /> : <></>}
        </div>
      </div>
    </button>
  );
};

export default ProfilePage;

const ProfilePageShimmer = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>
      <div className="mt-[40px] h-[120px] animate-pulse bg-gray-200 lg:mt-[39px] lg:h-[160px]">
        {!isLoading && (
          <img
            src="/assets/images/profile-banner.svg"
            alt="profile-banner"
            className="h-[110px] w-full object-cover"
          />
        )}
      </div>
      <div className="mx-auto mt-[40px] flex h-full w-full max-w-[560px] flex-col items-center px-4 lg:mt-[48px]">
        <div
          className={`flex h-[120px] w-[120px] animate-pulse items-center justify-center rounded-[100px] border border-neutral-100 bg-gray-200`}
        >
          <p className="font-dm-sans text-[48px] font-semibold leading-[62.5px] text-[#3D4657]"></p>
        </div>
        <p
          className="mt-[24px] text-center font-dm-sans text-[20px] font-bold leading-[26.04px] lg:text-[32px] lg:leading-[33.6px]"></p>
        <LinkHolderComponent link="" isLoading={isLoading} />
        <ProfileSocialMediaComponent onClick={() => {
        }} isLoading={isLoading} />
        <p
          className="mt-[16px] w-[345px] px-[24px] text-center font-dm-sans text-[16px] font-normal leading-[24px] text-[#6F6F6F]"></p>
        <div className="flex flex-row gap-[12px]">
          <ActionButton
            isLoading={isLoading}
            title="Share"
            onClick={() => {
            }}
            imgSrc="/assets/icons/share_large.svg"
          />
          <ActionButton
            isLoading={isLoading}
            title="Edit Profile"
            onClick={() => {
            }}
            imgSrc="/assets/icons/edit-outline.svg"
          />
        </div>
        <CurrentAndClosedCampaignComponent isLoading />
      </div>
    </>
  );
};
