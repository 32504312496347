export default class KroConstants {
  static readonly WHATSAPP_CS_LINK = "https://wa.me/2347012665718";
  static readonly OTP_REQUEST_TIMEOUT = 2; // inMins
  static readonly DEFAULT_COUNTRY = "NG";
  static readonly DEFAULT_COUNTRY_CODE = "+234";
  static readonly MAX_FILE_SIZE = 5; // max file size 5 inMB
  static readonly OTP_LENGTH = 6;
  static readonly AUTH_ERROR_MSG = "Please login to continue";
  static readonly STORY_MAX_COUNT = 200;
  static readonly FUNDRAISER_SUPPORT_MESSAGE =
    "Hi, I’m supporting this fundraiser on Giv. If you can, please consider supporting by donating or sharing the link. Every bit of support makes a huge difference.";
  static readonly CAMPAIGN_DEFAULT_IMAGE_URLS = new Map<string, string>([
    [
      "default",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731607448/Default_Image-2_gt9bds.png"
    ],
    [
      "Personal Support",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072754/GIV/Static/gu37yqeofrxeemmugkot.png"
    ],
    [
      "Projects & Business",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072754/GIV/Static/bahwshvfim59bjzpk5xq.png"
    ],
    [
      "wedding",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072754/GIV/Static/gu37yqeofrxeemmugkot.png"
    ],
    [
      "Clubs & Association",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072754/GIV/Static/izry7kqkygbprxgvwhvv.png"
    ],
    [
      "Healthcare",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072754/GIV/Static/dbl575attnaje6siytek.png"
    ],
    [
      "charity",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072753/GIV/Static/kgukhejp6ofanpuwfiv3.png"
    ],
    [
      "Emergencies",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072753/GIV/Static/qzzu47az0zqp1ha4erbi.png"
    ],
    [
      "Education",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072753/GIV/Static/atqjg7scmlww39xylhqq.png"
    ],
    [
      "Gift",
      "https://res.cloudinary.com/dhohhksxi/image/upload/v1731072753/GIV/Static/moidje8uzb64xg6ibifi.png"
    ]
  ]);
}
