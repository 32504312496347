import { useQuery } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { ProfileModel } from "../../domain/model/profileModel";

const DashboardService = new DashboardServiceImpl();

const useUserProfile = () => {
  const {
    data: profile,
    isLoading,
    error,
    refetch,
  } = useQuery<ProfileModel, Error>({
    queryKey: ["fetchUserProfile"],
    queryFn: () => DashboardService.fetchUserProfile(),
  });

  return { profile, isLoading, error, refetch };
};

export default useUserProfile;
