import KroClient from "common/io/client/kroClient";
import { KroEndpoints } from "common/io/endpoints";
import kroFeeModel from "../models/kroFee";
import { CampaignTypeResponse } from "../models/campaignTypeResponse";
import { UpdateCampaignInformationPayload } from "../models/updateCampaignInformationPayload";
import { CampaignCategoryModel } from "../models/campaignCategoryModel";
import { SaveVideoPayload } from "../models/saveVideoPayload";
import { UpdateCampaignDurationPayload } from "../models/updateCampaignDurationPayload";
import { UpdateCampaignTargetAmountPayload } from "../models/updateCampaignTargetAmountPayload";
import { CampaignModelMapper, mapCampaignModel } from "../models/campaignModel";
import {
  DonationResponseMapper,
  DonationResponseMapperWithAnon,
  mapDonationResponse
} from "../models/donationResponse";
import { InitializeDonationPayload } from "../models/initializeDonationPayload";
import { UpdateCampaignStatusPayload } from "../models/updateCampaignStatusPayload";
import { VerifyDonationPayload } from "../models/verifyDonationPayload";
import { DonationFeesResponse } from "../models/donationFeesResponse";
import { CommentPayload } from "../models/commentPayload";
import { CommentsResponse } from "../models/comments";
import { PaginatedResponse } from "../models/paginatedResponse";
import { CurrencyData } from "@/features/campaign/domain/models/currencies";

const CampaignRepository = () => {
  const createCampaign = async (beneficiary: string) => {
    try {
      const response = await KroClient.post(
        KroEndpoints.createCampaignEndpoint,
        {
          beneficiary: beneficiary
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const createComment = async (payload: CommentPayload) => {
    try {
      const response = await KroClient.post(
        KroEndpoints.createComment(payload.campaignId),
        {
          user_id: payload.user_id,
          text: payload.text,
          isAnonymous: payload.isAnonymous,
          full_name: payload.full_name.trim()
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetchComments = async (
    campaignId: string,
    page: number,
    limit: number
  ): Promise<PaginatedResponse<CommentsResponse>> => {
    try {
      const response = await KroClient.get(
        KroEndpoints.fetchComments(campaignId, page, limit)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const endCampaign = async (id: string): Promise<CampaignModelMapper> => {
    try {
      const response = await KroClient.patch(KroEndpoints.endCampaign(id));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fetchKroFees = async (): Promise<kroFeeModel> => {
    try {
      const response = await KroClient.get(KroEndpoints.kroFee);

      return new kroFeeModel(response.data);
    } catch (error) {
      throw error;
    }
  };

  const fetchDonationFees = async (): Promise<DonationFeesResponse> => {
    try {
      const response = await KroClient.get(KroEndpoints.donationFee);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const setCampaignType = async (
    campaignType: string
  ): Promise<CampaignTypeResponse> => {
    try {
      const response = await KroClient.post(
        KroEndpoints.createCampaignEndpoint,
        {
          beneficiary: campaignType
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fetchCampaignCategories = async (): Promise<
    Array<CampaignCategoryModel>
  > => {
    const response = await KroClient.get(KroEndpoints.fetchCampaignCategories);

    return response.data;
  };

  const saveVideoLink = async (payload: SaveVideoPayload): Promise<boolean> => {
    const response = await KroClient.patch(
      KroEndpoints.saveYoutubeLink(payload.id),
      {
        video_url: payload.link
      }
    );
    return response.data;
  };

  const updateCampaignInformation = async (
    payload: UpdateCampaignInformationPayload
  ): Promise<boolean> => {
    try {
      const response = await KroClient.patch(
        KroEndpoints.updateCampaignInformation(payload.id),
        {
          title: payload.title,
          category: payload.category,
          name_of_beneficiary: payload.beneficiaryName,
          story: payload.story,
          relationship_with_beneficiary: payload.relationshipWithBeneficiary
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateCampaignPrivacy = async (
    id: string,
    visibility: string
  ): Promise<boolean> => {
    try {
      const response = await KroClient.patch(
        KroEndpoints.changeCampaignVisibility(id),
        {
          visibility: visibility
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fileUpload = async (files: Array<File>): Promise<boolean> => {
    try {
      let formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("file", new File([files[i]], files[i].name));
      }
      formData.append("document_type", "campaign");

      const response = await KroClient.post(KroEndpoints.fileUpload, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fileUploadCampaign = async (
    id: string,
    files: Array<File>
  ): Promise<boolean> => {
    try {
      let formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("file", new File([files[i]], files[i].name));
      }
      formData.append("document_type", "campaign");

      const response = await KroClient.patch(
        KroEndpoints.fileUploadCampaign(id),
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const handleSaveAndUpload = async (
    payload: SaveVideoPayload,
    files: Array<File>
  ): Promise<boolean> => {
    try {
      const videoLinkSaved = await saveVideoLink(payload);
      if (!videoLinkSaved) {
        throw new Error("Failed to save video link");
      }

      const fileUploadResponse = await fileUploadCampaign(payload.id, files);
      if (!fileUploadResponse) {
        throw new Error("File upload failed");
      }

      return true;
    } catch (error) {
      throw error;
    }
  };

  const fetchCampaign = async (id: string): Promise<CampaignModelMapper> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchCampaign(id));
      const mappedResponse = mapCampaignModel(response.data);
      return mappedResponse;
    } catch (error) {
      throw error;
    }
  };

  const initializeDonation = async (
    payload: InitializeDonationPayload
  ): Promise<DonationResponseMapperWithAnon> => {
    try {
      const response = await KroClient.post(
        KroEndpoints.initializeDonation(payload.id),
        {
          email: payload.email,
          amount: payload.amount,
          fee: payload.fees,
          first_name: payload.firstName.trim(),
          last_name: payload.lastName.trim(),
          is_anonymous: payload.is_anonymous,
          provider: payload.provider,
          currency: payload.currency
        }
      );
      const mappedResponse = mapDonationResponse(response.data);
      return {
        ...mappedResponse,
        isAnonymous: payload.is_anonymous,
        firstName: payload.firstName,
        lastName: payload.lastName
      };
    } catch (error) {
      throw error;
    }
  };

  const verifyDonation = async (
    payload: VerifyDonationPayload
  ): Promise<DonationResponseMapper> => {
    const response = await KroClient.post(
      KroEndpoints.verifyDonation(payload.reference),
      { campaign_id: payload.id }
    );
    const mappedResponse = mapDonationResponse(response.data);
    return mappedResponse;
  };

  const updateCampaignFunding = async (
    payload: UpdateCampaignTargetAmountPayload
  ): Promise<boolean> => {
    const response = await KroClient.patch(
      KroEndpoints.updateCampaignTargetAmount(payload.id),
      {
        target_amount: payload.amount
      }
    );
    return response.data;
  };

  const updateCampaignDuration = async (
    payload: UpdateCampaignDurationPayload
  ): Promise<boolean> => {
    const response = await KroClient.patch(
      KroEndpoints.updateCampaignDuration(payload.id),
      {
        start_date: payload.startDate,
        end_date: payload.endDate
      }
    );
    return response.data;
  };

  const updateCampaignStatus = async (
    payload: UpdateCampaignStatusPayload
  ): Promise<boolean> => {
    const response = await KroClient.patch(
      KroEndpoints.publishCampaign(payload.id),
      {
        status: payload.status
      }
    );
    return response.data;
  };

  const fetchSupportedCurrencies = async (): Promise<CurrencyData[]> => {
    const response = await KroClient.get(
      KroEndpoints.supportedCurrencies
    );
    console.log(response);
    return response.data;
  };


  return {
    fetchKroFees,
    updateCampaignPrivacy,
    createCampaign,
    setCampaignType,
    updateCampaignInformation,
    fetchCampaignCategories,
    handleSaveAndUpload,
    fetchCampaign,
    fileUploadCampaign,
    initializeDonation,
    verifyDonation,
    fileUpload,
    saveVideoLink,
    updateCampaignStatus,
    updateCampaignFunding,
    updateCampaignDuration,
    fetchDonationFees,
    endCampaign,
    createComment,
    fetchComments,
    fetchSupportedCurrencies
  };
};

export default CampaignRepository;
