import React from "react";
const KroToggleSwitch = ({
  isOn = false,
  handleToggle = () => {},
  label = "",
  disabled = false,
  className = "",
}) => {
  return (
    <label className={`inline-flex cursor-pointer items-center ${className}`}>
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isOn}
          onChange={handleToggle}
          disabled={disabled}
        />
        <div
          className={`block h-5 w-9 rounded-full transition-colors duration-200 ease-in-out ${
            isOn ? "bg-[#4FBA6D]" : "bg-gray-200"
          } ${disabled ? "opacity-50" : ""}`}
        />
        <div
          className={`absolute left-0.5 top-0.5 h-4 w-4 rounded-full bg-white transition-transform duration-200 ease-in-out ${
            isOn ? "translate-x-4" : "translate-x-0"
          }`}
        />
      </div>
      {label && (
        <span className="text-sm ml-3 font-medium text-gray-900">{label}</span>
      )}
    </label>
  );
};

export default KroToggleSwitch;
