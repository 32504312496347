import { useQuery } from "@tanstack/react-query";
import { DonationFeesResponse } from "features/campaign/domain/models/donationFeesResponse";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";
import { useState } from "react";

const campaignService = new CampaignServiceImpl();

export const useFetchDonationFees = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["kroDonationFee"],
    queryFn: campaignService.fetchDonationFees
  });

  return { data, isLoading, error };
};
export const useKroDonationFeeCalculation = (
  feesResponse: DonationFeesResponse | undefined,
  currency: string
) => {
  const [amountToReceive, setAmountToReceive] = useState<string>("0");
  const [serviceFee, setServiceFee] = useState<number>(0);


  const handleDonationAmountChange = (amount: number) => {
    if (isNaN(amount)) {
      setServiceFee(0);
      setAmountToReceive("0");
      return;
    }

    const stripeBaseFee = feesResponse?.stripe.base ?? 0;
    const NON_EU_CURRENCIES = ["USD", "GBP"];
    const EU_CURRENCY = ["EUR"];

    if (currency === "NGN") {
      const baseFeeRate = parseFloat(feesResponse?.baseFeeRate ?? "0") || 0;
      const additionalFeeThreshold =
        parseFloat(feesResponse?.additionalFeeThreshold ?? "0") || 0;
      const feeCap = parseFloat(feesResponse?.feeCap ?? "0") || 0;

      let fee = amount * baseFeeRate;

      if (amount >= additionalFeeThreshold) {
        fee += 100;
      }

      if (fee > feeCap) {
        fee = feeCap;
      }
      setServiceFee(fee);
      setAmountToReceive(amount.toString());
    } else if (NON_EU_CURRENCIES.includes(currency)) {

      const stripeNonEUFee = feesResponse?.stripe.nonEu;
      const charge = amount * (stripeNonEUFee! / 100) + (stripeBaseFee / 100);

      setServiceFee(charge);
      setAmountToReceive(amount.toString());
    } else if (EU_CURRENCY.includes(currency)) {

      const stripeEUFee = feesResponse?.stripe.eu;
      const charge = amount * (stripeEUFee! / 100) + (stripeBaseFee / 100);

      setServiceFee(charge);
      setAmountToReceive(amount.toString());
    } else {
      setServiceFee(0);
      setAmountToReceive("0");
    }
  };

  return { handleDonationAmountChange, serviceFee, amountToReceive };
};