import { useEffect, useState } from "react";

const OTPResendCountDown: React.FC<{
  minutes: number;
  className?: string;
  onResend: () => Promise<any>;
  email?: string;
  type?: string;
}> = ({ minutes, onResend, className, email, type }) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleResend = async () => {
    if (timeLeft <= 0) {
      await onResend();
      setTimeLeft(minutes * 60);
    }
  };

  return (
    <div className="min-h-[60px] w-full max-w-full rounded-[12px] bg-[#FFFBED] mt-[16px] px-[12px] py-[11px]">
      <p className="font-inter font-semibold text-[14px] leading-[100%] tracking-[-0.5%] text-[#9D530F]">
        Didn't receive an email?
      </p>
      <span>
        <span className="font-inter font-normal text-[14px] leading-[100%] tracking-[-0.5%] text-[#9D530F]">
          Check your spam folder or
        </span>{" "}
        {timeLeft > 0 ? (
          <span className="font-inter font-semibold text-[14px] leading-[100%] tracking-[-0.5%] text-[#9D530F]">
            wait {formatTime(timeLeft)}
          </span>
        ) : (
          <span
            onClick={handleResend}
            className="cursor-pointer font-inter underline font-semibold text-[14px] leading-[100%] tracking-[-0.5%] text-[#9D530F]"
          >
            request a new code
          </span>
        )}
      </span>
    </div>
  );
};

export default OTPResendCountDown;