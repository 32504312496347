import React, { FC, ReactNode, useRef } from "react";

import { kroInputType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";

const validateInputByType = (type: kroInputType, value: string) => {
  switch (type) {
    case kroInputType.NUMBER:
      return !isNaN(Number(value));
    default:
      return true;
  }
};

const KroTextField: FC<KroTextFieldProps> = (props: KroTextFieldProps) => {
  let inputElement;
  const borderClass = props.errorMessage
    ? "border-red-500"
    : "border-neutral-300";

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onTextAreaChange && props.onTextAreaChange(e);
  };

  // const { showSnackbar } = useSnackbar();
  const editorRef = useRef<ClassicEditor>();

  switch (props.type) {
    case KroInputFieldType.TEXTAREA:
      inputElement = (
        <div className="relative">
          {props.useRichText ? (
            <div>
              <CKEditor
                id={props.id}
                data={props.value}
                onChange={() => {
                  props.ckEditorChange &&
                    props.ckEditorChange(editorRef.current?.getData());
                }}
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                editor={ClassicEditor}
                // onError={}
                // onFocus={}
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "heading",
                      "|",
                      "fontfamily",
                      "fontsize",
                      "fontColor",
                      "fontBackgroundColor",
                      "|",
                      "bold",
                      "italic",
                      "strikethrough",
                      "subscript",
                      "superscript",
                      "code",
                      "|",
                      "link",
                      "uploadImage",
                      "blockQuote",
                      "codeBlock",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "todoList",
                      "outdent",
                      "indent",
                    ],
                    shouldNotGroupWhenFull: false,
                  },

                  plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
                  licenseKey: "<YOUR_LICENSE_KEY>",
                  // mention: {
                  //   // Mention configuration
                  // },
                  initialData: props.value ?? "",
                }}
              />
            </div>
          ) : (
            <textarea
              ref={props.ref}
              id={props.id}
              name={props.name}
              className={`w-full rounded-xl border ${borderClass} px-3 py-2 text-gray-900 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black`}
              placeholder={props.placeHolder}
              value={props.value}
              onChange={handleTextAreaChange}
              rows={6}
            />
          )}
          {props.showCount && props.value && (
            <span className="text-sm absolute bottom-2 right-3 text-gray-500">
              {props.value.length}{" "}
              {props.value.length === 1 ? "character" : "characters"}
            </span>
          )}
        </div>
      );
      break;

    case KroInputFieldType.TEXT:
      inputElement = (
        <input
          disabled={props.isDisabled}
          ref={props.ref}
          id={props.id}
          name={props.name}
          className={`${props.className} rounded-[20px] border ${borderClass} w-full p-[10px] text-gray-900 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black ${props.fillColor} ${props.isDisabled ? "bg-neutral-50" : ""}`}
          type="text"
          value={props.value || ""}
          placeholder={props.placeHolder}
          onChange={(e) => {
            if (
              props.inputType &&
              !validateInputByType(props.inputType, e.target.value)
            ) {
              return;
            }

            props.onChange && props.onChange(e);
          }}
        />
      );
      break;

    case KroInputFieldType.TEXTWITHADDONS:
      inputElement = (
        <div
          className={`flex items-center overflow-hidden rounded-full border ${borderClass}`}
        >
          <span className="ml-3 mr-3 text-body-md text-neutral-500">
            https://
          </span>
          <div className="h-11 border-l-2 border-neutral-200"></div>
          <input
            ref={props.ref}
            id={props.id}
            name={props.name}
            type="text"
            value={props.value?.replace(/^https?:\/\//, "")}
            onChange={(e) => {
              const newValue = e.target.value.replace(/^https?:\/\//, "");
              props.onChange &&
                props.onChange({
                  ...e,
                  target: { ...e.target, value: newValue },
                });
            }}
            placeholder={props.placeHolder}
            className="w-full px-3 py-[11px] focus:outline-none"
          />
          {props.value && (
            <div
              className="mr-3 cursor-pointer text-red-500"
              onClick={() =>
                props.onChange &&
                props.onChange({
                  target: { value: "" },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <img
                src="/assets/icons/trash.svg"
                height={20}
                width={20}
                alt="close"
              />
            </div>
          )}
        </div>
      );
      break;
    case KroInputFieldType.COPIER:
      inputElement = (
        <div
          className={`border-bg-stroke-soft-200 flex w-full flex-row justify-between rounded-full border ${borderClass} bg-white px-3 py-2`}
        >
          <input
            ref={props.ref}
            id={props.id}
            name={props.name}
            type="text"
            placeholder={props.placeHolder}
            className="w-full px-3 py-2 focus:outline-none"
            readOnly
            // value={props.value || ""}
          />
          <div
            className="flex cursor-pointer flex-col items-center justify-center rounded-3xl bg-black pl-5 pr-5"
            onClick={() => {
              navigator.clipboard.writeText(props.value || "");
              toast.info("Copied to clipboard");
            }}
          >
            <span className="font-500 font-body-lg text-white">Copy</span>
          </div>
        </div>
      );
      break;
    case KroInputFieldType.TEXTADDONS:
      inputElement = (
        <div className="relative w-full">
          <div className="relative">
            {props.leadingAddon && (
              <div className="absolute inset-y-0 start-3.5 flex items-center">
                {props.leadingAddon}
              </div>
            )}
            <input
              disabled={props.isDisabled}
              ref={props.ref}
              id={props.id}
              name={props.name}
              type="text"
              value={props.value}
              onChange={(e) => {
                if (
                  props.inputType &&
                  !validateInputByType(props.inputType, e.target.value)
                ) {
                  return;
                }

                props.onChange && props.onChange(e);
              }}
              placeholder={props.placeHolder}
              className={`w-full rounded-[20px] border ${borderClass} p-[10px] ${props.leadingAddon ? (props.leadingPadding ?? "pl-14") : ""} text-gray-900 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black ${props.isDisabled ? "bg-neutral-50" : ""} ${props.fillColor} `}
            />
            {props.addon && (
              <div className="absolute inset-y-0 end-2 flex items-center">
                {props.addon}
              </div>
            )}
          </div>
        </div>
      );
      break;

    default:
      inputElement = <div></div>;
  }

  return (
    <div
      className={`${props.margin ?? "mb-6"} flex w-full flex-col justify-start ${props.className}`}
    >
      <div className="mb-2 flex items-baseline">
        <label
          className={
            !props.labelClassName
              ? "text-md font-medium-regular block text-left font-inter leading-[22px] tracking-[-0.005em]"
              : props.labelClassName
          }
          htmlFor={props.id}
        >
          {props.label}
        </label>
        {props.subtitle && (
          <span className="text-sm ml-1 text-neutral-500">
            {props.subtitle}
          </span>
        )}
      </div>
      {inputElement}
      {props.errorMessage && (
        <span className="italic text-red-500">{props.errorMessage}</span>
      )}
    </div>
  );
};

interface KroTextFieldProps {
  label: string;
  subtitle?: string;
  name?: string;
  type: KroInputFieldType;
  margin?: string;
  className?: string;
  placeHolder: string;
  fillColor?: string;
  labelClassName?: string;
  errorMessage?: string;
  ref?: React.Ref<any>;
  id?: string;
  value?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  quillChange?: (event: string) => void;
  ckEditorChange?: (event: string | undefined) => void;
  onTextAreaChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  addon?: ReactNode;
  showCount?: boolean;
  maxCharacters?: number;
  leadingIcon?: string;
  leadingAddon?: ReactNode;
  leadingPadding?: string;
  useRichText?: boolean;
  isDisabled?: boolean;
  inputType?: kroInputType;
}

export default KroTextField;
