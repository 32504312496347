import { CardsModel } from "../../data/models/cardsModel";

export const FundraiserTypeCard = ({
  props: { title, description, image },
}: {
  props: CardsModel;
}) => {
  return (
    <div className="flex h-[235px] max-w-[300px] flex-col rounded-[42px] bg-white p-[32px] lg:h-[257px] lg:max-h-[235px] lg:max-w-[304.5px]">
      <p className="leading-9 text-left font-inter font-medium tracking-tight text-[#131313]">
        {title}
      </p>
      <p className="text-base leading-normal mt-[16px] text-left font-normal text-[#5A5A59]">
        {description}
      </p>
      <img
        className="mt-[29px] h-[62px] w-[62px]"
        src={image}
        alt="fundraiser type"
        // height="62px"
        // width="62px"
      />
    </div>
  );
};
