import Routes from "router/routes";
import { useNavigate } from "react-router";

export const StartFundraisingButton = ({
  title,
  className,
}: {
  title?: string;
  className?: string;
}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className={`${className} flex h-[56px] w-[245px] transform items-center justify-center rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:h-[72px]`}
    >
      <span className="mr-2">{title ? title : "Start a Fundraiser"}</span>
      <img className="h-5 w-5" src="/assets/icons/Arrow.svg" alt="arrow" />
    </button>
  );
};
