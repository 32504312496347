class Routes {
  static CREATE_CAMPAIGN = "/campaign";
  static CREATE_CAMPAIGN_INFORMATION = "/campaign/information";
  static CREATE_CAMPAIGN_GALLERY = "/campaign/gallery";
  static CREATE_CAMPAIGN_TARGET = "/campaign/target";
  static HOME = "/";
  static ABOUT = "/about";
  static SIGNUP = "/signup";
  static SITE = "/site";
  static ORGANISATION_STEP_ONE = "/signup/organisation/step-one";
  static ORGANISATION_STEP_TWO = "/signup/organisation/step-two";
  static LOGIN = "/login";
  static LOGOUT = "/logout";
  static MY_CAMPAIGNS = "/dashboard";
  static DISCOVER_CAMPAIGN = "/campaigns";
  static HOW_TO_USE = "/how-to-use";
  static CONTACT_US = "/contact-us";
  static REDIRECT_PARAM = "redirect";
  static SETTINGS = "/dashboard/settings";
  static CAMPAIGN_BY_SLUG = (slug: string) => `/c/${slug}`;

  static CAMPAIGN_DETAILS = (id: string) => `/campaign/${id}`;

  static CAMPAIGN_DYNAMIC = (name: string) => `/${name}`;

  static ENTER_AMOUNT = (id: string) => `/campaign/${id}/enter-amount`;

  static PAYMENT_SUMMARY = (id: string) => `/campaign/${id}/payment-summary`;

  static DONATION_SUCCESSFUL = (id: string) =>
    `/campaign/${id}/donation-successful`;

  static DONATION_FAILED = (id: string) => `/campaign/${id}/donation-failed`;

  static END_CAMPAIGN = (id: string) => `/dashboard/end/${id}`;

  static EDIT_CAMPAIGN = (id: string) => `/dashboard/edit/${id}`;

  static USECASES = (type: string) => `/usecases/${type}`;

  static PROFILE = (slug: string) => `/dashboard/profile/${slug}`;

  static PUBLIC_PROFILE = (slug: string) => `/profile/${slug}`;
}

export default Routes;
