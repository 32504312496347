import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import useDiceBear from "common/hooks/useDiceBear";
import { formatCount } from "common/utils/countUtils";
import React from "react";
import { FC } from "react";

interface DonorAvatarsProps {
  count: number;
  size?: number;
  campaign?: CampaignModelMapper;
}

const DonorAvatars: React.FC<DonorAvatarsProps> = ({
  count,
  size = 20,
  campaign,
}) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex items-center">
        <div className="flex -space-x-1">
          {campaign?.donations
            ?.slice(0, 5)
            .map((donation, index) => (
              <GenerateAvatar
                key={index}
                seed={donation?.first_name + "." + donation?.last_name}
                className={`w-[${size}px] h-[${size}px] rounded-full border border-white`}
              />
            ))}
        </div>
        <span className="ml-2 text-body-sm leading-[16px] text-[#09101D]">
          {formatCount(count)} {count === 1 ? "Donor" : "Donors"}
        </span>
      </div>
    </div>
  );
};

export default DonorAvatars;

export const GenerateAvatar: FC<{
  seed: string;
  className: string;
}> = ({ seed, className }) => {
  const avaterURL = useDiceBear({ seed, options: {} });

  return <img src={avaterURL} alt={seed} className={className} />;
};
