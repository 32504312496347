import { PhoneNumberUtil } from "google-libphonenumber";
import CountryList from "country-list-with-dial-code-and-flag";

export class KroPhoneNumberUtil {
  getPhoneNumberWithCountryCode = (
    value: string,
    countryCode: string,
  ): string | null => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const phoneNumber = phoneUtil.parse(value, countryCode);
      const formattedPhoneNumber = phoneNumber.getNationalNumber() ?? null;

      if (!formattedPhoneNumber) return null;

      const country = CountryList.findOneByCountryCode(countryCode);

      if (!country) return null;

      return country.dialCode + formattedPhoneNumber.toString();
    } catch (error) {
      return null;
    }
  };

  getFormattedPhoneNumber = (
    value: string,
    countryCode: string,
  ): string | null => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const phoneNumber = phoneUtil.parse(value, countryCode);
      const formattedPhoneNumber = phoneNumber.getNationalNumber() ?? null;

      if (!formattedPhoneNumber) return null;

      return formattedPhoneNumber.toString();
    } catch (error) {
      return null;
    }
  };
}
