import { KroButton } from "features/components/buttons/kroButton";
import KroModal from "features/components/modals/kroModal";
import { FC, useState } from "react";
import useSayThanks from "../../hooks/useSayThanks";
import { toast } from "react-toastify";
import { SayThanksPayload } from "features/dashboard/domain/model/sayThanksPayload";
import { Activity } from "features/campaign/domain/models/activites";
import { queryClient } from "index";
import { useParams } from "react-router-dom";

const SayThanksModal: FC<{
  donation: Activity | null;
  isModalOpen: boolean;
  onModalClose: () => void;
}> = ({ isModalOpen: isSayThanksModalOpen, onModalClose, donation }) => {
  const [isSendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [isViewSentMessageModalOpen, setViewSentMessageModalOpen] =
    useState(false);
  const [sentMessage, setSentMessage] = useState<string>("");
  const { id: campaignId } = useParams<{ id: string }>();

  const handleViewSentMessageModalClose = () => {
    setViewSentMessageModalOpen(false);
  };
  const handleViewSentMessageModalOpen = () => {
    handleSayThanksModalClose();
    handleSendMessageModalClose();
    setViewSentMessageModalOpen(true);
  };

  const sayThanksMutation = useSayThanks(
    (error, _, __) => {
      console.log(error.stack);
      toast.error(error.message);
    },
    (response) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchActivityLog", campaignId]
      });
      handleSayThanksModalClose();
      handleSendMessageModalOpen();
    }
  );

  const handleSendMessageModalOpen = () => {
    setSendMessageModalOpen(true);
  };

  const handleSendMessageModalClose = () => {
    setSendMessageModalOpen(false);
  };

  const handleSayThanksModalClose = () => {
    onModalClose();
  };
  const handleSendMessage = (message: string) => {
    setSentMessage(message);

    const payload: SayThanksPayload = {
      activityId: donation?.id ?? -1,
      message
    };
    sayThanksMutation.mutate(payload);
  };

  return (
    <>
      <KroModal
        className="px-0"
        maxWidth={510}
        isOpen={isSayThanksModalOpen}
        onClose={handleSayThanksModalClose}
      >
        <SendMessageModal
          sayThanksMutation={sayThanksMutation}
          handleSendMessage={handleSendMessage}
          donor={donation?.donor ?? ""}
        />
      </KroModal>
      <KroModal
        className="px-0"
        maxWidth={510}
        isOpen={isSendMessageModalOpen}
        onClose={handleSendMessageModalClose}
      >
        <MessageSent
          openSentMessage={handleViewSentMessageModalOpen}
          closeSentMessageModal={handleSendMessageModalClose}
          donor={donation?.donor ?? ""}
        />
      </KroModal>
      <KroModal
        className="px-0"
        isOpen={isViewSentMessageModalOpen}
        onClose={handleViewSentMessageModalClose}
        maxWidth={510}
      >
        <ViewSentMessage
          closeSentMessageModal={handleViewSentMessageModalClose}
          message={sentMessage}
          donor={donation?.donor ?? ""}
        />
      </KroModal>
    </>
  );
};

const SendMessageModal: FC<{
  handleSendMessage: (message: string) => void;
  sayThanksMutation: any;
  donor: string;
}> = ({ handleSendMessage, donor, sayThanksMutation }) => {
  const [message, setMessage] = useState("");

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    console.log(message);
  };

  return (
    <div className="mx-[10px] mb-[10px] flex flex-col items-center justify-center">
      <div className="mt-9">
        <div className="mb-[6px] flex flex-col items-center justify-center">
          <img
            src="/assets/icons/praying_hand.svg"
            alt="giving"
            className="mb-1"
          />
          <h3 className="text-left font-inter text-[24px] font-[500] leading-[28.8px] tracking-[-0.005em]">
            Say Thanks
          </h3>
        </div>

        <h4
          className="text-center font-inter text-[14px] font-[400] leading-[20px] tracking-[-0.006em] text-neutral-700">
          Send a word of appreciation to <strong>{donor}</strong>
        </h4>
      </div>

      <div className="flex w-full flex-col items-stretch justify-stretch">
        <div
          onFocus={(e) =>
            e.currentTarget.classList.add("ring-black", "border-black")
          }
          onBlur={(e) =>
            e.currentTarget.classList.remove("ring-black", "border-neutral-900")
          }
          className="mb-3 mt-5 w-full animate-fadeIn overflow-hidden rounded-[20px] border border-[#E5E5E5] p-3"
        >
          <textarea
            className="w-full resize-none rounded-lg border-none pr-[6px] text-left font-inter text-[14px] font-[400] leading-[22px] tracking-[-0.005em] placeholder:text-[#A3A3A3] focus:outline-none"
            rows={4}
            placeholder="Send a word of appreciation..."
            onChange={handleMessageChange}
          />
        </div>
        <KroButton
          onclick={() => handleSendMessage(message)}
          title="Send Message"
          isLoading={sayThanksMutation.isPending}
        ></KroButton>
      </div>
    </div>
  );
};

const MessageSent: FC<{
  donor: string;
  openSentMessage: () => void;
  closeSentMessageModal: () => void;
}> = ({ donor, openSentMessage, closeSentMessageModal }) => {
  return (
    <div className="mx-[10px] mb-[10px] flex flex-col items-center justify-center">
      <div className="mt-9">
        <div className="mb-[6px] flex flex-col items-center justify-center">
          <img
            src="/assets/icons/send-mail 1.svg"
            alt="giving"
            className="mb-1"
          />
          <h3 className="text-left font-inter text-[24px] font-[500] leading-[28.8px] tracking-[-0.005em]">
            Message sent
          </h3>
        </div>

        <h4
          className="text-center font-inter text-[14px] font-[400] leading-[20px] tracking-[-0.006em] text-neutral-700">
          You sent a word of appreciation to <strong>{donor}</strong>
        </h4>
      </div>
      <div className="mb-3 mt-12 flex w-full flex-row justify-between gap-3">
        <button
          onClick={closeSentMessageModal}
          className="h-[52px] flex-1 rounded-[32px] border border-[#E5E5E5] bg-neutral-50 text-center font-inter text-[16px] font-[500] leading-[24px] tracking-[-0.005em]"
        >
          Close
        </button>
        <button
          onClick={openSentMessage}
          className="h-[52px] flex-1 rounded-[32px] border border-[#E5E5E5] bg-neutral-50 text-center font-inter text-[16px] font-[500] leading-[24px] tracking-[-0.005em]"
        >
          View Message
        </button>
      </div>
    </div>
  );
};

const ViewSentMessage: FC<{
  message: string;
  donor: string;
  closeSentMessageModal: () => void;
}> = ({ message, donor, closeSentMessageModal }) => {
  return (
    <div className="mx-[10px] mb-[10px] flex flex-col items-center justify-center">
      <div className="mt-9">
        <div className="flex flex-col items-center justify-center">
          <img
            src="/assets/icons/send-mail 1.svg"
            alt="giving"
            className="mb-1"
          />
        </div>

        <h4
          className="my-3 text-center font-inter text-[14px] font-[400] leading-[20px] tracking-[-0.006em] text-neutral-700">
          You sent a word of appreciation to <strong>{donor}</strong>
        </h4>
      </div>

      <div className="flex w-full flex-col items-stretch justify-stretch">
        <div className="mb-3 w-full animate-fadeIn overflow-hidden rounded-[20px] border border-[#E5E5E5] p-3">
          <textarea
            className="w-full resize-none rounded-lg border-none pr-[6px] text-left font-inter text-[14px] font-[400] leading-[22px] tracking-[-0.005em] placeholder:text-[#A3A3A3] focus:outline-none"
            rows={4}
            readOnly={true}
            value={message}
            placeholder="Send a word of appreciation..."
          />
        </div>
        <KroButton
          className="h-[48px]"
          hasIcon={false}
          onclick={closeSentMessageModal}
          title="Close"
        ></KroButton>
      </div>
    </div>
  );
};

export { SayThanksModal, SendMessageModal, ViewSentMessage };
