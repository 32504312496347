import { KroButton } from "features/components/buttons/kroButton";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import { TabController } from "features/components/tab/kroTabBar";
import KroModal from "features/components/modals/kroModal";
import SocialMediaModal from "../profile/socialMediaModal";
import { useRef, useState } from "react";
import { allowedImageTypes, maxFileSize } from "common/utils/imageUtils";
import KroImageCropper from "features/components/imageCropper/kroImageCropper";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useUpdateProfileImage from "../../hooks/useUpdateProfileImage";
import { toast } from "react-toastify";
import { useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import KroPhoneNumberCountryCodeDropDown from "features/components/dropdowns/kroPhoneNumberCodeDropdown";
// import { getPhoneNumberWithCountryCode } from "common/utils/phoneNumber";
import { KroDropdownT } from "features/components/dropdowns/kroDropdown";
import { COUNTRIES, Country } from "common/data/countries";
import ReactCountryFlag from "react-country-flag";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import PhoneVerificationModal from "features/onboarding/presentation/components/create_account/PhoneVerficationModal";
import { useSendOTP } from "features/onboarding/presentation/hooks/useOTP";
import KroLoader from "features/components/loader/kroLoader";
import KroConstants from "common/constants/kroConstants";
import { queryClient } from "index";
import { UserType } from "features/dashboard/domain/enums/userType.enum";
import { ProfileModel } from "@/features/dashboard/domain/model/profileModel";
import { KroPhoneNumberUtil } from "common/utils/phoneNumber";

const EditProfileTab = ({
                          tabController,
                          userProfile
                        }: {
  tabController: TabController;
  userProfile: ProfileModel;
}) => {
  const [isSocialMediaModalOpen, setSocialMediaModalOpen] =
    useState<boolean>(false);
  const [cropData, setCropData] = useState<{
    showCropper: boolean;
    imageUrl: string;
    type: "banner" | "profile" | null;
  }>({
    showCropper: false,
    imageUrl: "",
    type: null
  });

  const [cropperKey, setCropperKey] = useState(0);

  const [hasCountryBeenSaved, setHasCountryBeenSaved] = useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const util = new KroPhoneNumberUtil();

  console.log(userProfile?.countryCode);

  const form = useForm<{
    bio: string;
    banner_picture: string;
    profile_picture: string;
    phone_number?: string | null;
    phoneNumberCountry: string;
    country: Country | null;
    website?: string;
    isPhoneNumberVerified: boolean;
  }>({
    defaultValues: {
      bio: userProfile?.bio || "",
      banner_picture: userProfile?.bannerPicture || "",
      profile_picture: userProfile?.profilePicture || "",
      phone_number: userProfile.phoneNumber || null,
      website: userProfile?.website || "",
      phoneNumberCountry:
        userProfile?.countryCode || KroConstants.DEFAULT_COUNTRY_CODE,
      country: COUNTRIES.find((c) => c.name === userProfile?.country) || null,
      isPhoneNumberVerified: !!userProfile?.phoneNumber
    },
    onSubmit: (values) => {
      console.log(values.value);
      updateProfileMutation.mutate({
        bio: values.value.bio.trim(),
        country: values.value.country?.name,
        website: values.value.website,
        country_code: values.value.phoneNumberCountry,
        phone_number: values.value.phone_number ?? ""
      });
    }
  });

  const bannerInputRef = useRef<HTMLInputElement>(null);
  const profileInputRef = useRef<HTMLInputElement>(null);

  const updateProfileMutation = useUpdateProfile(
    (error) => {
      toast.error(error.message);
    },
    () => {
      queryClient.invalidateQueries({
        queryKey: ["updateProfile"]
      });

      toast.success("Profile details updated successfully");
      setHasCountryBeenSaved(true);
    }
  );

  const updateProfileImageMutation = useUpdateProfileImage(
    cropData.type || "profile",
    (error) => {
      toast.error(error.message);
    },
    () => {
      toast.success(
        `${cropData.type === "banner" ? "Banner" : "Profile"} picture updated successfully`
      );
    }
  );

  const OTPRequestMutation = useSendOTP(
    (error: Error) => {
      toast.error(error.message);
    },
    () => setShowVerificationModal(true)
  );

  const handleImageSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "banner" | "profile"
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!allowedImageTypes.includes(file.type)) {
      toast.error("Please upload a JPEG or PNG file");
      return;
    }

    if (file.size > maxFileSize) {
      toast.error("File size should be less than 5MB");
      return;
    }

    const minDimensions =
      type === "banner"
        ? { width: 800, height: 200 }
        : { width: 400, height: 400 };
    // const isValid =
    // type === "banner"
    //     ? await validateImageFile(
    //file,
    //minDimensions.width,
    //minDimensions.height,
    //      )
    //    : true;

    if (false) {
      toast.error(
        `Image dimensions should be at least ${minDimensions.width}x${minDimensions.height}px`
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropperKey((prev) => prev + 1);
      setCropData({
        showCropper: true,
        imageUrl: reader.result as string,
        type
      });
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = async (croppedImage: string) => {
    try {
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const file = new File([blob], `${cropData.type}_image.jpg`, {
        type: "image/jpeg"
      });

      await updateProfileImageMutation.mutateAsync(file);

      form.setFieldValue(
        cropData.type === "banner" ? "banner_picture" : "profile_picture",
        croppedImage
      );
    } catch (error) {
      toast.error("Failed to upload image");
    } finally {
      setCropData({ showCropper: false, imageUrl: "", type: null });
    }
  };

  const handleCancelCrop = () => {
    setCropData({ showCropper: false, imageUrl: "", type: null });
    setCropperKey((prev) => prev + 1); // Add this to force remount on cancel
  };

  const closeSocialMediaModal = () => {
    queryClient.invalidateQueries({
      queryKey: ["fetchUserProfile"]
    });
    setSocialMediaModalOpen(false);
  };
  const openSocialMediaModal = () => {
    setSocialMediaModalOpen(true);
  };

  const handleCloseVerificationModal = (isVerified: boolean) => {
    if (isVerified) {
      form.setFieldValue("isPhoneNumberVerified", isVerified);
      // Update profile after verification
      updateProfileMutation.mutate({
        phone_number: form.getFieldValue("phone_number") ?? ""
      });
    }
    setShowVerificationModal(false);
  };

  return (
    <>
      <PhoneVerificationModal
        type={EOTPUseCases.INDIVIDUAL_SIGNUP}
        phone={form.getFieldValue("phone_number") || ""}
        onClose={handleCloseVerificationModal}
        isOpen={showVerificationModal}
      />

      {cropData.showCropper && (
        <KroModal
          key={cropperKey}
          className="px-0 py-0"
          maxWidth={400}
          maxHeight={300}
          isOpen={true}
          onClose={handleCancelCrop}
        >
          <KroImageCropper
            isSaving={updateProfileImageMutation.isPending}
            key={cropperKey}
            imageUrl={cropData.imageUrl}
            aspect={cropData.type === "banner" ? 4 : 1}
            onCropComplete={handleCropComplete}
            onCancel={handleCancelCrop}
          />
        </KroModal>
      )}

      <KroModal
        closeOnOutsideClick={true}
        maxWidth={608}
        className="px-0 py-0"
        title="Add Social Media"
        maxHeight={626}
        isOpen={isSocialMediaModalOpen}
        onClose={closeSocialMediaModal}
      >
        <SocialMediaModal
          onClose={closeSocialMediaModal}
          profile={userProfile}
        />
      </KroModal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="mt-[24px] min-h-[calc(100vh-180px)] lg:mt-[40px]">
          <form.Field
            name="banner_picture"
            children={(field) => (
              <div className="relative">
                <img
                  src={
                    field.state.value ||
                    "/assets/images/rounded-profile-banner.svg"
                  }
                  alt="profile-banner"
                  className="mb-[40px] h-[110px] w-full rounded-[16px] object-cover lg:h-[130px]"
                />
                <input
                  type="file"
                  ref={bannerInputRef}
                  className="hidden"
                  accept="image/jpeg,image/png"
                  onChange={(e) => handleImageSelect(e, "banner")}
                />
                <button
                  onClick={() => bannerInputRef.current?.click()}
                  className="absolute right-4 top-4 flex h-[32px] w-[105px] items-center justify-center rounded-[8px] border border-stroke-soft-200 bg-white font-dm-sans text-[12px] font-semibold leading-[20px] tracking-[-0.6%] text-sub-600 lg:w-[120px] lg:text-[14px]"
                >
                  Change Banner
                </button>
              </div>
            )}
          />

          <form.Field
            name="profile_picture"
            children={(field) => (
              <div className="flex flex-row">
                {field.state.value ? (
                  <img
                    src={field.state.value}
                    alt="profile"
                    className="h-[120px] w-[120px] rounded-full object-cover"
                  />
                ) : (
                  <div
                    className="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-stroke-soft-200 font-dm-sans text-[48px] leading-[62.5px] tracking-[0%]">
                    {userProfile?.firstName?.[0]}
                    {userProfile?.lastName?.[0]}
                  </div>
                )}
                <div className="ml-[24px] mt-[12px] flex h-[92px] flex-col items-start justify-center">
                  <p className="font-dm-sans text-[16px] font-medium leading-[24px] text-strong-950">
                    Add photo
                  </p>
                  <p className="font-dm-sans text-[14px] font-normal leading-[20px] tracking-[-0.6%] text-sub-600">
                    Min 400x400px, PNG or JPEG
                  </p>
                  <input
                    type="file"
                    ref={profileInputRef}
                    className="hidden"
                    accept="image/jpeg,image/png"
                    onChange={(e) => handleImageSelect(e, "profile")}
                  />
                  <button
                    onClick={() => profileInputRef.current?.click()}
                    className="mt-[12px] h-[32px] w-[67px] rounded-[8px] border border-stroke-soft-200"
                  >
                    Upload
                  </button>
                </div>
              </div>
            )}
          />

          {userProfile.userType === UserType.organisation && (
            <div>
              <div
                className="mt-[48px] border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
                <p className="mb-[10px]">ORGANIZATION INFORMATION</p>
              </div>

              <div className="mt-[36px] flex flex-row gap-[12px]">
                <div className="flex-1">
                  <KroTextField
                    label="Organization Name"
                    type={KroInputFieldType.TEXT}
                    isDisabled={true}
                    value={userProfile?.organisationName}
                    placeHolder=""
                  />
                </div>
              </div>

              <div
                className="mt-[48px] border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
                <p className="mb-[10px]">ADMIN INFORMATION</p>
              </div>

              <div className="mt-[36px] flex flex-row gap-[12px]">
                <div className="flex-1">
                  <KroTextField
                    label="First Name"
                    type={KroInputFieldType.TEXT}
                    isDisabled={true}
                    value={userProfile?.adminFirstName}
                    placeHolder=""
                  />
                </div>
                <div className="flex-1">
                  <KroTextField
                    label="Last Name"
                    isDisabled={true}
                    type={KroInputFieldType.TEXT}
                    value={userProfile?.adminLastName}
                    placeHolder=""
                  />
                </div>
              </div>
            </div>
          )}

          {userProfile.userType === UserType.individual && (
            <>
              <div
                className="mb-[24px] mt-[48px] border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]"></div>

              <div className="flex flex-row gap-[12px]">
                <div className="flex-1">
                  <KroTextField
                    label="First Name"
                    type={KroInputFieldType.TEXT}
                    isDisabled={true}
                    value={userProfile?.firstName}
                    placeHolder=""
                  />
                </div>
                <div className="flex-1">
                  <KroTextField
                    label="Last Name"
                    isDisabled={true}
                    type={KroInputFieldType.TEXT}
                    value={userProfile?.lastName}
                    placeHolder=""
                  />
                </div>
              </div>
            </>
          )}
          <form.Field
            name="bio"
            validators={{
              onChange: ({ value }) => {
                return Validation.maxLength(value, 350);
              }
            }}
            children={(field) => (
              <KroTextField
                id={field.name}
                name={field.name}
                label="Short Bio"
                className="mt-[16px]"
                type={KroInputFieldType.TEXTAREA}
                useRichText={true}
                ckEditorChange={(e) => {
                  field.handleChange(e ?? "");
                }}
                value={field.state.value}
                errorMessage={field.state.meta.errors.join(", ")}
                placeHolder="Type here..."
              />
            )}
          />

          <form.Field
            name="country"
            validators={{
              onChange: ({ value }) => Validation.required(value?.name)
            }}
            children={(field) => (
              <KroDropdownT<Country>
                label={"Country"}
                hoverType="fixed"
                isDisabled={!!userProfile?.country || hasCountryBeenSaved}
                errorMessage={field.state.meta.errors.join(", ")}
                placeHolder={"Select your country"}
                selectedValue={field.state.value}
                className={"mt-4 w-full"}
                options={COUNTRIES.map((country) => ({
                  value: country,
                  name: country.name,
                  leftIcon: (
                    <ReactCountryFlag
                      countryCode={country.cca2}
                      svg
                      className="mr-2"
                      style={{
                        width: "1rem",
                        height: "1rem"
                      }}
                    />
                  )
                }))}
                selectedItemRenderer={(item) => (
                  <div className="flex items-center">
                    <ReactCountryFlag
                      countryCode={item.cca2}
                      svg
                      className="mr-2"
                      style={{
                        width: "1rem",
                        height: "1rem"
                      }}
                    />
                    {item.name}
                  </div>
                )}
                onDropdownItemClicked={(option: Country) => {
                  field.handleChange(option);
                }}
              />
            )}
          />

          <div
            className="mb-[10px] mt-[48px] border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
            <p className="mb-[10px]">CONTACT INFORMATION</p>
          </div>
          <KroTextField
            label="Email Address"
            isDisabled={true}
            fillColor="bg-neutral-50"
            value={userProfile.email ?? ""}
            type={KroInputFieldType.TEXT}
            placeHolder="user@mail.com"
          />

          {userProfile?.userType === UserType.organisation && (
            <form.Field
              name="website"
              validators={{
                onChange: ({ value }) => {
                  return Validation.websiteLink(value);
                }
              }}
              children={(field) => (
                <KroTextField
                  id={field.name}
                  name={field.name}
                  label="Website"
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                  }}
                  type={KroInputFieldType.TEXT}
                  value={field.state.value}
                  errorMessage={field.state.meta.errors.join(", ")}
                  placeHolder="www.company.com"
                />
              )}
            />
          )}

          <form.Subscribe>
            {(state) => (
              <form.Field
                name="phone_number"
                validators={{
                  onChange: ({ value }) => {
                    if (!value) return;
                    return Validation.phoneNumberByCountryCode(
                      value,
                      state.values.phoneNumberCountry
                    );
                  }
                }}
                children={(field) => (
                  <KroTextField
                    label="Phone Number"
                    className="mt-[16px]"
                    fillColor="bg-neutral-50"
                    type={KroInputFieldType.TEXTADDONS}
                    value={field.state.value ?? ""}
                    errorMessage={field.state.meta.errors.join(", ")}
                    onChange={(e) => {
                      console.log(e.target.value, "phone number");
                      if (state.values.isPhoneNumberVerified) {
                        form.setFieldValue("isPhoneNumberVerified", false);
                      }
                      field.handleChange(e.target.value);
                    }}
                    leadingAddon={
                      <form.Field
                        name="phoneNumberCountry"
                        children={(countryField) => {
                          return (
                            <KroPhoneNumberCountryCodeDropDown
                              value={countryField.state.value ?? ""}
                              onChange={(value) => {
                                console.log(value, "selected country");
                                countryField.handleChange(value.countryCode);
                                form.setFieldValue(
                                  "phoneNumberCountry",
                                  value.countryCode
                                );
                              }}
                            />
                          );
                        }}
                      />
                    }
                    addon={
                      state.values.isPhoneNumberVerified ? (
                        <img
                          src="/assets/icons/radio_checked.svg"
                          alt="checked"
                        />
                      ) : OTPRequestMutation.isPending ? (
                        <KroLoader />
                      ) : (
                        <KroButton.Base
                          title="Verify"
                          type="button"
                          isLoading={OTPRequestMutation.isPending}
                          className="px-3 py-[6px]"
                          hasIcon={false}
                          isDisabled={
                            field.state.meta.errors.length > 0 ||
                            !field.state.value
                          }
                          onclick={() =>
                            OTPRequestMutation.mutate({
                              use_case: EOTPUseCases.INDIVIDUAL_SIGNUP,
                              phone:
                                util.getPhoneNumberWithCountryCode(
                                  field.state.value ?? "",
                                  state.values.phoneNumberCountry
                                ) ?? ""
                            })
                          }
                        />
                      )
                    }
                    placeHolder="+234 703 123 5678"
                  />
                )}
              />
            )}
          </form.Subscribe>

          {form.getFieldValue("isPhoneNumberVerified") && (
            <div className="text-sm leading-snug mt-2 font-normal text-neutral-500">
              Your phone number has been verified!
            </div>
          )}

          <div
            className="mt-[48px] border-b-[1px] border-neutral-300 font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%]">
            <p className="mb-[10px]">SOCIAL MEDIA</p>
          </div>

          {/* Display social media links */}
          {userProfile?.socials?.instagram && (
            <KroTextField
              label=""
              leadingAddon={
                <img
                  src="/assets/icons/instagram.svg"
                  alt="instagram"
                  className="h-[24px] w-[24px]"
                />
              }
              placeHolder=""
              className="mt-[36px]"
              value={userProfile.socials.instagram}
              isDisabled
              type={KroInputFieldType.TEXTADDONS}
            />
          )}

          {userProfile?.socials?.facebook && (
            <KroTextField
              label=""
              leadingAddon={
                <img
                  src="/assets/icons/facebook_icon.svg"
                  alt="facebook"
                  className="h-[24px] w-[24px]"
                />
              }
              placeHolder=""
              className="mt-[16px]"
              value={userProfile.socials.facebook}
              isDisabled
              type={KroInputFieldType.TEXTADDONS}
            />
          )}

          {userProfile?.socials?.x && (
            <KroTextField
              label=""
              leadingAddon={
                <img
                  src="/assets/icons/twitter.svg"
                  alt="twitter"
                  className="h-[24px] w-[24px]"
                />
              }
              placeHolder=""
              className="mt-[16px]"
              value={userProfile.socials.x
                ?.replace("https://www.twitter.com/", "")
                .replace("https://x.com/", "")}
              isDisabled
              type={KroInputFieldType.TEXTADDONS}
            />
          )}

          {userProfile?.socials?.linkedin && (
            <KroTextField
              label=""
              leadingAddon={
                <img
                  src="/assets/icons/linkedin.svg"
                  alt="linkedin"
                  className="h-[24px] w-[24px]"
                />
              }
              placeHolder=""
              className="mt-[16px]"
              value={userProfile.socials.linkedin}
              isDisabled
              type={KroInputFieldType.TEXTADDONS}
            />
          )}

          {userProfile?.socials?.tiktok && (
            <KroTextField
              label=""
              leadingAddon={
                <img
                  src="/assets/icons/tiktok.svg"
                  alt="tiktok"
                  className="h-[24px] w-[24px]"
                />
              }
              placeHolder=""
              className="mt-[16px]"
              value={userProfile.socials.tiktok}
              isDisabled
              type={KroInputFieldType.TEXTADDONS}
            />
          )}

          <button
            onClick={openSocialMediaModal}
            className="mt-[12px] flex h-[32px] w-[128px] items-center justify-center gap-[2px] rounded-[8px] border border-stroke-soft-200"
          >
            <img src="/assets/icons/add-fill.svg" alt="add-fill" />
            <p className="font-dm-sans text-[14px] font-medium leading-[20px] tracking-[-0.6%]">
              {Object.values(userProfile?.socials || {}).some(Boolean)
                ? "Edit Socials"
                : "Add Account"}
            </p>
          </button>
          <KroButton
            title="Save Profile Information"
            className="mt-[40px] w-full"
            isLoading={updateProfileMutation.isPending}
            isDisabled={updateProfileMutation.isPending}
            onclick={form.handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default EditProfileTab;
