import React, { useState } from "react";

const LocationCircleWithTooltip = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div className="relative">
      <div
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        className="-mb-[110px] flex h-[160px] w-[160px] animate-pulse flex-col items-center justify-center rounded-full bg-[#022928] bg-opacity-10 lg:h-[294px] lg:w-[294px]"
      >
        <div className="relative flex h-[37.01px] w-[37.01px] items-center justify-center rounded-full bg-[#022928] bg-opacity-20 lg:h-[68px] lg:w-[68px]">
          <div className="flex h-[15.24px] w-[15.24px] animate-pulse items-center justify-center rounded-full bg-[#022928] text-white lg:h-[28px] lg:w-[28px]"></div>

          {isTooltipVisible && (
            <div className="absolute bottom-full left-1/2 mb-1 w-[154.56px] -translate-x-1/2 transform lg:mb-4 lg:w-[294px]">
              <div className="rounded-3xl bg-white p-3 shadow-lg lg:p-6">
                <button className="text-base lg:text-lg mb-2 flex w-full items-center justify-between rounded-full bg-gray-100 px-3 py-1.5 lg:mb-8 lg:px-6 lg:py-3">
                  View larger map
                  <img
                    src="/assets/icons/arrow-right.svg"
                    alt="arrow-right"
                    className="h-3 w-3 lg:h-4 lg:w-4"
                  />
                </button>

                <div className="text-center">
                  <h2 className="text-lg lg:text-2xl mb-1 font-normal lg:mb-4">
                    Office Address
                  </h2>
                  <p className="text-sm lg:text-base mb-0.5 text-gray-600 lg:mb-2">
                    Email Address
                  </p>
                  <p className="text-sm lg:text-base text-gray-600">
                    Phone Number
                  </p>
                </div>
              </div>
              <div className="absolute left-1/2 top-full -mt-2 h-4 w-4 -translate-x-1/2 transform overflow-hidden">
                <div className="h-2 w-2 origin-top-left rotate-45 transform bg-white"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationCircleWithTooltip;
