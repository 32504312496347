import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import DashboardRepository from "../repositories/dashboardRepository";
import CampaignRepository from "features/campaign/domain/repositories/campaignRepository"; // Example import from another feature
import { BankModel } from "../model/bankModel";
import {
  BankAccountModel,
  BankAccountPayload,
} from "../model/bankAccountModel";
import { WithdrawDonationModel } from "../model/withdrawDonationPayload";
import { Activity } from "features/campaign/domain/models/activites";
import { SayThanksResponseMapper } from "../model/sayThanksResonse";
import { SayThanksPayload } from "../model/sayThanksPayload";
import { EnquiryPayload } from "../model/enquiryPayload";
import { UpdateSocialMediaLinksPayload } from "../model/updateSocialMediaLinksPayload";
import { ProfileModel } from "../model/profileModel";
import { UpdateProfilePayload } from "../model/updateProfilePayload";
import {
  UpdateNotificationSettingsPayload,
  UpdatePrivacySettingsPayload,
} from "../model/updateSettingsPayload";

interface DashboardService {
  fetchUserCampaigns(slug: string): Promise<Array<CampaignModelMapper>>;
  fetchCampaigns(): Promise<Array<CampaignModelMapper>>;
  fetchWithdrawalBanks(): Promise<Array<BankModel>>;
  fetchCampaignCategories(): Promise<Array<CampaignModelMapper>>;
  fetchBankAccountInformation(
    payload: BankAccountPayload,
  ): Promise<BankAccountModel>;
  withdrawCampaignDonation(payload: WithdrawDonationModel): Promise<boolean>;
  sayThanks(payload: SayThanksPayload): Promise<SayThanksResponseMapper>;
  makeEnquiry(payload: EnquiryPayload): Promise<{}>;
  updateSocialMediaLinks(payload: UpdateSocialMediaLinksPayload): Promise<{}>;
  fetchProfile(slug: string): Promise<ProfileModel>;
  fetchUserProfile(): Promise<ProfileModel>;
  updateProfile(payload: UpdateProfilePayload): Promise<ProfileModel>;
  updateBannerPicture(file: File): Promise<ProfileModel>;
  updateProfilePicture(file: File): Promise<ProfileModel>;
  updateNotificationSettings(
    payload: UpdateNotificationSettingsPayload,
  ): Promise<ProfileModel>;
  updatePrivacySettings(
    payload: UpdatePrivacySettingsPayload,
  ): Promise<ProfileModel>;
  fetchActivityLog(campaignId: string): Promise<Array<Activity>>;
}

export class DashboardServiceImpl implements DashboardService {
  fetchCampaigns(): Promise<Array<CampaignModelMapper>> {
    return DashboardRepository().fetchCampaigns();
  }
  makeEnquiry(payload: EnquiryPayload): Promise<{}> {
    return DashboardRepository().makeEnquiry(payload);
  }
  fetchProfile(slug: string): Promise<ProfileModel> {
    return DashboardRepository().fetchProfile(slug);
  }
  updateSocialMediaLinks(payload: UpdateSocialMediaLinksPayload): Promise<{}> {
    return DashboardRepository().updateSocialMediaLinks(payload);
  }
  sayThanks(payload: SayThanksPayload): Promise<SayThanksResponseMapper> {
    return DashboardRepository().sayThanks(payload);
  }
  fetchActivityLog(campaignId: string): Promise<Array<Activity>> {
    return DashboardRepository().fetchActivityLog(campaignId);
  }

  fetchUserCampaigns(slug: string): Promise<Array<CampaignModelMapper>> {
    return DashboardRepository().fetchUserCampaigns(slug);
  }

  fetchCampaignCategories(): Promise<any> {
    return CampaignRepository().fetchCampaignCategories();
  }

  withdrawCampaignDonation(payload: WithdrawDonationModel): Promise<boolean> {
    return DashboardRepository().withdrawCampaignDonation(payload);
  }
  fetchBankAccountInformation(
    payload: BankAccountPayload,
  ): Promise<BankAccountModel> {
    return DashboardRepository().fetchBankAccountInformation(payload);
  }
  fetchWithdrawalBanks(): Promise<Array<BankModel>> {
    return DashboardRepository().fetchWithdrawalBanks();
  }
  fetchUserProfile(): Promise<ProfileModel> {
    return DashboardRepository().fetchUserProfile();
  }
  updateProfile(payload: UpdateProfilePayload): Promise<ProfileModel> {
    return DashboardRepository().updateProfile(payload);
  }
  updateBannerPicture(file: File): Promise<ProfileModel> {
    return DashboardRepository().updateBannerPicture(file);
  }

  updateProfilePicture(file: File): Promise<ProfileModel> {
    return DashboardRepository().updateProfilePicture(file);
  }

  updateNotificationSettings(
    payload: UpdateNotificationSettingsPayload,
  ): Promise<ProfileModel> {
    return DashboardRepository().updateNotificationSettings(payload);
  }

  updatePrivacySettings(
    payload: UpdatePrivacySettingsPayload,
  ): Promise<ProfileModel> {
    return DashboardRepository().updatePrivacySettings(payload);
  }
}
