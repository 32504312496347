import { useMutation } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { UpdateNotificationSettingsPayload, UpdatePrivacySettingsPayload } from "../../domain/model/updateSettingsPayload";
import { ProfileModel } from "../../domain/model/profileModel";

const DashboardService = new DashboardServiceImpl();

export const useUpdateNotificationSettings = (
  onError?: (error: Error) => void,
  onSuccess?: (data: ProfileModel) => void
) => {
  return useMutation({
    mutationFn: (payload: UpdateNotificationSettingsPayload) =>
      DashboardService.updateNotificationSettings(payload),
    onError,
    onSuccess,
  });
};

export const useUpdatePrivacySettings = (
  onError?: (error: Error) => void,
  onSuccess?: (data: ProfileModel) => void
) => {
  return useMutation({
    mutationFn: (payload: UpdatePrivacySettingsPayload) =>
      DashboardService.updatePrivacySettings(payload),
    onError,
    onSuccess,
  });
};
