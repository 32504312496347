import { FC, ReactNode, useState } from "react";
import KroTooltip from "../display/kroToolTip";

const KroRadioButton: FC<{
  id: string;
  name: string;
  value: string;
  label?: string;
  onToggle?: (checked: boolean) => void;
  inputClassName?: string;
  labelClassName?: string;
  labelField?: ReactNode;
  isChecked?: boolean;
  useSquareCheckbox?: boolean;
  tooltip?: string;
}> = ({
        id,
        name,
        value,
        label = "",
        onToggle,
        inputClassName = "",
        labelClassName = "",
        labelField,
        isChecked = false,
        useSquareCheckbox = false,
        tooltip
      }) => {
  const [checked, setChecked] = useState(isChecked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);
    if (onToggle) {
      onToggle(checked);
    }
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className={`hidden ${inputClassName}`}
        onChange={handleChange}
        checked={checked}
      />
      <label
        htmlFor={id}
        className={`flex cursor-pointer items-center text-body-md tracking-tight text-neutral-900 ${labelClassName} ${
          checked ? "text-neutral-900" : ""
        }`}
      >{
        <div className="mr-2 flex-shrink-0">
          {useSquareCheckbox ? (
            <div
              className={`h-5 w-5 border-2 border-neutral-900 transition-all duration-300 ease-in-out ${
                checked ? "bg-neutral-900" : "bg-white"
              }`}
            >
              {checked && (
                <div className="flex h-full w-full items-center justify-center">
                  <div className="h-3 w-3 bg-white"></div>
                </div>
              )}
            </div>
          ) : (
            <img
              src={
                checked
                  ? "/assets/icons/radio_checked.svg"
                  : "/assets/icons/radio_unchecked.svg"
              }
              alt={checked ? "checked" : "unchecked"}
              className={`transition-transform duration-300 ease-in-out ${
                checked ? "scale-100" : "scale-95"
              }`}
            />
          )}
        </div>
      }

        {tooltip ? (
          <KroTooltip subtitle={tooltip}>
            <div className="flex-grow">{label}</div>
          </KroTooltip>
        ) : (labelField ? labelField :
            <div className="flex-grow">{label}</div>
        )}
      </label>
    </div>
  );
};

export default KroRadioButton;
