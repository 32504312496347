import KroTextField from "features/components/inputs/kroTextField";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";

import { useState } from "react";
import KroBackButton from "features/components/buttons/kroBackButton";
import { SocialMediaHolder } from "features/components/modals/socialMediaHolder";
import generateProfileShareLink from "common/utils/profileShareLink";

const ProfileShareComponent = ({ slug }: { slug: string }) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const shareLink = generateProfileShareLink(slug);
  const socialMediaList = [
    {
      imgsrc: "/assets/icons/facebook.svg",
      medium: "Facebook",
      shareUrl: `https://www.facebook.com/sharer/sharer.php?url=${shareLink}`,
    },
    {
      imgsrc: "/assets/icons/twitter-x.svg",
      medium: "X (Twitter)",
      shareUrl: `https://twitter.com/intent/tweet?url=${shareLink}`,
    },
    {
      imgsrc: "/assets/icons/whatsapp.svg",
      medium: "Whatsapp",
      shareUrl: `https://api.whatsapp.com/send?text=${shareLink}`,
    },
    {
      imgsrc: "/assets/icons/qr-code-line.svg",
      medium: "QR Code",
      shareUrl: `${slug}`,
      onClick: () => setShowQRCode(true),
    },
  ];

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div>
      <div className="mx-[10px] mb-6 mt-5 grid justify-items-start">
        <h3 className="mt-5 text-H_8 font-[500]">Share</h3>
        <p className="text-body-md font-[400] text-neutral-700">
          Share this profile via the following channels
        </p>
      </div>

      <div className="m-[10px] rounded-xl bg-neutral-100 p-5">
        {showQRCode ? (
          <div className="flex flex-col items-center">
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${slug}`}
              alt="QR Code"
            />
            <KroBackButton
              classname="mt-4"
              onclick={() => setShowQRCode(false)}
            ></KroBackButton>
          </div>
        ) : (
          <>
            <div className="mb-[16px] grid grid-cols-4 justify-items-center">
              {socialMediaList.map((socialMedium, index) => (
                <SocialMediaHolder
                  onClick={() => {
                    if (socialMedium.onClick) {
                      return socialMedium.onClick();
                    }
                    return openInNewTab(socialMedium.shareUrl);
                  }}
                  key={index}
                  imgSrc={socialMedium.imgsrc}
                  medium={socialMedium.medium}
                  className="hover:bg-neutral-200"
                />
              ))}
            </div>
            <KroTextField
              className="mb-0"
              value={shareLink}
              placeHolder={shareLink}
              label=""
              type={KroInputFieldType.COPIER}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileShareComponent;
