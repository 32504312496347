const LinkHolderComponent = ({
  link,
  isLoading,
}: {
  link: string;
  isLoading?: boolean;
}) => {
  console.log(link ?? "");

  return (
    <button
      onClick={(e) => {
        if (link) {
          const formattedLink =
            link.startsWith("http://") || link.startsWith("https://")
              ? link
              : `https://${link}`;

          window.open(formattedLink, "_blank", "noopener,noreferrer");
        }
      }}
      className={`mx-[8px] my-[2px] mt-[18px] flex h-[20px] min-w-[172px] flex-row items-center justify-center rounded-[32px] ${isLoading ? "bg-gray-200" : "bg-[#EBF2FF]"}`}
    >
      {!isLoading && (
        <img src="/assets/icons/links-line.svg" alt="links-line" />
      )}
      <p
        className={`ml-[8px] text-center font-dm-sans text-[12px] font-normal leading-[16px] ${isLoading ? "text-gray-100" : "text-[#0052F2]"}`}
      >
        {!isLoading && link}
      </p>
    </button>
  );
};

export default LinkHolderComponent;
