import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";
import { useQuery } from "@tanstack/react-query";

const campaignService = new CampaignServiceImpl();

export const useFetchSupportedCurrencies = () => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["fetchSupportedCurrencies"],
    queryFn: campaignService.fetchSupportedCurrencies
  });

  return { data, isLoading, error, refetch };
};