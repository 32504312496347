export interface CurrencyData {
  id: number;
  code: string;
  symbol: string;
  flag_url: null,
  active: true;
  createdAt: Date;
  updatedAt: Date;
}


const currencyIconMap: Record<string, string> = {
  "NGN": "/assets/icons/nigeria-round.svg",
  "USD": "/assets/icons/united-states-round.svg",
  "EUR": "/assets/icons/euro-round.svg",
  "GBP": "/assets/icons/gbp-round.svg"
};

export const getCurrencyIcon = (currencyCode: string): string => {
  return currencyIconMap[currencyCode];
};
