import { KroButton } from "features/components/buttons/kroButton";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import { useForm } from "@tanstack/react-form";
import { UpdateSocialMediaLinksPayload } from "features/dashboard/domain/model/updateSocialMediaLinksPayload";
import useUpdateSocialMediaLinks from "../../hooks/useUpdateSocialMediaLinks";
import { toast } from "react-toastify";
import Validation from "common/utils/validation";
import { queryClient } from "index";
import { getSessionUser } from "common/store/useAuthStore";
import { ProfileModel } from "@/features/dashboard/domain/model/profileModel";

const SocialMediaModal = ({
  onClose,
  profile,
}: {
  onClose: () => void;
  profile?: ProfileModel;
}) => {
  const user = getSessionUser();
  const mutationSocialMediaLinks = useUpdateSocialMediaLinks(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: any) => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["fetchProfile", user?.slug],
      });
      toast.success("Social media links updated successfully");
    },
  );
  const form = useForm<UpdateSocialMediaLinksPayload>({
    defaultValues: {
      facebook: profile?.socials?.facebook ?? "",
      instagram: !profile?.socials?.instagram?.includes("www.instagram.com")
        ? `https://www.instagram.com/${profile?.socials?.instagram?.replace(
            "@",
            "",
          )}`
        : (profile?.socials?.instagram ?? ""),
      tiktok: profile?.socials?.tiktok ?? "",
      linkedin: profile?.socials?.linkedin ?? "",
      x:
        (profile?.socials?.x != null &&
          profile?.socials?.x?.includes("https://www.x.com/")) ||
        profile?.socials?.x?.includes("https://www.twitter.com/")
          ? profile?.socials?.x?.replace("https://www.x.com/", "")
          : (profile?.socials?.x ?? ""),
    },
    onSubmit: async (values) => {
      mutationSocialMediaLinks.mutate({
        facebook: !values.value.facebook?.includes("www.facebook.com")
          ? `https://www.facebook.com/${values.value.facebook?.replace(
              "@",
              "",
            )}`
          : values.value.facebook.replace("@", ""),
        instagram: !values.value.instagram?.includes("www.instagram.com")
          ? `https://www.instagram.com/${values.value.instagram?.replace("@", "")}`
          : values.value.instagram.replace("@", ""),
        tiktok: !values.value.tiktok?.includes("www.tiktok.com")
          ? `https://www.tiktok.com/${values.value.tiktok}`
          : values.value.tiktok,
        linkedin: values.value.linkedin,
        x: !values.value.x?.includes("www.x.com")
          ? `https://www.x.com/${values.value.x?.replace("@", "")}`
          : values.value.x.replace("@", ""),
      });
    },
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <div className="mt-[40px] flex flex-col px-[24px] pb-[40px] lg:mt-[56px] lg:px-[40px]">
          <form.Field
            name="facebook"
            validators={
              {
                // onChange: ({ value }) => Validation.facebookLink(value),
              }
            }
            children={(field) => (
              <KroTextField
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                label=""
                leadingAddon={
                  <img
                    src="/assets/icons/facebook_icon.svg"
                    alt="facebook"
                    className="h-[24px] w-[24px]"
                  />
                }
                placeHolder="e.g https://www.facebook.com/givNG"
                onChange={(e) => field.handleChange(e.target.value)}
                type={KroInputFieldType.TEXTADDONS}
              />
            )}
          />
          <form.Field
            name="instagram"
            validators={
              {
                // onChange: ({ value }) => Validation.instagramLink(value),
              }
            }
            children={(field) => (
              <KroTextField
                label=""
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                leadingAddon={
                  <img
                    src="/assets/icons/instagram.svg"
                    alt="instagram"
                    className="h-[24px] w-[24px]"
                  />
                }
                placeHolder="e.g https://www.instagram.com/givNG"
                onChange={(e) => field.handleChange(e.target.value)}
                type={KroInputFieldType.TEXTADDONS}
              />
            )}
          />
          <form.Field
            name="tiktok"
            validators={
              {
                // onChange: ({ value }) => Validation.tiktokLink(value),
              }
            }
            children={(field) => (
              <KroTextField
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                label=""
                leadingAddon={
                  <img
                    src="/assets/icons/tiktok.svg"
                    alt="tiktok"
                    className="h-[24px] w-[24px]"
                  />
                }
                placeHolder="e.g https://www.tiktok.com/givNG"
                onChange={(e) => field.handleChange(e.target.value)}
                type={KroInputFieldType.TEXTADDONS}
              />
            )}
          />
          <form.Field
            name="linkedin"
            validators={{
              onChange: ({ value }) => Validation.linkedinLink(value),
            }}
            children={(field) => (
              <KroTextField
                label=""
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                leadingAddon={
                  <img
                    src="/assets/icons/linkedin.svg"
                    alt="linkedin"
                    className="h-[24px] w-[24px]"
                  />
                }
                placeHolder="e.g https://www.linkedin.com/givNG"
                onChange={(e) => field.handleChange(e.target.value)}
                type={KroInputFieldType.TEXTADDONS}
              />
            )}
          />
          <form.Field
            name="x"
            validators={
              {
                // onChange: ({ value }) => Validation.xLink(value),
              }
            }
            children={(field) => (
              <KroTextField
                label=""
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                leadingAddon={
                  <img
                    src="/assets/icons/twitter.svg"
                    alt="twitter"
                    className="h-[24px] w-[24px]"
                  />
                }
                placeHolder="e.g https://www.x.com/givNG"
                onChange={(e) => field.handleChange(e.target.value)}
                type={KroInputFieldType.TEXTADDONS}
              />
            )}
          />

          <KroButton
            isDisabled={mutationSocialMediaLinks.isPending}
            title="Add Social Media"
            onclick={form.handleSubmit}
            animated={true}
            isLoading={mutationSocialMediaLinks.isPending}
            type="submit"
            className="mt-[40px]"
          />
        </div>
      </form>
    </>
  );
};

export default SocialMediaModal;
