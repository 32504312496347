import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetchCampaign from "../../hooks/useFetchCampaign";
import { DonationResponseMapperWithAnon } from "features/campaign/domain/models/donationResponse";
import { useInitializeDonation } from "../../hooks/useInitializeDonation";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";
import { useEffect, useRef, useState } from "react";
import Routes from "router/routes";
import { formatCurrency } from "common/utils/currencyFormater";
import { useFetchDonationFees, useKroDonationFeeCalculation } from "../../hooks/useFetchDonationFees";
import KroShareModal from "features/components/modals/kroShareModal";
import KroModal from "features/components/modals/kroModal";
import KroIframeMessageListener from "common/hooks/useKroIframeListener";
import { VerifyDonationPayload } from "features/campaign/domain/models/verifyDonationPayload";
import useVerifyDonation from "../../hooks/useVerifyDonation";
import { queryClient } from "index";
import KroLoadingIndicator, { LoaderType } from "features/components/loader/kroCircularIndicator";
import { toast } from "react-toastify";
import { useForm } from "@tanstack/react-form";
import { InitializeDonationPayload } from "features/campaign/domain/models/initializeDonationPayload";
import { smartRoundUp } from "common/utils/smartRoundUp";

const PaymentSummaryPage = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const { data: campaign } = useFetchCampaign(campaignId || "");
  const { formData, setFormData } = useCampaignDonateForm();

  const [isIframeOpen, setIsIframeOpen] = useState(false);

  const verifyDonation = (payload: VerifyDonationPayload) => {
    setIsIframeOpen(false);

    verifyDonationMutation.mutate(payload);
  };

  const location = useLocation();

  const form = useForm({
    defaultValues: {
      amount: formData.amount ?? "",
      firstName: formData.firstName ?? "",
      isEmailVerified: formData.isEmailVerified ?? false,
      lastName: formData.lastName ?? "",
      email: formData.email ?? "",
      reference: formData.reference ?? "",
      paymentMethod: formData.paymentMethod ?? "",
      donateAnonymously: formData.donateAnonymously ?? false,
      currencyCode: formData.currencyCode ?? "",
      provider: formData.provider ?? ""
    },

    onSubmit: (values) => {
      if (!values.value.isEmailVerified) {
        toast.info("Please verify your email address to proceed");
        return;
      }

      const payload: InitializeDonationPayload = {
        amount: parseFloat(location.state.amount),
        firstName: values.value.firstName,
        lastName: values.value.lastName,
        email: values.value.email,
        is_anonymous: values.value.donateAnonymously,
        id: campaign?.id ?? "",
        fees: serviceFee,
        currency: location.state.currencyCode.code,
        provider:
          location.state.currencyCode.code === "NGN" ? "paystack" : "stripe"
      };

      setFormData(() => ({
        email: values.value.email,
        isEmailVerified: values.value.isEmailVerified,
        firstName: values.value.firstName,
        lastName: values.value.lastName,
        reference: values.value.reference
      }));

      mutation.mutate(payload);
    }
  });

  const verifyDonationMutation = useVerifyDonation(
    () => {
      navigate(Routes.DONATION_FAILED(campaignId ?? ""), location.state);
    },
    () => {
      navigate(Routes.DONATION_SUCCESSFUL(campaignId ?? ""), location.state);

      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaignId]
      });
    }
  );

  const [link, setLink] = useState("");
  const donorInformation = useRef<DonationResponseMapperWithAnon | null>(null);

  const handlePaystack = (data?: DonationResponseMapperWithAnon) => {
    setLink(data?.paymentLink ?? "");
    if (data != null) setIsIframeOpen(true);
    donorInformation.current = data ?? null;
  };

  const mutation = useInitializeDonation(
    (error) => {
      toast.error(error.message);
    },
    (data: DonationResponseMapperWithAnon) => {
      if (location.state.currencyCode.code === "NGN") {
        handlePaystack(data);
      } else {
        window.open(data.paymentLink, "_self");
      }
    }
  );

  useEffect(() => {
    if (!location.state?.amount) {
      navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
    } else {
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const { data, isLoading } = useFetchDonationFees();

  const { symbol, code } = location.state.currencyCode;

  const { serviceFee, handleDonationAmountChange } =
    useKroDonationFeeCalculation(data, code);

  useEffect(() => {
    handleDonationAmountChange(parseFloat(location.state.amount ?? 0));
  }, [location.state.amount, handleDonationAmountChange]);

  const [showCampaignDetailsShareModal, setShowCmapaignDetailsShareModal] =
    useState<boolean>(false);

  const handleDonate = () => {
    form.handleSubmit();
  };

  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
    }
  };
  const roundedServiceFee = smartRoundUp(serviceFee);


  const total = parseFloat(location.state.amount) + roundedServiceFee;


  if (isLoading) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }

  return (
    <>
      <KroShareModal
        campaignId={campaign?.slug === null ? campaignId : campaign?.slug}
        isOpen={showCampaignDetailsShareModal}
        onClose={() => setShowCmapaignDetailsShareModal(false)}
      />
      <KroModal
        isOpen={isIframeOpen}
        onClose={() => setIsIframeOpen(false)}
        maxWidth={550}
        maxHeight={900}
        className="m-0 px-1 py-14 sm:py-0"
        showCloseButton={true}
      >
        <KroIframeMessageListener
          url={link}
          onSuccess={(data) => {
            const payload: VerifyDonationPayload = {
              reference: data?.reference ?? "",
              id: campaignId ?? ""
            };
            verifyDonation(payload);
          }}
        />
      </KroModal>

      <div className="flex min-h-[calc(100vh-280px)] flex-col">
        <div className="flex flex-grow items-center justify-center px-4">
          <div className="w-full max-w-[608px]">
            <div className="flex flex-col items-start rounded-3xl border border-neutral-200 p-6">
              <KroBackButton
                title="Payment summary"
                onclick={handleBackButton}
                classname="lg:flex hidden"
              />
              <p
                className="mb-4 flex text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] lg:hidden">
                Payment summary
              </p>

              <div className="mt-4 space-y-4 sm:mt-5">
                <h2 className="text-lg sm:text-xl font-medium text-neutral-900">
                  Overview
                </h2>

                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      Your donation
                    </span>
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      {formatCurrency(
                        isNaN(location.state.amount)
                          ? "0"
                          : location.state.amount,
                        true,
                        symbol
                      )}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      {code === "NGN"
                        ? "3rd party bank charges"
                        : "3rd party charges"}
                    </span>
                    <span className="text-base sm:text-lg font-medium text-neutral-900">
                      {formatCurrency(serviceFee.toFixed(2), true, symbol) ??
                        "0"}
                    </span>
                  </div>

                  <div className="mt-3 border-t border-stroke-soft-200 pt-3">
                    <div className="flex items-center justify-between">
                      <span className="text-lg sm:text-xl font-semibold text-[#0F0F0F]">
                        Total
                      </span>
                      <span className="text-lg sm:text-xl font-semibold text-[#0F0F0F]">
                        {formatCurrency(
                          isNaN(total) ? "0" : total,
                          true,
                          symbol
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <KroButton
                  isLoading={mutation.isPending}
                  onclick={handleDonate}
                  title="Donate now"
                  className="mt-[100px] w-full"
                />

                <div className="text-sm sm:text-base mt-6 text-center text-neutral-700">
                  <span>
                    By choosing the payment method above, you agree to the Giv{" "}
                    <button className="underline">Terms of Service</button> and
                    acknowledge the{" "}
                    <button className="underline">Privacy Notice</button>.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSummaryPage;
