import { useQuery } from "@tanstack/react-query";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";
import { useState } from "react";

const campaignService = new CampaignServiceImpl();

export const useKroFee = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["kroFee"],
    queryFn: campaignService.getKroFee,
  });

  return { data, isLoading, error };
};

export const useKroFeeCalculation = (feePercentage: number) => {
  const [amountToReceive, setAmountToReceive] = useState<string>("0");
  const [targetAmount, setTargetAmount] = useState<string>("0");
  const [serviceFee, setServiceFee] = useState<number>(0);

  const handleTargetAmountChange = (amount: number) => {
    if (!isNaN(amount) && amount > 0) {
      const fee = amount * (feePercentage / 100);
      const amountWithoutFee = amount - fee;
      setTargetAmount(amount.toString());
      setAmountToReceive(amountWithoutFee.toString());
      setServiceFee(fee);
      return;
    }
    setTargetAmount("0");
    setServiceFee(0);
    setAmountToReceive("0");
  };

  const handleAmountToReceiveChange = (amount: number) => {
    if (!isNaN(amount) && amount > 0) {
      const fee = amount * (feePercentage / 100);
      const calculatedTargetAmount = amount + fee;
      setAmountToReceive(amount.toString());
      setTargetAmount(calculatedTargetAmount.toString());
      setServiceFee(fee);
      return;
    }
    setAmountToReceive("0");
    setTargetAmount("0");
    setServiceFee(0);
  };

  return {
    amountToReceive,
    targetAmount,
    serviceFee,
    handleTargetAmountChange,
    handleAmountToReceiveChange,
  };
};