import { useEffect } from "react";
import Lottie from "react-lottie";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Routes from "router/routes";
import animationData from "../../../../../common/assets/lottie/confetti_animation.json";
import useVerifyDonation from "features/campaign/presentation/hooks/useVerifyDonation";
import { queryClient } from "index";
import KroLoadingIndicator, { LoaderType } from "features/components/loader/kroCircularIndicator";

const DonationCompletedPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: campaignId } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  const verifyDonationMutation = useVerifyDonation(
    () => {
    },
    () => {
      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaignId]
      });
    }
  );

  useEffect(() => {
    if (!sessionId && !campaignId) return;
    if (sessionId && campaignId) {
      verifyDonationMutation.mutate({
        id: campaignId,
        reference: sessionId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, campaignId]);


  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(Routes.CAMPAIGN_DETAILS(campaignId ?? ""), { replace: true });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [navigate, campaignId, location.state?.amount]);

  if (verifyDonationMutation.isPending && sessionId !== "") {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }
  return (
    <div className="flex min-h-[calc(100vh-250px)] flex-col">
      <div className="absolute inset-0 z-10">
        <Lottie options={defaultOptions} height="100%" width="100%" />
      </div>
      <div className="flex flex-grow items-center justify-center px-4">
        <div className="w-full max-w-[608px]">
          <div className="flex flex-col items-center rounded-3xl border border-neutral-200 p-6">
            <img
              src="/assets/icons/donation_success.svg"
              alt="giving"
              className="mb-1"
            />
            <p className="mb-2 text-center font-inter text-H_7 tracking-[-0.005em]">
              Donation successful!
            </p>
            <p
              className="text-base leading-6 mb-4 text-center font-inter font-normal tracking-[-0.005em] text-neutral-400">
              Your donation was successful and will
              <br />
              make a big difference.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCompletedPage;
