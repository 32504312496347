import { CallToActionButton } from "../components/callToActionButton";
import { FundraiserTypeCard } from "../components/fundraiserTypeCard";
import { StartFundraisingButton } from "../components/startFundraisingButton";
import { WhyGivCard } from "../components/whyGivCard";
import { CardsModel } from "../../data/models/cardsModel";
import WebsiteFooter from "common/layout/websiteFooter";
import FAQ from "../components/faqComponent";

export const AboutPage = () => {
  const fundraiserTypes: Array<CardsModel> = [
    {
      title: "Non-profit",
      description: "Fundraising as a Non-Profit?",
      image: "/assets/icons/non-profit.svg",
    },
    {
      title: "Events",
      description: "Need to Raise Money for An Event?",
      image: "/assets/icons/event.svg",
    },
    {
      title: "Religious",
      description: "Raising Money for a Religious Cause?",
      image: "/assets/icons/religious.svg",
    },
    {
      title: "Medical",
      description: "Raising Money for Medical Aid and Emergencies?",
      image: "/assets/icons/medical.svg",
    },
  ];
  const whyGiv: Array<CardsModel> = [
    {
      title: "Seamless Setup",
      description:
        "From creating a campaign to receiving donations, Giv makes the process effortless and intuitive, empowering you to focus on meeting your fundraising goals.",
      image: "/assets/icons/seamless-setup.svg",
    },
    {
      title: "Transparent Funding",
      description:
        "Giv offers a transparent platform that prioritises clear communication and visible funding pathways, ensuring that donors know exactly where their contributions go.",
      image: "/assets/icons/transparent-funding.svg",
    },
    {
      title: "Real-Time Updates",
      description:
        "Keep your supporters engaged and informed with real-time updates and progress tracking, making every milestone a shared celebration.",
      image: "/assets/icons/real-time-updates.svg",
    },
    {
      title: "Support When You Need It",
      description:
        "Our dedicated support team is here to assist at every stage, so you never feel alone in your journey to make a difference.",
      image: "/assets/icons/support.svg",
    },
  ];

  return (
    <>
      <div className="mx-[20px] mt-[10px] flex max-w-[1440px] flex-col items-start lg:mx-[80px] lg:mb-[194px] lg:mt-[36px] lg:flex-row lg:items-center lg:justify-center">
        <div className="mt-[61px] flex-1 items-center justify-center">
          <h1 className="font-dm-sans text-[48px] font-medium leading-[48px] tracking-[-0.03em] text-neutral-900 lg:text-left lg:text-[100px] lg:font-medium lg:leading-[120px] lg:tracking-[-0.09em]">
            Created for <br />
            Givers & Giv-ng
          </h1>
        </div>

        <div className="mt-[16px] flex h-[261px] flex-1 flex-col justify-center lg:ml-[100px]">
          <p className="text-lg text-left font-normal leading-relaxed text-[#5A5A59]">
            Giv connects compassionate supporters with causes that matter,
            making every act of generosity count.
          </p>
          <p className="text-lg mb-[32px] mt-4 text-left font-normal leading-relaxed text-[#5A5A59] lg:mb-[64px] lg:mt-10">
            We are here to amplify the power of giving, turning small acts of
            kindness into big waves of change together.
          </p>
          <StartFundraisingButton />
        </div>
      </div>
      <div className="mt-[32px] flex w-full flex-col items-center justify-center bg-[#FFF3ED] px-[16px] lg:h-[191px] lg:flex-row lg:px-[100px]">
        {/* First Section */}
        {fundraiserTypes.map((fundraiserType, index) => (
          <div className="mx-4 my-4 lg:-translate-y-16 lg:transform">
            <FundraiserTypeCard props={fundraiserType} key={index} />
          </div>
        ))}
      </div>
      <div className="flex h-[200px] flex-col bg-[#FFF3ED] px-[16px] lg:h-[419px] lg:flex-row lg:px-[75px]">
        <div className="mb-[300px] flex-1 lg:mb-[120px] lg:mt-[60px]">
          <p className="text-left font-dm-sans text-[42px] font-medium leading-[42px] tracking-tighter text-[#131313] lg:text-[75px] lg:leading-[72px]">
            Our Mission
          </p>
          <p className="mt-[16px] text-[16px] font-normal leading-[22.4px] text-[#131313] lg:mt-[32px] lg:text-[18px] lg:leading-[25.2px]">
            We created the Giv platform because of the massive disconnect
            between those in need and those willing to give. The bridge was
            trust and transparency- so we created the perfect platform to ensure
            that.
          </p>
        </div>
        <div className="flex flex-1"></div>
      </div>
      <div className="hidden flex-col bg-[#022928] lg:flex lg:max-h-[774.58px] lg:flex-row lg:px-[75px]">
        <div className="flex-1 lg:mb-[120px] lg:mt-[120px]">
          <img
            src="/assets/images/kro-illustration.svg"
            alt="kro illustration"
            // height="100%"
            // width="100%"
          />
        </div>
        <div className="flex flex-1 flex-col items-start justify-center lg:ml-[100px]">
          <p className="text-[58px] leading-[64px] tracking-[-2px] text-[#D2D2D2]">
            Powered by Kro, a Leadway Venture
          </p>
          <p className="text-lg leading-7 text-left font-dm-sans font-normal tracking-[-0.25px] text-[#B3B3B3] lg:mt-[32px]">
            Giv is a product of Kro, a platform that helps secure and protect
            transactions <br /> while conducting business or providing services.{" "}
            <br /> Kro is backed by Leadway, a top trusted insurance company in
            Nigeria.
          </p>
        </div>
      </div>
      <div className="px-[16px] lg:h-[802px] lg:px-[75px]">
        <div className="mt-[56px] lg:mb-[64px] lg:mt-[120px]">
          <p className="font-dm-sans text-[42px] font-medium leading-[42px] tracking-ultraTightest lg:text-[72px] lg:leading-[72px]">
            Why Giv?
          </p>
        </div>
        <div className="mt-[24px] grid grid-rows-1 gap-[32px] lg:grid-cols-2">
          {whyGiv.map((prop, index) => (
            <WhyGivCard props={prop} key={index} />
          ))}
        </div>
      </div>
      <div className="hidden flex-col rounded-[32px] bg-[#FFF3ED] lg:mx-[50px] lg:flex lg:h-[598px] lg:flex-row lg:px-[75px] lg:py-[120px]">
        <div className="flex-1">
          <p className="font-dm-sans text-[42px] font-medium leading-[50px] tracking-[-0.03em] md:text-[56px] md:leading-[65px] lg:w-[500px] lg:text-[72px] lg:leading-[80px] lg:tracking-[-0.06em]">
            Partner with Purpose
          </p>
          <p className="mt-4 w-full text-left font-inter text-body-xl font-thin text-[#5A5A59] md:max-w-xl lg:mt-[32px] lg:w-[500px]">
            Want to join hands with Giv to drive social impact through strategic
            partnerships and sponsorships?
          </p>
          <div className="mb-8">
            <CallToActionButton isTransparent={false} title="Connect with us" />
          </div>
        </div>
        <div className="flex-1">
          <img src="/assets/images/hands.svg" alt="hands" />
        </div>
      </div>
      <div className="hidden flex-col rounded-[32px] bg-[#F6F6F6] lg:mx-[50px] lg:mt-[100px] lg:flex lg:h-[598px] lg:flex-row lg:px-[75px] lg:py-[120px]">
        <div className="flex-1">
          <img src="/assets/images/sending-love.svg" alt="sending-love" />
        </div>
        <div className="flex-1 lg:ml-[75px]">
          <p className="font-dm-sans text-[42px] font-medium leading-[50px] tracking-[-0.03em] md:text-[56px] md:leading-[65px] lg:text-[72px] lg:leading-[80px] lg:tracking-[-0.06em]">
            Looking to Give?
          </p>
          <p className="mt-4 w-full text-left font-inter text-body-xl font-thin text-[#5A5A59] md:max-w-xl lg:mt-[32px] lg:w-[603px]">
            When we come together to give it makes an impact, no matter the
            amount. You can help make a difference.
          </p>
          <div className="mb-8">
            <CallToActionButton isTransparent={false} title="View Campaigns" />
          </div>
        </div>
      </div>
      <FAQ />
      <WebsiteFooter />
    </>
  );
};
