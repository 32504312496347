import {
  IndividualResponseMapper,
  mapIndividualResponse,
} from "../models/accountCreationResponse";
import { ISignInPayload } from "../models/signInPayload";
import { SignInResponseData } from "../models/signInResponse";
import AuthRepository from "../repositories/authRepository";

interface AuthService {
  createIndividual(
    payload: ICreateIndividualAccountPayload,
  ): Promise<IndividualResponseMapper>;
  requestOTP(payload: IRequestOTP): Promise<IResponse>;
  createOrganizationAccount(
    payload: ICreateOrganisationAccountPayload,
  ): Promise<IOrganizationCreationResponse>;
  getAllCategories(): Promise<IAllCategoryResponse>;
  updateOrganizationStepOne(
    id: string,
    payload: IOrganizationStepOnePayload,
  ): Promise<IOrganizationStepOneData>;
  updateOrganizationStepTwo(
    id: string,
    payload: IOrganizationStepTwoPayload,
  ): Promise<IOrganizationStepOneResponse>;
  verifyOTP(otp: string): Promise<IResponse>;
  signIn(payload: ISignInPayload): Promise<SignInResponseData>;
}

export class AuthServiceImpl implements AuthService {
  signIn(payload: ISignInPayload): Promise<SignInResponseData> {
    return AuthRepository().signIn(payload);
  }
  requestOTP(payload: IRequestOTP): Promise<IResponse> {
    console.log(payload);
    const { name, email, phone, use_case } = payload;

    const contactMethod = email ? { email } : phone ? { phone } : null;

    if (!contactMethod) {
      throw new Error("Either email or phone is required.");
    }
    const finalPayload = { name, ...contactMethod, use_case };

    return AuthRepository().sendOTP(finalPayload);
  }

  verifyOTP(otp: string): Promise<IResponse> {
    return AuthRepository().verifyOTP(otp);
  }

  async createIndividual(
    payload: ICreateIndividualAccountPayload,
  ): Promise<IndividualResponseMapper> {
    const response = await AuthRepository().createIndividualAccount(payload);

    return mapIndividualResponse(response.data);
  }

  async createOrganizationAccount(
    payload: ICreateOrganisationAccountPayload,
  ): Promise<IOrganizationCreationResponse> {
    try {
      const response =
        await AuthRepository().createOrganizationAccount(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getAllCategories(): Promise<IAllCategoryResponse> {
    try {
      const response = await AuthRepository().getAllCategories();
      return {
        ...response,
        message: response.data.message || "Success",
      };
    } catch (error) {
      throw error;
    }
  }

  async updateOrganizationStepOne(
    id: string,
    payload: IOrganizationStepOnePayload,
  ): Promise<IOrganizationStepOneData> {
    try {
      const response = await AuthRepository().saveOrganizationStepOne(
        id,
        payload,
      );

      return response.data as IOrganizationStepOneData;
    } catch (error) {
      throw error;
    }
  }
  async updateOrganizationStepTwo(
    id: string,
    payload: IOrganizationStepTwoPayload,
  ): Promise<IOrganizationStepOneResponse> {
    try {
      const response = await AuthRepository().saveOrganizationStepTwo(
        id,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
