import Validation from "common/utils/validation";
import { useForm } from "@tanstack/react-form";
import { KroButton } from "features/components/buttons/kroButton";
import KroOTPBox from "features/components/inputs/kroOTPBox";
import KroModal from "features/components/modals/kroModal";
import { useSendOTP, useVerifyOTP } from "../../hooks/useOTP";
import KroConstants from "common/constants/kroConstants";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { toast } from "react-toastify";
import SpamFolderOTPCountdown from "features/onboarding/presentation/components/spamFolderOTPCountdown";

const EmailVerificationModal: React.FC<{
  email: string;
  isOpen: boolean;
  type: EOTPUseCases;
  onClose: (isVerified: boolean) => void;
}> = ({ email, isOpen, onClose, type }) => {
  // const { showSnackbar } = useSnackbar();
  interface VerificationFormInputs {
    otp: string;
  }

  const form = useForm<VerificationFormInputs>({
    defaultValues: {
      otp: ""
    },

    onSubmit: (values) => {
      mutationVerifyOTP.mutate(values.value.otp);
    }
  });

  const mutationVerifyOTP = useVerifyOTP(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: any) => {
      handleClose(true);
    }
  );

  const mutateOTPRequest = useSendOTP(
    (error: Error) => toast.error(error.message),
    () => {
    }
  );

  const handleClose = (isVerified: boolean) => {
    form.reset();
    mutationVerifyOTP.reset();
    mutateOTPRequest.reset();
    onClose(isVerified);
  };

  return (
    <KroModal
      maxWidth={500}
      className="p-0"
      closeOnOutsideClick={false}
      showCloseButton={true}
      isOpen={isOpen}
      onClose={() => handleClose(false)}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className="m-2 flex flex-col items-center p-6 sm:m-3"
      >
        <img src="/assets/icons/mail.svg" alt="mail icon" className="mb-8" />
        <h2 className="mb-2 font-['Inter'] text-[32px] font-medium leading-[38.4px] tracking-[-0.005em]">
          Verification code
        </h2>

        <p
          className="text-sm mb-1 text-center font-['Inter'] text-[14px] font-[400] leading-[22px] tracking-[-0.005em] text-neutral-500">
          We’ve sent a 6-character code to{" "}
          <strong className="font-medium text-neutral-900">{email}</strong>. The
          code expires shortly, so please enter it soon.
        </p>

        <div className="mb-8 flex flex-col gap-1">
          <div
            className="flex h-[38px] cursor-pointer items-center justify-center text-[#C56A09]"
            onClick={() => handleClose(false)}
          >
            Change email address
          </div>
        </div>

        <div className="mt-[32px]">
          <form.Field
            validators={{
              onChange: ({ value }) => {
                return Validation.minLength(value, KroConstants.OTP_LENGTH);
              }
            }}
            name="otp"
            children={(field) => {
              return (
                <KroOTPBox
                  errorMessage={mutationVerifyOTP.error?.message}
                  length={KroConstants.OTP_LENGTH}
                  value={field.state.value}
                  isInvalid={field.state.meta.errors.join(", ").trim() !== ""}
                  onChange={(value) => field.handleChange(value)}
                ></KroOTPBox>
              );
            }}
          ></form.Field>
        </div>


        <SpamFolderOTPCountdown minutes={KroConstants.OTP_REQUEST_TIMEOUT} email={email} type={type}
                                onResend={() => {
                                  return mutateOTPRequest.mutateAsync({
                                    email: email,
                                    use_case: type
                                  });
                                }} />

        <KroButton
          className="mt-[32px] w-full"
          title="Verify Email"
          isLoading={mutationVerifyOTP.isPending}
          onclick={form.handleSubmit}
        ></KroButton>
      </form>
    </KroModal>
  );
};

export default EmailVerificationModal;
