import { useMutation } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { ProfileModel } from "../../domain/model/profileModel";

const DashboardService = new DashboardServiceImpl();

export const useUpdateProfileImage = (
  type: 'profile' | 'banner',
  onError?: (error: Error) => void,
  onSuccess?: (data: ProfileModel) => void
) => {
  return useMutation({
    mutationFn: (file: File) => 
      type === 'profile' 
        ? DashboardService.updateProfilePicture(file)
        : DashboardService.updateBannerPicture(file),
    onError,
    onSuccess,
  });
};

export default useUpdateProfileImage;
