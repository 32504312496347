import React, { FC, ReactNode, useEffect, useState } from "react";
import KroTooltip from "../display/kroToolTip";
import { formatCurrency } from "common/utils/currencyFormater";

interface KroAmountInputFieldProps {
  title: string;
  currency?: string;
  value: string;
  placeHolder: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  maxDigits?: number;
  className?: string;
  id?: string;
  ref?: React.RefObject<HTMLInputElement>;
  name?: string;
  errorMessage?: string;
  leadingAddon?: ReactNode;
}

const KroAmountInputField: FC<KroAmountInputFieldProps> = ({
                                                             title,
                                                             currency,
                                                             value,
                                                             ref,
                                                             placeHolder,
                                                             maxDigits = 10,
                                                             onChange,
                                                             id,
                                                             leadingAddon,
                                                             name,
                                                             readOnly = false,
                                                             className = "",
                                                             errorMessage
                                                           }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (readOnly || true) {
      setInputValue(formatCurrency(value));
    }
  }, [value, readOnly]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    if (numericValue.length > maxDigits) {
      return;
    }

    setInputValue(formatCurrency(inputValue));
    onChange(numericValue);
  };

  const handleClear = () => {
    setInputValue("");
    onChange("");
  };

  return (
    <div
      className={`w-full relative py-4 h-[96px] ${className} ${errorMessage ? "border-b border-red-500" : ""}`}
    >
      <h2 className="w-full text-left text-body-md font-[400] text-neutral-500 mb-1">
        {title}{" "}
        {errorMessage && (
          <span className="italic text-red-500">{errorMessage}</span>
        )}
      </h2>
      <div className="flex items-center w-full">
        {leadingAddon ? (
          <div className="flex items-center mr-2">
            {leadingAddon}
          </div>
        ) : (
          <span
            className={`text-H_7 leading-22 tracking-ultralTight mr-2 ${
              readOnly ? "text-neutral-400" : "text-neutral-900"
            }`}
          >
            {currency}
          </span>
        )}
        <div className="flex-1 relative">
          <input
            ref={ref}
            type="text"
            id={id}
            name={name}
            value={inputValue}
            placeholder={formatCurrency(placeHolder || "")}
            onChange={handleChange}
            readOnly={readOnly}
            className={`w-full rounded p-1 text-H_7 focus:outline-none ${
              readOnly
                ? "text-neutral-400 placeholder-neutral-400"
                : "text-neutral-900 placeholder-black"
            }`}
            style={{
              backgroundColor: "transparent",
              border: "none",
              caretColor: "black"
            }}
          />
        </div>
        {inputValue && !readOnly && (
          <KroTooltip subtitle="Clear input">
            <button onClick={handleClear} className="flex items-center ml-2">
              <img
                className="h-6 w-6"
                src="/assets/icons/closeIcon.svg"
                alt="close"
              />
            </button>
          </KroTooltip>
        )}
      </div>
    </div>
  );
};

export default KroAmountInputField;