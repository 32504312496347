import React from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

const JoinGivSection: React.FC = () => {
  const navigate = useNavigate();

  const StartFundraisingButton = (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className="flex items-center justify-center min-h-[56px] w-[245px] rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:h-[72px] mt-4 lg:mt-8"
    >
      <span className="mr-2">Start Fundraising</span>
      <img className="h-5 w-5" src="/assets/icons/Arrow.svg" alt="arrow" />
    </button>
  );

  return (
    <div className="max-w-[1280px] mx-auto px-4 py-8 lg:py-24">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Text Section */}
        <div
          className="border border-stroke-soft-200 rounded-[23px]
          lg:h-[731px] lg:max-h-[731px] lg:w-[524px] lg:max-w-[524px]
          p-6 lg:p-10 flex flex-col justify-center"
        >
          <h1
            className="font-dm-sans font-bold text-[40px] lg:text-[100px] text-[#022928] leading-[100%] tracking-[-3%] mb-4">
            Join Giv Today
          </h1>
          <p className="font-dm-sans text-[16px] lg:text-[30px] text-[#022928] leading-[125%] tracking-[-2%] mb-6">
            Happy fundraisers have reached their fundraising targets.
          </p>
          {StartFundraisingButton}
        </div>

        {/* Image Section */}
        <div
          className="flex-1 flex items-center justify-center
          lg:h-[731px] lg:max-h-[731px]"
        >
          <img
            src="/assets/images/join-giv.svg"
            alt="fundraiser target"
            className="w-full h-full object-cover rounded-[12px]"
          />
        </div>
      </div>
    </div>
  );
};

export default JoinGivSection;