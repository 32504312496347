import { PhoneNumberUtil } from "google-libphonenumber";

class Validation {
  static required(value: string | null | undefined | any): string | undefined {
    if (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return "This field is required.";
    }
    return undefined;
  }

  static oneWord(value: string): string | undefined {
    const oneWordRegex = /^\S+$/;
    if (!oneWordRegex.test(value.trim())) {
      return "This field must be a single word.";
    }
    return undefined;
  }

  static requiredFile(value: File | null): string | undefined {
    if (!value) {
      return "required.";
    }
    return undefined;
  }

  static minLength(value: string, length: number): string | undefined {
    if (value.length < length) {
      return ` at least ${length} characters long.`;
    }
    return undefined;
  }

  static phoneNumberByCountryCode(
    value: string,
    countryCode: string,
  ): string | undefined {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(value, countryCode),
        countryCode,
      );

      if (!isValidPhoneNumber) {
        return `invalid phone number please check input`;
      }
      return undefined;
    } catch (error) {
      return `invalid phone number please check input`;
    }
  }

  static noGibberish(value: string): string | undefined {
    const spaceRegex = /\s/;
    // const validCharsRegex = /^[a-zA-Z\s]+$/;

    if (!spaceRegex.test(value)) {
      return "Input must contain at least one space.";
    }
    //TODO: is this redundant ?
    // if (!validCharsRegex.test(value)) {
    //   return "Input contains invalid characters.";
    // }

    return undefined;
  }

  static maxLength(value: string, length: number): string | undefined {
    if (value.length > length) {
      return `This field must be no more than ${length} characters long.`;
    }
    return undefined;
  }
  static email(value: string): string | undefined {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      return "Please enter a valid email address.";
    }
    return undefined;
  }

  static websiteLink(value: string | undefined): string | undefined {
    if (!value) return;

    const trimmedValue = value.trim();

    const websiteRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(:\d+)?(\/.*)?$/;

    if (websiteRegex.test(trimmedValue)) {
      return "";
    }

    return "Invalid website link.";
  }

  static facebookLink(value: string | undefined): string | undefined {
    const facebookRegex =
      /^(https?:\/\/)?(www\.|web\.)?(facebook|fb)\.(com|me)\/[a-zA-Z0-9.]{5,}$/;

    if (value === undefined || value === null || value === "") return;

    if (!facebookRegex.test(value)) {
      return "Invalid Facebook link.";
    }
    return undefined;
  }

  static instagramLink(value: string | undefined): string | undefined {
    if (!value) return;

    const cleanValue = value.trim();
    const valueWithoutAt = cleanValue.startsWith("@")
      ? cleanValue.substring(1)
      : cleanValue;

    const isUsernameOnly = /^[A-Za-z0-9_.]{1,30}$/.test(valueWithoutAt);
    if (isUsernameOnly) return;

    const match = cleanValue.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:x\.com)\/([A-Za-z0-9_.]{1,30})\/?$/,
    );

    if (match) return;

    return "Invalid Instagram username or link.";
  }

  static tiktokLink(value: string | undefined): string | undefined {
    if (!value) return;

    const trimmedValue = value.trim();

    if (/^@[a-zA-Z0-9._]{2,24}$/.test(trimmedValue)) {
      return "";
    }

    if (
      /^(https?:\/\/)?(www\.)?tiktok\.com\/@([a-zA-Z0-9._]{2,24})\/?$/.test(
        trimmedValue,
      )
    ) {
      return "";
    }

    return "Invalid TikTok username or link.";
  }

  static linkedinLink(value: string | undefined): string | undefined {
    const linkedinRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9-_%]+\/?$/;

    if (!value) return;

    if (!linkedinRegex.test(value)) {
      return "Invalid LinkedIn link.";
    }
    return undefined;
  }

  static xLink(value: string | undefined): string | undefined {
    if (!value) return;

    const cleanValue = value.trim();
    const valueWithoutAt = cleanValue.startsWith("@")
      ? cleanValue.substring(1)
      : cleanValue;

    const isUsernameOnly = /^[A-Za-z0-9_.]{1,30}$/.test(valueWithoutAt);
    if (isUsernameOnly) return;

    const match = cleanValue.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:x\.com)\/([A-Za-z0-9_.]{1,30})\/?$/,
    );

    if (match) return;

    return "Invalid X username or link.";
  }
  static youtubeLink(value: string): string | undefined {
    const youtubeRegex =
      /^(www\.youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}/;
    if (!youtubeRegex.test(value)) {
      return "Invalid YouTube link. Please include the full URL without the https:// prefix.";
    }
    return undefined;
  }
  static amount(value: string): string | undefined {
    if (
      (!value && typeof value === "number") ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return "required";
    }

    const amount = parseFloat(value);
    if (isNaN(amount) || amount <= 0) {
      return "invalid amount.";
    }
    return undefined;
  }
  /**
   *
   * @param file
   * @param maxSizeInMB
   */
  static maxFileSize(file: File, maxSizeInMB: number): string | undefined {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      return `File size should not exceed ${maxSizeInMB} MB.`;
    }
    return undefined;
  }
}

export default Validation;
