import Routes from "router/routes";
import { useNavigate } from "react-router";

export const CallToActionButton: React.FC<{
  isTransparent: boolean;
  className?: string;
  title?: string;
}> = ({ isTransparent, className, title }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className={`flex h-[56px] w-[229px] ${isTransparent ? "border border-[#4FBA6D]" : ""} transform items-center justify-center rounded-[60px] ${
        isTransparent ? "bg-transparent" : "bg-[#022928]"
      } <div className="mb-8 lg:mt-[54px]"> <InnerFundRaisingButton isTransparent={false} /> </div> mt-8 text-white transition-transform hover:scale-105 active:scale-95 lg:flex lg:h-[72px] lg:w-[245px] ${className}`}
    >
      <p
        className={`text-lg mr-2 text-left font-inter font-medium leading-[18px] ${isTransparent ? "text-[#4FBA6D]" : "text-white"}`}
      >
        {title ? title : "Start Fundraising"}
      </p>
      <img
        className="h-5 w-5"
        src={`/assets/icons/${isTransparent ? "green-arrow.svg" : "Arrow.svg"}`}
        alt="arrow"
      />
    </button>
  );
};
