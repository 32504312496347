import { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { KroButton } from "../buttons/kroButton";

interface ImageCropperProps {
  imageUrl: string;
  aspect: number;
  onCropComplete: (croppedImage: string) => void;
  onCancel: () => void;
  isSaving: boolean;
}

const KroImageCropper = ({
  imageUrl,
  aspect,
  onCropComplete,
  isSaving,
  onCancel,
}: ImageCropperProps) => {
  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    return () => {
      if (cropper) {
        cropper.destroy();
        setCropper(null);
      }
    };
  }, [cropper]);

  const getCroppedImage = () => {
    if (cropper) {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      onCropComplete(croppedImage);
    }
  };

  return (
    <div className="flex flex-col gap-4 pb-3">
      <Cropper
        src={imageUrl}
        aspectRatio={aspect}
        guides={true}
        onInitialized={(instance) => setCropper(instance)}
      />
      <div className="flex justify-end gap-2">
        <button
          className="rounded-md border px-4 py-2 hover:border-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <KroButton
          title="Crop"
          onclick={getCroppedImage}
          isLoading={isSaving}
          isDisabled={isSaving}
        />
      </div>
    </div>
  );
};

export default KroImageCropper;
