import Routes from "router/routes";
import { useNavigate, useParams } from "react-router-dom";
import { KroUseCases } from "common/enums/KroUseCases.enum";
import FAQ from "../components/faqComponent";
import { usecaseData } from "../../data/models/usecaseModel";
import WebsiteFooter from "common/layout/websiteFooter";

const StartFundraisingButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className="flex h-[56px] w-[245px] transform items-center justify-center rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:h-[72px]"
    >
      <span className="mr-2">Start Fundraising</span>
      <img className="h-5 w-5" src="/assets/icons/Arrow.svg" alt="arrow" />
    </button>
  );
};

export const UseCasesPage = () => {
  const { usecase } = useParams<{ usecase: KroUseCases }>();
  const usecaseInfo = usecase ? usecaseData[usecase] : null;

  return (
    <>
      <div
        key={usecase}
        className="mx-[20px] mt-[56px] flex max-w-[1440px] flex-col items-center justify-center lg:mx-[80px] lg:mt-[13px] lg:flex-row"
      >
        <div className="flex-1 items-center justify-center">
          {usecaseInfo && (
            <>
              <h1 className="font-dm-sans text-[42px] font-medium leading-[42px] tracking-[-0.03em] text-[#131313] lg:w-[633px] lg:text-left lg:text-[72px] lg:font-medium lg:leading-[72px] lg:tracking-[-3%]">
                {usecaseInfo.header}
              </h1>
              <p className="mt-[20px] font-inter text-[20px] font-normal leading-[28px] text-[#0E121B] lg:mt-[48px]">
                {usecaseInfo.description}
              </p>
            </>
          )}
          <div className="mt-[20px] flex lg:mt-[48px]">
            <StartFundraisingButton />
          </div>
        </div>

        <div className="mt-[20px] flex h-[651px] flex-1 justify-center">
          <img
            src={usecaseInfo?.image}
            alt={usecaseInfo?.header}
            height="100%"
          />
        </div>
      </div>

      {usecaseInfo && (
        <div className="mt-[56px] flex w-[358px] flex-col px-[16px] lg:mx-auto lg:mt-[120px] lg:w-[791px] lg:items-center lg:justify-center">
          <p className="font-dm-sans text-[42px] font-medium leading-[42px] text-[#131313] lg:text-center lg:text-[72px] lg:leading-[72px] lg:tracking-[-0.03em]">
            {usecaseInfo.howTo}
          </p>
          <p className="mt-[24px] font-inter text-[16px] lg:mt-[32px] lg:text-center lg:text-[18px] lg:font-normal lg:leading-[25.2px]">
            Setting up a Fundraiser on Giv is simple, smooth and easy. Here’s
            how it works:
          </p>
        </div>
      )}

      <div className="mt-[42px] flex flex-col gap-[32px] lg:mx-[75px] lg:mt-[60px] lg:flex-row lg:gap-[45px]">
        {[1, 2, 3].map((step) => (
          <div key={step} className="flex-1 px-[16px]">
            <img
              src={`/assets/images/usecase-step-${step}.svg`}
              alt={`step ${step}`}
              height="100%"
              width="100%"
            />
          </div>
        ))}
      </div>

      <div className="hidden flex-row items-center justify-self-center lg:mt-[64px] lg:flex">
        <StartFundraisingButton />
      </div>
      <FAQ />
      <WebsiteFooter />
    </>
  );
};
