import { ProfileModel } from "features/dashboard/domain/model/profileModel";

const SocialMediaPill = ({ profileData }: { profileData?: ProfileModel }) => {
  const getSocialMediaUsername = (link: string) => {
    const platform = link.split("/").pop()?.replace(".svg", "");

    const urls = {
      instagram: profileData?.socials?.instagram ?? "",
      tiktok: profileData?.socials?.tiktok ?? "",
      linkedin: profileData?.socials?.linkedin ?? "",
      twitter: profileData?.socials?.x ?? "",
      facebook: profileData?.socials?.facebook ?? ""
    };

    const url = urls[platform as keyof typeof urls] || "";

    if (!url) return "";

    const username = url
      .replace("https://", "")
      .replace("http://", "")
      .replace("www.", "")
      .split("/")
      .pop()
      ?.replace("@", "");

    return username ? `@${username}` : "";
  };

  const getSocialMediaUrl = (icon: string) => {
    const platform = icon.split("/").pop()?.replace(".svg", "");

    const urls = {
      instagram: profileData?.socials?.instagram ?? "",
      tiktok: profileData?.socials?.tiktok ?? "",
      linkedin: profileData?.socials?.linkedin ?? "",
      twitter: profileData?.socials?.x ?? "",
      facebook: profileData?.socials?.facebook ?? ""
    };

    return urls[platform as keyof typeof urls] || "";
  };

  const socialIcons = [
    "/assets/icons/instagram.svg",
    "/assets/icons/tiktok.svg",
    "/assets/icons/linkedin.svg",
    "/assets/icons/twitter.svg",
    "/assets/icons/facebook_icon.svg"
  ];

  return (
    <div className="mt-[16px] flex flex-col gap-[8px] lg:flex-row">
      {socialIcons.map((icon, index) => {
        const username = getSocialMediaUsername(icon);
        if (!username) return null;

        const url = getSocialMediaUrl(icon);

        return (
          <button
            key={index}
            onClick={() => {
              window.open(url, "_blank");
            }}
            className="flex items-center gap-1 rounded-[32px] border border-[#4FBA6D] px-[8px] py-[4px] animate-slide-in"
          >
            <img
              src={icon}
              alt={icon.split("/").pop()?.replace(".svg", "")}
              className="h-4 w-4"
            />
            <span className="font-dm-sans text-[12px] font-normal leading-[18px] text-[#022928]">
              {username}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default SocialMediaPill;
