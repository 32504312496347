import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const KroDropdown = (props: KroDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const _toggleDropdown = () => {
    if (!props.isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const filteredOptions = props.options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative w-full ${props.className}`} ref={dropdownRef}>
      <label className="text-md font-medium-regular mb-2 block text-left font-inter leading-[22px] tracking-[-0.005em]">
        {props.label}
      </label>

      <div
        className={`flex w-full cursor-${
          props.isDisabled ? "not-allowed" : "pointer"
        } justify-between rounded-[20px] border ${
          props.errorMessage ? "border-red-500" : "border-neutral-300"
        } ${props.fillColor ?? "bg-white"} ${
          props.isDisabled ? "opacity-60" : ""
        } p-3 leading-22 text-black placeholder:text-neutral-500 focus:ring-2`}
        onClick={_toggleDropdown}
        tabIndex={props.isDisabled ? -1 : 0}
        id={props.id}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            _toggleDropdown();
          }
        }}
        onFocus={(e) =>
          e.currentTarget.classList.add("ring-black", "border-black")
        }
        onBlur={(e) =>
          e.currentTarget.classList.remove("ring-black", "border-black")
        }
      >
        <span className={props.selectedValue ? "text-black" : "text-[#737373]"}>
          {props.selectedValue || props.placeHolder}
        </span>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-500 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div
          className={`${
            props.hoverType === "fixed" ? "relative" : "absolute right-0"
          } z-30 mt-2 w-[283px] rounded-[20px] border border-gray-300 bg-white p-2 shadow-lg`}
        >
          <div className="flex items-center rounded-xl border border-neutral-300 px-3">
            <img
              src="/assets/icons/search-grey.svg"
              className="mr-2 h-5 w-5"
              alt="search"
            ></img>
            <input
              type="text"
              className="w-full p-2 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <ul className="mt-2 max-h-[382px] overflow-y-auto">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className="mb-2 cursor-pointer px-4 py-2 hover:bg-gray-100"
                onClick={() => {
                  if (option === "Custom") return;

                  props.onDropdownItemClicked(option);
                  setIsOpen(false);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (option === "Custom") return;
                  if (e.key === "Enter" || e.key === " ") {
                    props.onDropdownItemClicked(option);
                    setIsOpen(false);
                  }
                }}
              >
                {option === "Custom" ? (
                  <input
                    type="text"
                    className="w-full rounded border border-gray-300 p-2 focus:outline-none"
                    placeholder={props.customHint}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        (e.target as HTMLInputElement).value.trim() !== ""
                      ) {
                        props.onDropdownItemClicked(
                          (e.target as HTMLInputElement).value,
                        );
                        setIsOpen(false);
                      }
                    }}
                  />
                ) : (
                  option
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {props.errorMessage && (
        <p className="text-sm italic text-red-500">{props.errorMessage}</p>
      )}
    </div>
  );
};

const KroDropdownT = <T,>(props: KroDropdownPropsT<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const _toggleDropdown = () => {
    if (!props.isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectedValue = props.options.find(
    (e) => e.value === props.selectedValue,
  )?.name;

  const filteredOptions = props.options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative w-full ${props.className}`} ref={dropdownRef}>
      <label className="text-md font-medium-regular mb-2 block text-left font-inter leading-[22px] tracking-[-0.005em]">
        {props.label}
      </label>

      <div
        className={`flex w-full cursor-${
          props.isDisabled ? "not-allowed" : "pointer"
        } justify-between rounded-[20px] border ${
          props.errorMessage ? "border-red-500" : "border-neutral-300"
        } ${props.fillColor ?? "bg-white"} ${
          props.isDisabled ? "opacity-60" : ""
        } p-3 leading-22 text-black placeholder:text-neutral-500 focus:ring-2`}
        onClick={_toggleDropdown}
        tabIndex={props.isDisabled ? -1 : 0}
        id={props.id}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            _toggleDropdown();
          }
        }}
        onFocus={(e) =>
          e.currentTarget.classList.add("ring-black", "border-black")
        }
        onBlur={(e) =>
          e.currentTarget.classList.remove("ring-black", "border-black")
        }
      >
        <span className={props.selectedValue ? "text-black" : "text-[#737373]"}>
          {props.selectedItemRenderer
            ? props.selectedValue &&
              props.selectedItemRenderer(props.selectedValue)
            : selectedValue}
        </span>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-500 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div
          className={`${
            props.hoverType === "fixed"
              ? "relative w-full"
              : "absolute right-0 w-[283px]"
          } z-30 mt-2 rounded-[20px] border border-gray-300 bg-white p-2 shadow-lg`}
        >
          <div className="flex items-center rounded-xl border border-neutral-300 px-3">
            <img
              src="/assets/icons/search-grey.svg"
              className="mr-2 h-5 w-5"
              alt="search"
            ></img>
            <input
              type="text"
              className="w-full p-2 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <ul className="mt-2 max-h-[382px] overflow-y-auto">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className="mb-2 cursor-pointer px-4 py-2 hover:bg-gray-100"
                onClick={() => {
                  if (option.name === "Custom") return;

                  props.onDropdownItemClicked(option.value);
                  setIsOpen(false);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (option.name === "Custom") return;
                  if (e.key === "Enter" || e.key === " ") {
                    props.onDropdownItemClicked(option.value);
                    setIsOpen(false);
                  }
                }}
              >
                {option.name === "Custom" ? (
                  <input
                    type="text"
                    className="w-full rounded border border-gray-300 p-2 focus:outline-none"
                    placeholder={props.customHint}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        (e.target as HTMLInputElement).value.trim() !== ""
                      ) {
                        props.onDropdownItemClicked(
                          (e.target as HTMLInputElement).value as unknown as T,
                        );
                        setIsOpen(false);
                      }
                    }}
                  />
                ) : (
                  option.name
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {props.errorMessage && (
        <p className="text-sm italic text-red-500">{props.errorMessage}</p>
      )}
    </div>
  );
};

export interface KroDropdownProps {
  label: string;
  placeHolder: string;
  selectedValue: string;
  ref?: any;
  id?: string;
  fillColor?: string;
  className: string;
  customHint?: string;
  options: string[];
  onDropdownItemClicked: (option: string) => void;
  errorMessage?: string;
  hoverType?: "hover" | "fixed";
  isDisabled?: boolean;
}

interface KroDropdownPropsT<T>
  extends Omit<
    KroDropdownProps,
    "selectedValue" | "onDropdownItemClicked" | "options"
  > {
  selectedValue: T | null;
  options: KroDropdownOption<T>[];
  onDropdownItemClicked: (option: T) => void;
  selectedItemRenderer?: (item: T) => React.ReactNode;
}

export interface KroDropdownOption<T> {
  value: T;
  name: string;
  leftIcon?: React.ReactNode;
}

export default KroDropdown;

export { KroDropdownT };
