import { formatCurrency } from "common/utils/currencyFormater";
import React from "react";

interface PresetAmountsProps {
  targetAmount: number;
  onPresetSelected: (amount: number) => void;
  className?: string;
  currencySymbol?: string;
}

const PresetAmounts: React.FC<PresetAmountsProps> = ({
                                                       targetAmount,
                                                       className,
                                                       onPresetSelected,
                                                       currencySymbol
                                                     }) => {
  const calculatePresetAmounts = (target: number, currency?: string): number[] => {
    if (currency && currency !== "₦") {
      return [10, 20, 50, 100];
    }

    if (target <= 0) return [];

    const logT = Math.log10(target);
    const percentages = [1, 5, 10, 15, 20].map((A) => (A * (1 / logT)));

    let amounts = percentages.map((p) => Math.round(p * target / 100));

    amounts = amounts.map((amt) => {
      if (amt < 100000) {
        return Math.round(amt / 10000) * 10000;
      } else if (amt < 500000) {
        return Math.round(amt / 50000) * 50000;
      } else {
        return Math.round(amt / 100000) * 100000;
      }
    });

    const uniqueAmounts: number[] = [];
    amounts.forEach(amt => {
      if (amt > 0 && !uniqueAmounts.includes(amt)) {
        uniqueAmounts.push(amt);
      }
    });

    return uniqueAmounts;
  };

  const presetAmounts = calculatePresetAmounts(targetAmount, currencySymbol);

  return (
    <div className={`mt-4 ${className}`}>
      <div
        className="flex gap-2 overflow-x-auto whitespace-nowrap no-scrollbar"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        <style>{`
          .no-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, and Opera */
          }
        `}</style>
        {presetAmounts.map((amount, index) => (
          <button
            key={index}
            className="flex w-full max-w-[120px] flex-grow items-center justify-center rounded-[32px] border bg-neutral-50 px-2 py-3 text-center text-body-lg font-medium leading-[24px] hover:bg-neutral-200 sm:max-w-[180px] md:max-w-[200px]"
            onClick={() => onPresetSelected(amount)}
          >
            {formatCurrency(amount, true, currencySymbol)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PresetAmounts;