import { useMutation } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { UpdateSocialMediaLinksPayload } from "../../domain/model/updateSocialMediaLinksPayload";

const DashboardService = new DashboardServiceImpl();

const useUpdateSocialMediaLinks = (
  onError:
    | ((
        error: Error,
        variables: UpdateSocialMediaLinksPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: {},
        variables: UpdateSocialMediaLinksPayload,
        context: unknown,
      ) => Promise<{}> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (payload: UpdateSocialMediaLinksPayload) =>
      DashboardService.updateSocialMediaLinks(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export default useUpdateSocialMediaLinks;
