import { UserModel } from "common/models/userModel";

export interface SignInResponseData {
  tokenIsValid: boolean;
  jwtToken: JwtToken;
  user: UserModel;
}

export interface JwtToken {
  accessToken: string;
  refreshToken: string;
}

export interface UserResponse {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    organisation_name: string;
    category: string;
    country: string;
    state: string;
    admin_first_name: string;
    admin_last_name: string;
    slug:string
    admin_phone_number: string;
    is_organisation_registered: boolean;
    registration_number: string;
}

export function userModelFromObject(user: UserResponse): UserModel {
    return {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        organisationName: user.organisation_name,
        category: user.category,
        country: user.country,
        state: user.state,
        slug: user.slug,
        adminFirstName: user.admin_first_name,
        adminLastName: user.admin_last_name,
        adminPhoneNumber: user.admin_phone_number,
        isOrganisationRegistered: user.is_organisation_registered,
        registrationNumber: user.registration_number,
    };
}
