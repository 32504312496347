import CountryList, { Country } from "country-list-with-dial-code-and-flag";
import { FC, useEffect, useState } from "react";

interface IPhoneNumber {
  dialCode: string;
  countryCode: string;
}

interface Props {
  value: string;
  onChange: (value: IPhoneNumber) => void;
  userCountryCode?: string;
}

const KroPhoneNumberCountryCodeDropDown: FC<Props> = ({
                                                        value,
                                                        onChange,
                                                        userCountryCode
                                                      }) => {
  const Countries: Country[] = CountryList.getAll().sort((a, b) =>
    a.dialCode.localeCompare(b.dialCode)
  );

  const [selectedCountry, setSelectedCountry] = useState<string>(value);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!value && userCountryCode) {
      const country = Countries.find((c) => c.code.toLowerCase() === userCountryCode.toLowerCase());
      if (country) {
        setSelectedCountry(country.code);
        onChange({
          dialCode: country.dialCode,
          countryCode: country.code
        });
      }
    } else if (value) {
      const country = Countries.find((c) => c.dialCode === value || c.code === value);
      if (country) {
        setSelectedCountry(country.code);
      }
    }
  }, [userCountryCode, value, onChange, Countries]);

  const getDisplayFlag = () => {
    const byCountryCode = CountryList.findOneByCountryCode(selectedCountry);
    if (byCountryCode) return byCountryCode.flag;

    const byDialCode = CountryList.findOneByDialCode(selectedCountry);
    if (byDialCode) return byDialCode.flag;

    return Countries[0].flag;
  };

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center justify-center gap-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{getDisplayFlag()}</span>
        <img
          alt="V"
          src="/assets/icons/drop_down.svg"
          className={`h-3 w-3 text-gray-500 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 mt-2 max-h-60 w-28 overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {Countries.map((country) => (
            <li
              key={country.code}
              className="flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100"
              onClick={() => {
                setSelectedCountry(country.code);
                onChange({
                  dialCode: country.dialCode,
                  countryCode: country.code
                });
                setIsOpen(false);
              }}
            >
              <span>{country.flag}</span>
              <span>{country.dialCode}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default KroPhoneNumberCountryCodeDropDown;