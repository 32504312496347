import { KroUseCases } from "common/enums/KroUseCases.enum";

export const usecaseData = {
  [KroUseCases.CHARITY]: {
    header: "Fundraise for Your Favourite Charity on Giv",
    description:
      "Looking to contribute to the great work your favourite charity is already doing? You can help them raise funds to make an even bigger difference!",
    howTo: "How to Set Up a Charity Fundraiser",
    image: "/assets/images/charity-usecase.svg",
  },
  [KroUseCases.FINANCIAL_EMERGENCIES]: {
    header: "Fundraise for Financial Emergencies on Giv",
    description:
      "Looking to raise money for an emergency beyond your current financial capacity? You can raise funds more easily and quickly on our platform!",
    howTo: "How to Set Up an Emergency Fundraiser",
    image: "/assets/images/emergency-usecase.svg",
  },
  [KroUseCases.CLUB_AND_ASSOCIATIONS]: {
    header: "Fundraise for Club & Association Activities on Giv",
    description:
      "Looking to set up a group contribution with your club and association for a joint project or activity? You can raise funds transparently on our platform!",
    howTo: "How to Set Up a Group Fundraiser",
    image: "/assets/images/club-and-activities-usecase.svg",
  },
  [KroUseCases.RELIGIOUS]: {
    header: "Fundraise for Religious Projects & Causes on Giv",
    description:
      "Looking to help a religious organisation with their project or cause? You can make a difference by helping them raise funds!",
    howTo: "How to Set Up a Religious Fundraiser",
    image: "/assets/images/religious-usecase.svg",
  },
  [KroUseCases.PROJECTS]: {
    header: "Fundraise for your Projects on Giv",
    description:
      "Looking to set up a crowdfunding campaign to raise funds for your project? You can raise funds with trust and transparency on our platform!",
    howTo: "How to Set Up a Project Fundraiser",
    image: "/assets/images/projects-usecase.svg",
  },
  [KroUseCases.MEDICAL]: {
    header: "Fundraise for Medical Causes on Giv",
    description:
      "Looking to raise money to attend to medical needs and emergencies? You can raise funds with trust and transparency on our platform!",
    howTo: "How to Set Up a Medical Fundraiser",
    image: "/assets/images/medical-usecase.svg",
  },
};
