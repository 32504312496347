export interface Country {
  name: string;
  code: string;
  cca2: string;
}

// Using a curated list of countries with their ISO codes
export const COUNTRIES: Country[] = [
  { name: "Afghanistan", code: "AF", cca2: "AF" },
  { name: "Albania", code: "AL", cca2: "AL" },
  { name: "Algeria", code: "DZ", cca2: "DZ" },
  { name: "Andorra", code: "AD", cca2: "AD" },
  { name: "Angola", code: "AO", cca2: "AO" },
  { name: "Argentina", code: "AR", cca2: "AR" },
  { name: "Australia", code: "AU", cca2: "AU" },
  { name: "Austria", code: "AT", cca2: "AT" },
  { name: "Belgium", code: "BE", cca2: "BE" },
  { name: "Brazil", code: "BR", cca2: "BR" },
  { name: "Canada", code: "CA", cca2: "CA" },
  { name: "China", code: "CN", cca2: "CN" },
  { name: "Egypt", code: "EG", cca2: "EG" },
  { name: "France", code: "FR", cca2: "FR" },
  { name: "Germany", code: "DE", cca2: "DE" },
  { name: "Ghana", code: "GH", cca2: "GH" },
  { name: "India", code: "IN", cca2: "IN" },
  { name: "Indonesia", code: "ID", cca2: "ID" },
  { name: "Italy", code: "IT", cca2: "IT" },
  { name: "Japan", code: "JP", cca2: "JP" },
  { name: "Kenya", code: "KE", cca2: "KE" },
  { name: "Mexico", code: "MX", cca2: "MX" },
  { name: "Netherlands", code: "NL", cca2: "NL" },
  { name: "New Zealand", code: "NZ", cca2: "NZ" },
  { name: "Nigeria", code: "NG", cca2: "NG" },
  { name: "Norway", code: "NO", cca2: "NO" },
  { name: "Russia", code: "RU", cca2: "RU" },
  { name: "Saudi Arabia", code: "SA", cca2: "SA" },
  { name: "South Africa", code: "ZA", cca2: "ZA" },
  { name: "South Korea", code: "KR", cca2: "KR" },
  { name: "Spain", code: "ES", cca2: "ES" },
  { name: "Sweden", code: "SE", cca2: "SE" },
  { name: "Switzerland", code: "CH", cca2: "CH" },
  { name: "Tanzania", code: "TZ", cca2: "TZ" },
  { name: "Turkey", code: "TR", cca2: "TR" },
  { name: "Uganda", code: "UG", cca2: "UG" },
  { name: "United Arab Emirates", code: "AE", cca2: "AE" },
  { name: "United Kingdom", code: "GB", cca2: "GB" },
  { name: "United States", code: "US", cca2: "US" },
  { name: "Zimbabwe", code: "ZW", cca2: "ZW" },
].sort((a, b) => a.name.localeCompare(b.name));
