import { ProfileModel } from "features/dashboard/domain/model/profileModel";

const ProfileSocialMediaComponent = ({
  onClick,
  isLoading,
  profileData,
}: {
  onClick: () => void;
  isLoading?: boolean;
  profileData?: ProfileModel;
}) => {
  const socialMediaLinks = [
    "/assets/icons/facebook.svg",
    "/assets/icons/instagram.svg",
    "/assets/icons/tiktok.svg",
    "/assets/icons/linkedin.svg",
    "/assets/icons/twitter.svg",
  ];

  const getBackgroundColor = (link: string) => {
    const platform = link.split("/").pop()?.replace(".svg", "");

    const colors = {
      instagram: !isLoading ? "bg-[#F9F0FA]" : "bg-white",
      tiktok: !isLoading ? "bg-[#E5FFFE]" : "bg-white",
      linkedin: !isLoading ? "bg-[#D8EBFD]" : "bg-white",
      twitter: !isLoading ? "bg-[#FDE9D8]" : "bg-white",
      facebook: "bg-white",
    };

    return colors[platform as keyof typeof colors] || "bg-white";
  };

  const getSocialMediaLink = (link: string) => {
    const platform = link.split("/").pop()?.replace(".svg", "");

    const urls = {
      instagram: profileData?.socials?.instagram ?? "",
      tiktok: profileData?.socials?.tiktok ?? "",
      linkedin: profileData?.socials?.linkedin ?? "",
      twitter: profileData?.socials?.x ?? "",
      facebook: profileData?.socials?.facebook ?? "",
    };
    return urls[platform as keyof typeof urls] || "";
  };

  return (
    <div
      className={`my-[16px] ml-[24px] mr-[24px] flex h-[148px] w-[345px] flex-col items-center justify-center rounded-[24px] ${!isLoading ? "bg-[#F2F6F2]" : "bg-gray-200"} ${isLoading ? "animate-pulse" : ""} lg:w-[560px]`}
    >
      <div className="flex flex-row">
        {socialMediaLinks.map((link, index) => (
          <button
            onClick={() => {
              window.open(getSocialMediaLink(link), "_blank");
            }}
            key={index}
            className={`flex h-8 w-8 items-center justify-center rounded-full border-2 border-white ${getBackgroundColor(link)} relative -ml-2 transition-all duration-200 first:ml-0 hover:z-10 hover:ml-0`}
          >
            {!isLoading ? (
              <img
                src={link}
                alt={
                  link.split("/").pop()?.replace(".svg", "") ||
                  "social media icon"
                }
              />
            ) : (
              ""
            )}
          </button>
        ))}
      </div>

      <p className="mt-[8px] w-[301px] text-center font-dm-sans text-[12px] font-normal leading-[15.62px] text-sub-600">
        {!isLoading
          ? "Add your social media handles so donors can connect with you."
          : ""}
      </p>
      <button
        onClick={onClick}
        className="mx-[12px] my-[2px] mt-[16px] h-[28px] w-[167px] rounded-[32px] bg-white transition duration-300 ease-in-out hover:scale-105"
      >
        <p className="font-dm-sans text-[12px] font-normal leading-[24px] text-sub-600">
          {!isLoading ? "Add social media handles" : ""}
        </p>
      </button>
    </div>
  );
};

export default ProfileSocialMediaComponent;
