import { Donations } from "./donations";
import { CampaignVisibility } from "./updateCampaignInformationPayload";

interface CampaignModel {
  getRemainingAmount(): number;
}

interface CampaignModel {
  id: string | null;
  beneficiary: string | null;
  image_urls: string[] | null;
  category: string;
  name_of_beneficiary: string;
  status: string;
  title: string;
  story: string;
  is_anonymous?: string;
  description: string;
  creatorName: string;
  video_url: string;
  amount_raised: number;
  target_amount: number | null | undefined;
  donations: Donations[] | undefined;
  start_date?: Date;
  end_date?: Date | null | undefined;
  createdAt: Date;
  withdrawal_requested: boolean;
  creator_avatar?: string | undefined;
  visibility: CampaignVisibility;
  updatedAt: Date;
  authorSlug?: string | null;
  slug?: string | null;
}

export interface CampaignModelMapper {
  id: string | null;
  beneficiary: string | null;
  imageUrls: string[] | null;
  category: string;
  nameOfBeneficiary: string;
  withDrawalRequested: boolean;
  title: string;
  status: string;
  isAnonymous: string;
  story: string | undefined;
  creatorName: string;
  description: string | undefined;
  videoUrl: string | undefined;
  amountRaised: number;
  targetAmount: number | undefined | null;
  donations: Donations[] | undefined;
  startDate?: Date | undefined | null;
  endDate?: Date | null | undefined;
  createdAt: Date;
  updatedAt: Date;
  visibility: CampaignVisibility;
  creatorAvatar?: string | undefined | null;
  authorSlug?: string | null;
  slug?: string | null;
}

export function mapCampaignModel(data: CampaignModel): CampaignModelMapper {
  return {
    id: data.id ?? "",
    withDrawalRequested: data.withdrawal_requested ?? false,
    beneficiary: data.beneficiary ?? "",
    imageUrls: data.image_urls ?? [],
    category: data.category ?? "",
    nameOfBeneficiary: data.name_of_beneficiary ?? "",
    title: data.title ?? "",
    story: data.story ?? "",
    visibility: data.visibility ?? false,
    creatorName: data.creatorName ?? "",
    description: data.description ?? "",
    videoUrl: data.video_url ?? "",
    amountRaised: data.amount_raised ?? "",
    targetAmount: data.target_amount ?? 0,
    status: data.status ?? "",
    donations: data.donations ?? [],
    startDate: data.start_date ?? null,
    isAnonymous: data.is_anonymous ?? "",
    endDate: data.end_date ?? null,
    createdAt: data.createdAt ?? null,
    updatedAt: data.updatedAt ?? null,
    creatorAvatar: data.creator_avatar ?? null,
    authorSlug: data.authorSlug ?? null,
    slug: data.slug ?? null
  };
}

export function isCampaignModelMapper(
  object: any
): object is CampaignModelMapper {
  return (
    object &&
    (typeof object.withDrawalRequested === "boolean" ||
      object.withDrawalRequested === null) &&
    (typeof object.id === "string" || object.id === null) &&
    (typeof object.creatorName === "string" || object.creatorName === null) &&
    (typeof object.beneficiary === "string" || object.beneficiary === null) &&
    (Array.isArray(object.imageUrls) ||
      object.imageUrls === null ||
      object.imageUrls === undefined) &&
    (typeof object.category === "string" || object.category === undefined) &&
    (typeof object.nameOfBeneficiary === "string" ||
      object.nameOfBeneficiary === undefined) &&
    (typeof object.title === "string" || object.title === undefined) &&
    (typeof object.visibility === "string" ||
      object.visibility === undefined) &&
    (typeof object.status === "string" || object.status === undefined) &&
    (typeof object.story === "string" || object.story === undefined) &&
    (typeof object.description === "string" ||
      object.description === undefined) &&
    (typeof object.videoUrl === "string" ||
      object.videoUrl === null ||
      object.videoUrl === undefined) &&
    (typeof object.amountRaised === "number" ||
      object.amountRaised === undefined) &&
    (typeof object.targetAmount === "number" ||
      object.targetAmount === undefined) &&
    (Array.isArray(object.donations) || object.donations === undefined) &&
    (object.startDate instanceof Date ||
      typeof object.startDate === "string" ||
      object.startDate === undefined) &&
    (object.endDate instanceof Date ||
      object.endDate === null ||
      typeof object.endDate === "string" ||
      object.endDate === undefined) &&
    (object.createdAt instanceof Date ||
      typeof object.createdAt === "string" ||
      object.createdAt === undefined) &&
    (typeof object.withDrawalRequested === "boolean" ||
      object.withDrawalRequested === null) &&
    (typeof object.creatorAvatar === "string" ||
      object.creatorAvatar === null ||
      object.creatorAvatar === undefined) &&
    (typeof object.authorSlug === "string" || object.authorSlug === null || object.authorSlug === undefined) &&
    (typeof object.slug === "string" || object.slug === null || object.slug === undefined) &&
    (object.updatedAt instanceof Date ||
      typeof object.updatedAt === "string" ||
      object.updatedAt === undefined)
  );
}