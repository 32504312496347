import WebsiteFooter from "common/layout/websiteFooter";
import { InnerFundRaisingButton } from "../components/innerFundraisingButton";
import { StartFundraisingButton } from "../components/startFundraisingButton";
import FAQ from "../components/faqComponent";

const HowToUsePage = () => {
  return (
    <>
      <div className="mx-[20px] mt-[10px] flex max-w-[1440px] flex-col items-start lg:mx-[80px] lg:mb-[194px] lg:mt-[36px] lg:flex-row lg:items-center lg:justify-center">
        <div className="mt-[42px] flex w-[358px] flex-1 flex-col lg:w-[710px]">
          <h1 className="font-dm-sans text-[48px] font-medium leading-[48px] tracking-[-2%] text-neutral-900 lg:text-left lg:text-[100px] lg:font-medium lg:leading-[100px] lg:tracking-[-3%]">
            How To Fundraise with Giv
          </h1>
          <p className="mt-[16px] text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-[#000000] lg:mt-[56px]">
            Whether it’s to meet a personal need, impact a good cause or
            charity, or meet a financial goal with a group, Giv is the perfect
            way to get financial support with ease and transparency!
          </p>
          <p className="mt-[12px] text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-[#000000]">
            Giv provides a platform where you can fundraise without any startup
            cost - all that is needed is an attention-grabbing title, a
            compelling story, heartwarming/inspiring media, and consistent
            sharing.
          </p>
          <p className="mt-[12px] text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-[#000000]">
            Soon, you’ll have enough donations to reach your goal!
          </p>
          <StartFundraisingButton
            title="Start Using Giv"
            className="mt-[16px] lg:mt-[56px]"
          />
        </div>

        <div className="mt-[42px] flex flex-1 justify-center">
          <img
            src="/assets/images/site-right-display.svg"
            alt="Site Display"
            height="100%"
          />
        </div>
      </div>
      <div className="mt-[56px] flex min-h-[2218px] flex-col items-center bg-[#022928] lg:mt-[100px]">
        <div className="flex min-h-[598px] w-full max-w-[1280px] flex-col p-4 pt-[56px] lg:flex-row lg:pt-[120px]">
          <div className="flex-1">
            <img
              src="/assets/images/preview-fundraiser.svg"
              alt="fundraiser published"
            />
          </div>
          <div className="flex flex-1 flex-col items-start justify-center lg:pl-[96px]">
            <p className="mt-[24px] text-[58px] leading-[64px] tracking-[-2px] text-white">
              Setting Up Your Fundraiser
            </p>
            <p className="mt-[16px] text-left font-inter text-[18px] font-normal leading-[36px] tracking-[-0.25px] text-white lg:mt-[32px]">
              Starting your fundraiser begins with signing up on Giv - it only
              takes a few minutes to come on board. The moment you’ve created
              your account, you will be required to:
            </p>
            <p className="text-lg leading-7 hidden text-left font-inter font-normal tracking-[-0.25px] text-white lg:mt-[32px] lg:flex">
              Starting your fundraiser begins with signing up on Giv - it only
              takes a few minutes to come on board. The moment you’ve created
              your account, you will be required to:
            </p>
            <ul className="ml-[10px] mt-[20px] list-inside list-disc space-y-2 text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-white">
              <li>
                Set up your fundraising target to accommodate the amount you are
                trying to raise and the Giv fees.
              </li>
              <li>
                Share compelling details to communicate why people should donate
                to your campaign.
              </li>
              <li>
                Share pictures or link a video via YouTube to visually support
                your fundraiser.
              </li>
              <li>
                Publish your fundraiser and once the campaign is live, you’re
                ready to share!
              </li>
            </ul>
            <InnerFundRaisingButton
              isTransparent={true}
              title="Setup Your First Campaign"
              className="mt-[40px] w-[326px]"
            />
          </div>
        </div>
        <div className="flex min-h-[598px] w-full max-w-[1280px] flex-col p-4 lg:mx-[80px] lg:mt-[96px] lg:flex-row">
          <div className="order-2 flex flex-1 flex-col lg:order-1">
            <p className="mt-[24px] text-[58px] leading-[64px] tracking-[-2px] text-white">
              Spreading the Word on Your Campaign
            </p>
            <p className="mt-[16px] text-left font-inter text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-white lg:mt-[32px]">
              The success of your fundraiser is heavily dependent on how many
              people can see your campaign, so make sure to share with everyone
              you can reach!
            </p>
            <p className="mt-[12px] text-left font-inter text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-white lg:mt-[32px]">
              You get a special link and QR code, regardless of whether your
              fundraiser is private or public.
            </p>
            <ul className="ml-[10px] list-inside list-disc space-y-2 text-[18px] font-normal leading-[28px] tracking-[-0.25px] text-white lg:mt-[20px]">
              <li>
                Posting on social media, either directly from the website right
                after publishing your campaign or sharing the link anytime
                afterwards.
              </li>
              <li>
                Send emails with the link or QR code attached to your mailing
                contacts.
              </li>
              <li>
                Sending texts and broadcasts to groups you are on, encouraging
                them along with your personal contacts to help spread the word
              </li>
            </ul>
          </div>
          <div className="order-1 mt-[42px] flex-1 items-start justify-center lg:order-2 lg:pl-[96px]">
            <img
              src="/assets/images/fundraiser-published-long.svg"
              alt="fundraiser published"
            />
          </div>
        </div>
        <div className="flex min-h-[598px] w-full max-w-[1280px] flex-col p-4 lg:mx-[80px] lg:mt-[96px] lg:flex-row">
          <div className="flex-1">
            <img
              src="/assets/images/manage-donations.svg"
              alt="fundraiser published"
            />
          </div>
          <div className="mt-[42px] flex flex-1 flex-col items-start lg:pl-[96px]">
            <p className="text-[58px] leading-[64px] tracking-[-2px] text-white">
              Managing Your Donations
            </p>
            <p className="mt-[16px] text-left font-inter text-[18px] font-normal leading-[36px] tracking-[-0.25px] text-white lg:mt-[32px]">
              Once you’ve begun to receive donations, it is important to
              acknowledge those who have pitched in, by saying thanks once you
              get an update. You can leverage our “say thanks” feature to send a
              personalised message to your donors without a hitch!
            </p>
            <p className="mt-[16px] text-left font-inter text-[18px] font-normal leading-[36px] tracking-[-0.25px] text-white lg:mt-[32px]">
              You can post updates on the platforms you shared the campaign on,
              and even do a general appreciation message when you hit certain
              milestones. This helps to strengthen trust and show your
              supporters that you appreciate their efforts.
            </p>

            <InnerFundRaisingButton
              isTransparent={true}
              title="Ready to Fundraise?"
              className="mt-[40px]"
            />
          </div>
        </div>
      </div>
      <FAQ />
      <WebsiteFooter />
    </>
  );
};

export default HowToUsePage;
