export class KroEndpoints {
  static createCampaignEndpoint = "/v1/campaigns";
  static fetchCampaignCategories = "/v1/campaigns/categories";
  static kroFee = "/v1/fee";
  static donationFee = "/v1/donation/fee";
  static fileUpload = "/v1/file-upload/multiple-upload";
  static createIndividualAccount = "/v1/users/create/individual";
  static sendOTP = "/v1/otp/send";
  static signIn = "/v1/users/sign-in";
  static verifyOTP = "/v1/otp/verify";
  static createOgranizationAccount = "/v1/users/create/organisation";
  static getAllCategories = "/v1/users/organisation/categories";
  static fetchCampaigns = "/v1/campaigns";
  // static withdrawalFetchBanks = "/v1/paystack/banks"
  static withdrawalFetchBanks = "https://api.krotrust.com/payment/all-banks";
  static fetchBankAccountInformation =
    "https://api.krotrust.com/payment/verify/bank-details";
  static withdrawCampaignDonations = `/v1/withdrawal/request`;
  static refreshToken = "/v1/users/refresh-token";
  static enquiry = "/v1/enquiry";
  static updateBannerPicture = "/v1/profile/banner-image";
  static updateProfilePicture = "/v1/profile/profile-picture";
  static updateSocialMediaLink = "/v1/profile/socials";
  static userProfile = "/v1/profile";
  static updateNotificationSettings = "/v1/profile/notification";
  static updatePrivacySettings = "/v1/profile/privacy";
  static supportedCurrencies = "/v1/currencies/active";

  static updateCampaignInformation = (id: string) =>
    `/v1/campaigns/${id}/information`;

  static fileUploadCampaign = (id: string) =>
    `/v1/campaigns/${id}/update-image-url`;

  static saveYoutubeLink = (id: string) => `/v1/campaigns/${id}/video`;

  static initializeDonation = (id: string) => `/v1/donation/initialize/${id}`;

  static verifyDonation = (reference: string) =>
    `/v1/donation/verify/${reference}`;

  static updateCampaignTargetAmount = (id: string) =>
    `/v1/campaigns/${id}/funding`;

  static updateCampaignDuration = (id: string) =>
    `/v1/campaigns/${id}/duration`;

  static publishCampaign = (id: string) => `/v1/campaigns/${id}/publish`;

  static saveOrganizationStepOne = (id: string) =>
    `/v1/users/${id}/organisation/set-up`;

  static saveOrganizationStepTwo = (id: string) =>
    `/v1/users/${id}/organisation/registration`;

  static endCampaign = (id: string) => `/v1/campaigns/${id}/end`;

  static createComment = (id: string) => `/v1/comments/${id}`;

  static fetchComments = (id: string, page: number, limit: number) =>
    `/v1/comments/${id}?page=${page}&limit=${limit}`;

  static fetchUserCampaigns = (slug: string) =>
    `/v1/campaigns/user/${slug}/campaigns`;

  static fetchCampaign = (id: string) => `/v1/campaigns/${id}`;

  static changeCampaignVisibility = (id: string) =>
    `/v1/campaigns/${id}/visibility`;

  static fetchActivityLog = (id: string) => `/v1/campaigns/${id}/activities`;

  static sayThanks = (id: number) => `/v1/donation/thank-you-message/${id}`;

  static profile = (slug: string) => `/v1/profile/${slug}`;
}
