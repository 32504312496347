import { KroCategoryBadge } from "features/components/display/kroBadges";
import { useEffect, useState } from "react";
import { KroButton } from "features/components/buttons/kroButton";
import TabBarScrollTo from "features/components/tab/kroScrollToTabBar";
import MediaPreview from "../components/campaignDetails/campaignMediaPreview";
import FundraiserInfo from "../components/campaignDetails/campaignFundraiserInfo";
import ShareFavoriteButtons from "../components/campaignDetails/campaignShareFavoriteButtons";
import { AboutUsComponent } from "../components/campaignDetails/campaignDetailsAbout";
import useFetchCampaign from "../hooks/useFetchCampaign";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "common/utils/currencyFormater";
import useKroCountdown from "features/components/countdown/kroCountdown";
import CampaignUpdateCard from "../components/campaignDetails/campaignUpdateCard";
import KroLoadingIndicator, { LoaderType } from "features/components/loader/kroCircularIndicator";
import KroMetaDecorator from "features/components/utils/kroMetaDecorator";
import { Comments } from "../components/campaignDetails/commentsComponent";
import { useFetchComments } from "../hooks/useFetchComments";
import OnDonateModal from "../components/campaignDetails/donationModals";
import KroShareModal from "features/components/modals/kroShareModal";
import Routes from "router/routes";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { useShareUrlState } from "features/campaign/presentation/hooks/useShareUrlState";

export const CampaignDetailsPage = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const featureName = "donation_flow";
  const { treatments, isReady } = useSplitTreatments({ names: [featureName] });
  const treatment = treatments[featureName].treatment;

  const { data: campaign, isLoading } = useFetchCampaign(campaignId || slug);

  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

  const [page] = useState(1);

  const { data } = useFetchComments(campaignId || slug, page, 10);

  const { isFromShare, shouldShowShare, setShouldShowShare } =
    useShareUrlState();
  const [showCampaignDetailsShareModal, setShowCampaignDetailsShareModal] =
    useState<boolean>(false);

  const handleCampaignDetailsShareModalOpen = () => {
    setShowCampaignDetailsShareModal(true);
  };

  const handleCloseVerificationModal = () => {
    setIsDonateModalOpen(false);
  };

  const handleOpenDonateModal = () => {
    setIsDonateModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShowCampaignDetailsShareModal(false);
    setShouldShowShare(false);
    if (isFromShare) {
      const newUrl = `${location.pathname}`;
      window.history.replaceState({}, "", newUrl);
    }
  };

  const countdown = useKroCountdown(campaign?.endDate);

  const tabs = [
    { name: "about", label: "About", targetId: "about-section" },
    { name: "donors", label: "Donors", targetId: "donors-section" },
    {
      name: "comments",
      label: "Comments",
      targetId: "comments-section",
      badge: data?.comments?.length
    }
  ];

  useEffect(() => {
    if (shouldShowShare) {
      setShowCampaignDetailsShareModal(true);
    }
  }, [shouldShowShare]);

  useEffect(() => {
    if (campaignId === null || campaignId === "" || slug === null || slug === "") {
      navigate("/");
    }
  }, [campaignId, slug, navigate]);

  if (isLoading || !isReady || !campaign) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }

  const media: { type: "image" | "video" | undefined; src: string }[] = [
    ...(campaign?.videoUrl
      ? [{ type: "video" as const, src: campaign?.videoUrl ?? "" }]
      : []),
    ...(campaign?.imageUrls?.map((image) => ({
      type: "image" as const,
      src: image
    })) || [])
  ];

  return (
    <>
      <KroMetaDecorator
        description={campaign?.story ?? ""}
        title={`Give to ${campaign?.title ?? ""} ${campaign?.creatorName ?? ""}`}
        imageUrl={campaign?.imageUrls?.[0] ?? ""}
        imageAlt={"Campaign Image"}
      />

      <OnDonateModal
        campaign={campaign}
        campaignId={campaignId ?? ""}
        isOpen={isDonateModalOpen}
        handlePaystackOpen={() => setIsDonateModalOpen(true)}
        onClose={handleCloseVerificationModal}
      />

      <KroShareModal
        campaignId={campaign?.slug === null ? campaign?.id : campaign?.slug}
        isOpen={showCampaignDetailsShareModal}
        onClose={handleCloseShareModal}
      />

      <div className="mx-[20px] mt-9 max-w-[1280px] lg:mx-auto">
        <div
          className="mb-[42px] flex w-full flex-col gap-[24px] rounded-3xl border border-neutral-200 pb-3 lg:flex-row">
          <div className="w-full pl-3 pr-3 pt-3">
            <MediaPreview
              media={media}
              campaignCategory={campaign?.category ?? ""}
            />
          </div>
          <div className="w-full self-start pl-3 pr-3 pt-3">
            <div className="mb-6">
              <h3
                className="mb-3 text-left font-inter text-[40px] font-semibold leading-[50px] tracking-[-0.005em] text-neutral-900">
                {campaign?.title}
              </h3>
              <div className="flex gap-2">
                <KroCategoryBadge category={campaign?.category ?? ""} />
                {/* <KroVerifiedBadge /> */}
              </div>
            </div>

            <div className="flex flex-col">
              <FundraiserInfo
                key={campaignId ?? ""}
                campaign={campaign}
                avatarUrl={campaign?.creatorAvatar ?? ""}
                fundraiserText={campaign?.creatorName ?? ""}
                amountRaised={formatCurrency(campaign?.amountRaised ?? "")}
                goalAmount={formatCurrency(campaign?.targetAmount ?? "")}
                daysLeft={
                  campaign?.status === "ended" ? "Campaign Ended" : countdown
                }
                numberOfDonors={campaign?.donations?.length ?? 0}
                isVerified={false}
              ></FundraiserInfo>

              <KroButton
                shadow=""
                isDisabled={campaign?.status === "ended"}
                title="Donate Now"
                onclick={() => {
                  if (treatment === "on") {
                    navigate(Routes.ENTER_AMOUNT(campaignId ?? slug ?? ""));
                  } else {
                    handleOpenDonateModal();
                  }
                }}
                // onclick={() => )}
                // onclick={handleOpenVerificationModal}
              />

              <ShareFavoriteButtons
                onShareClick={handleCampaignDetailsShareModalOpen}
                onFavoriteClick={function(): void {
                  // throw new Error("Function not implemented.");
                }}
              />
            </div>
          </div>
        </div>
        <TabBarScrollTo tabs={tabs} />

        <div className="flex flex-col gap-12 pt-9 lg:flex-row lg:items-start">
          <div className="max-w-[773px] flex-1">
            <div id="about-section" className="">
              <AboutUsComponent about={campaign?.story ?? ""} />
            </div>
            <ul id="comments-section" className="mt-4">
              <span className="text-body-lg font-bold text-black">
                Comments
              </span>

              {data?.comments && data.comments.length > 0 ? (
                data.comments.map((comment, index) => (
                  <Comments {...comment} key={index} />
                ))
              ) : (
                <p className="mt-4 text-center text-body-lg text-sub-600">
                  No Comments yet
                </p>
              )}
            </ul>
          </div>
          <div className="flex-1">
            <CampaignUpdateCard
              donors={campaign?.donations ?? undefined}
            ></CampaignUpdateCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignDetailsPage;
