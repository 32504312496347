import { CardsModel } from "../../data/models/cardsModel";

export const WhyGivCard = ({
  props: { title, description, image },
}: {
  props: CardsModel;
}) => {
  return (
    <div className="flex flex-col rounded-[32px] border border-[#D2D2D2] px-[24px] py-[20px]">
      <img src={image} alt={image} height="58px" width="58px" />
      <p className="mt-[25px] font-inter text-[30px] font-medium leading-[37.5px] tracking-[-0.2] text-[#131313]">
        {title}
      </p>
      <p className="text-[#5A5A59 mt-[16px] font-inter text-[16px] font-normal">
        {description}
      </p>
    </div>
  );
};
