import useAuthStore from "common/store/useAuthStore";
import axios from "axios";
import Routes from "router/routes";
import { JwtToken } from "@/features/onboarding/domain/models/signInResponse";
import { KroEndpoints } from "../endpoints";


const redirectToLogin = () => {
  const authStore = useAuthStore.getState();
      authStore.signOut();
      const redirectUrl = encodeURIComponent(
        window.location.pathname + window.location.search,
      );
      window.location.href = `${Routes.LOGIN}?${Routes.REDIRECT_PARAM}=${redirectUrl}`;
}

const refreshTokenClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
timeout: 5000,
});

refreshTokenClient.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token.refreshToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

refreshTokenClient.interceptors.response.use(
  (response) => {
    const token = response.data.data as JwtToken;
    const authStore = useAuthStore.getState();
    authStore.refreshToken(token);
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  },
);

const KroClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});

KroClient.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token.accessToken}`;
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

KroClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const handleHttpError = (status: number, data: any) => {
      const errorMessages: { [key: number]: string } = {
      400: data.message || "Bad Request",
      404: "Resource not found.",
      500: "Internal Server Error. Please try again later.",

      };

      if (status === 401) {
      redirectToLogin();
      return;
      }
      throw new Error(errorMessages[status] || data.message || "An error occurred. Please try again.");
    };

    if (error.response) {
      const { status, config, data } = error.response;

      if (status === 401) {
      try {
        const authStore = useAuthStore.getState();

        const refreshToken = authStore.token?.refreshToken;
        
        if (!refreshToken) {
        redirectToLogin();
        return Promise.reject(error);
        }

        const response = await refreshTokenClient.get(KroEndpoints.refreshToken, {
        headers: {
          "Authorization": `Bearer ${refreshToken}`
        }
        });

        if (response.data?.data) {
        config.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
        return axios(config);
        }
      } catch (refreshError) {
        handleHttpError(status, data);
      }
      }

      handleHttpError(status, data);
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error(error.message || "An unknown error occurred.");
    }
  },
);

export default KroClient;