import { useEffect, useState } from "react";
import { IRatesResponse } from "@/features/campaign/domain/models/ratesResponse";

export default function useRateConverter({ baseCurrency }: { baseCurrency: string }) {
  const [isLoadingRateConverter, setLoadingRateConverter] = useState(false);
  const [errorLoadingRateConverter, setErrorLoadingRateConverter] = useState<Error | null>(null);
  const [ratesData, setRatesData] = useState<IRatesResponse | null>(null);

  useEffect(() => {
    if (!baseCurrency) return;

    setLoadingRateConverter(true);
    fetch(`${process.env.REACT_APP_RATE_API_BASE_URL}/latest/?api_key=${process.env.REACT_APP_RATE_API_KEY}&base=${baseCurrency}&currencies=NGN`)
      .then(res => {
        if (!res.ok) {
          throw new Error("Failed to fetch conversion rates");
        }
        return res.json();
      })
      .then(data => {
        setRatesData(data);
        setLoadingRateConverter(false);
      })
      .catch(err => {
        setErrorLoadingRateConverter(err);
        setLoadingRateConverter(false);
        console.log(err);
      });
  }, [baseCurrency]);

  return { isLoadingRateConverter, errorLoadingRateConverter, ratesData };
}