import { toast } from "react-toastify";
import { getSessionUser } from "common/store/useAuthStore";
import CampaignTile from "./campaignTile";
import useFetchUserCampaigns from "../../hooks/useFetchUserCampaigns";

const CurrentAndClosedCampaignComponent = ({
  isLoading,
  slug,
}: {
  isLoading?: boolean;
  slug?: string;
}) => {
  const user = getSessionUser();

  const {
    isLoading: isLoadingUsersCampaigns,
    campaigns: usersCampaigns,
    refetch: refetchUsersCampaigns,
    error: errorLoadingCampaigns,
  } = useFetchUserCampaigns(slug ?? "");

  if (errorLoadingCampaigns)
    toast.error(
      ({ closeToast }) => (
        <div className="flex items-center justify-between gap-2">
          <span>{errorLoadingCampaigns.message}</span>
          <span
            onClick={() => {
              refetchUsersCampaigns();
              closeToast();
            }}
            className="text-sm cursor-pointer text-black underline hover:text-gray-700"
          >
            Reload
          </span>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false,
      },
    );

  return (
    <>
      <div className="mx-[24px] mt-[40px] w-[344px] items-center justify-center lg:w-[560px]">
        <Header title="CURRENT CAMPAIGN" isLoading={isLoading} />
        <div className="mt-[10px] border-t bg-[#E1E4EA]" />
        <CampaignTile
          isClosedCampaign={false}
          isCurrentUser={slug === user?.slug}
          campaigns={usersCampaigns}
          isLoading={isLoadingUsersCampaigns}
        />
        <Header
          title="CLOSED CAMPAIGN"
          className="mt-[48px]"
          isLoading={isLoading}
        />
        <div className="mt-[10px] border-t bg-[#f2f6fd]" />
        <CampaignTile
          isClosedCampaign={true}
          isCurrentUser={slug === user?.slug}
          campaigns={usersCampaigns}
          isLoading={isLoadingUsersCampaigns}
        />
      </div>
      <div></div>
    </>
  );
};

const Header = ({
  title,
  className,
  isLoading,
}: {
  title: string;
  className?: string;
  isLoading?: boolean;
}) => {
  return (
    <p
      className={`${isLoading && "animate-pulse"} ${isLoading && "bg-gray-300"} ${isLoading && "rounded-md"} ${isLoading && "h-[10px]"} ${isLoading && "w-[100px]"} font-dm-sans text-[12px] font-semibold leading-[12px] tracking-[2%] ${className}`}
    >
      {!isLoading && title}
    </p>
  );
};

export default CurrentAndClosedCampaignComponent;
