interface RawProfileModel {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organisation_name?: string;
  category?: string;
  country?: string;
  state?: string;
  slug?: string;
  admin_first_name?: string;
  admin_last_name?: string;
  admin_phone_number?: string;
  is_organisation_registered?: boolean;
  registration_number?: string;
  proof_of_registration?: string;
  user_type?: string;
  bio?: string | null;
  website?: string | null;
  profile_picture?: string | null;
  banner_picture?: string | null;
  phone_number?: string | null;
  socials?: {
    facebook?: string | null;
    tiktok?: string | null;
    linkedin?: string | null;
    instagram?: string | null;
    x?: string | null;
  };
  notification_settings?: {
    opt_in_news_updates?: boolean;
    opt_in_thank_you_emails?: boolean;
    opt_in_campaign_milestone_updates?: boolean;
  };
  country_code?: string;
  privacy_settings?: {
    make_campaigns_private?: boolean;
    keep_profile_private?: boolean;
    donate_anonymously?: boolean;
  };
}

export interface ProfileModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationName?: string;
  category?: string;
  country?: string;
  state?: string;
  slug?: string;
  countryCode?: string;
  adminFirstName?: string;
  adminLastName?: string;
  adminPhoneNumber?: string;
  isOrganisationRegistered?: boolean;
  registrationNumber?: string;
  proofOfRegistration?: string;
  userType?: string;
  bio?: string | null;
  profilePicture?: string | null;
  bannerPicture?: string | null;
  phoneNumber?: string | null;
  website?: string | null;
  socials?: {
    facebook?: string | null;
    tiktok?: string | null;
    linkedin?: string | null;
    instagram?: string | null;
    x?: string | null;
  };

  notificationSettings?: {
    optInNewsUpdates?: boolean;
    optInThankYouEmails?: boolean;
    optInCampaignMilestoneUpdates?: boolean;
  };

  privacySettings?: {
    makeCampaignsPrivate?: boolean;
    keepProfilePrivate?: boolean;
    donateAnonymously?: boolean;
  };
}

export function mapProfile(data: RawProfileModel): ProfileModel {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    organisationName: data.organisation_name,
    category: data.category,
    country: data.country,
    state: data.state,
    slug: data.slug,
    adminFirstName: data.admin_first_name,
    adminLastName: data.admin_last_name,
    website: data.website,
    adminPhoneNumber: data.admin_phone_number,
    isOrganisationRegistered: data.is_organisation_registered,
    registrationNumber: data.registration_number,
    proofOfRegistration: data.proof_of_registration,
    userType: data.user_type,
    bio: data.bio,
    profilePicture: data.profile_picture,
    bannerPicture: data.banner_picture,
    phoneNumber: data.phone_number,
    countryCode: data.country_code,
    socials: {
      facebook: data.socials?.facebook,
      tiktok: data.socials?.tiktok,
      linkedin: data.socials?.linkedin,
      instagram: data.socials?.instagram,
      x: data.socials?.x,
    },
    notificationSettings: {
      optInNewsUpdates: data.notification_settings?.opt_in_news_updates,
      optInThankYouEmails: data.notification_settings?.opt_in_thank_you_emails,
      optInCampaignMilestoneUpdates:
        data.notification_settings?.opt_in_campaign_milestone_updates,
    },
    privacySettings: {
      makeCampaignsPrivate: data.privacy_settings?.make_campaigns_private,
      keepProfilePrivate: data.privacy_settings?.keep_profile_private,
      donateAnonymously: data.privacy_settings?.donate_anonymously,
    },
  };
}
