import TabBar, { createTabController } from "features/components/tab/kroTabBar";
import { SettingsTabs } from "../../domain/enums/settingsTabs.enum";
import KroLoadingIndicator, {
  LoaderType,
} from "features/components/loader/kroCircularIndicator";
import EditProfileTab from "../components/settings/editProfileTab";
import NotificationsTab from "../components/settings/notificationsTab";
import LoginAndSecurityTab from "../components/settings/loginAndSecurityTab";
import useAuthStore from "common/store/useAuthStore";
import useUserProfile from "../hooks/useUserProfile";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { UserModel } from "common/models/userModel";

const SettingsPage = () => {
  const tabController = createTabController();
  const { user, updateUser } = useAuthStore();
  const { profile, refetch, error: errorLoadingProfile } = useUserProfile();

  const [userProfile, setUserProfile] = useState(user);

  useEffect(() => {
    if (profile) {
      updateUser(profile as UserModel);

      setUserProfile(profile as UserModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (errorLoadingProfile) {
    toast.error(
      ({ closeToast }) => (
        <div className="flex items-center justify-between gap-2">
          <span>{errorLoadingProfile.message}</span>
          <span
            onClick={() => {
              refetch();
              closeToast();
            }}
            className="text-sm cursor-pointer text-black underline hover:text-gray-700"
          >
            Reload
          </span>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false,
      },
    );
  }

  return (
    <>
      <div className="mx-[24px] mt-[40px] max-w-[764px] justify-center lg:mx-[338px] lg:mt-[77px]">
        <p className="mb-[54px] font-dm-sans text-[32px] font-semibold leading-[38.4px] tracking-[-0.5%] lg:mb-[43px]">
          Settings
        </p>
        <TabBar
          tabController={tabController}
          tabs={[
            {
              name: SettingsTabs.profile,
              label: "Profile",
              component: !userProfile ? (
                <KroLoadingIndicator type={LoaderType.FIXED} />
              ) : (
                <EditProfileTab
                  tabController={tabController}
                  userProfile={userProfile}
                />
              ),
            },
            {
              name: SettingsTabs.notifications,
              label: "Notifications",
              component: !userProfile ? (
                <KroLoadingIndicator type={LoaderType.FIXED} />
              ) : (
                <NotificationsTab
                  tabController={tabController}
                  userProfile={userProfile}
                />
              ),
            },
            {
              name: SettingsTabs.loginAndSecurity,
              label: "Login & Security",
              component: !userProfile ? (
                <KroLoadingIndicator type={LoaderType.FIXED} />
              ) : (
                <LoginAndSecurityTab
                  tabController={tabController}
                  userProfile={userProfile}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default SettingsPage;
