import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import FAQ from "../components/faqComponent";
import { InnerFundRaisingButton } from "../components/innerFundraisingButton";
import { useState } from "react";
import { FieldApi, useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import LocationCircleWithTooltip from "../components/locationCircleWithToolTip";
import { EnquiryPayload } from "features/dashboard/domain/model/enquiryPayload";
import useMakeEnquiry from "features/dashboard/presentation/hooks/useMakeEnquiry";
import { toast } from "react-toastify";
import { EnquiryTypes } from "features/dashboard/domain/enums/enquiryTypes.enum";
import WebsiteFooter from "common/layout/websiteFooter";
// import KroMapTooltip from "../components/kroMapToolTip";

const ContactUsPage = () => {
  const makeEnquiry = useMakeEnquiry(
    (error, _, __) => {
      console.log(error.stack);
      toast.error(error.message);
    },
    () => {
      toast.success("Enquiry Submitted Successfully");
      form.reset();
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPill, setSelectedPill] = useState<string>("");

  const form = useForm<EnquiryPayload>({
    defaultValues: {
      fullName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      enquiryType: "",
      tellUsMore: "",
    },
    onSubmit: (data) => {
      console.log(data);
      makeEnquiry.mutate({
        fullName: data.value.fullName,
        companyName: data.value.companyName,
        email: data.value.email,
        phoneNumber: data.value.phoneNumber,
        enquiryType: data.value.enquiryType,
        tellUsMore: data.value.tellUsMore,
      });
    },
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // form.handleSubmit();
        }}
      >
        <div className="mx-[20px] flex max-w-[1440px] flex-col lg:mx-[80px] lg:flex-row">
          <div className="mt-[56px] flex-1 lg:mt-[80px]">
            <h1 className="font-dm-sans text-[36px] font-medium leading-[36px] tracking-[-3%] text-[#131313] lg:text-left lg:text-[72px] lg:font-medium lg:leading-[72px]">
              Need to Reach Us?
            </h1>
            <p className="mt-[16px] font-normal leading-[28px] tracking-[-0.25px] text-[#000000] lg:mt-[24px]">
              Do you have questions, need assistance or would like to share a
              vision for a partnership? You’re in the right place.
            </p>
            <img
              src="/assets/images/customer-support.svg"
              alt="customer support"
              className="flex lg:mt-[56px]"
              height="100%"
              // width="100%"
            />
          </div>
          <div className="flex flex-1 flex-col lg:ml-[47px] lg:mt-[157.35px]">
            <div className="flex flex-col gap-[42px] lg:flex-row">
              <form.Field
                validators={{
                  onChange: ({ value }) => Validation.required(value),
                }}
                name="fullName"
                children={(field) => (
                  <KroTextField
                    id={field.name}
                    name={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    label="Full name"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeHolder="Enter name here"
                    type={KroInputFieldType.TEXT}
                    className="flex-1 rounded-none border-0 border-b border-gray-300 focus:border-black focus:ring-0"
                  />
                )}
              />
              <form.Field
                name="companyName"
                validators={
                  {
                    // onChange: ({ value }) => Validation.required(value),
                  }
                }
                children={(field) => (
                  <KroTextField
                    id={field.name}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    name={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    label="Company name (if any)"
                    placeHolder="Company Name"
                    type={KroInputFieldType.TEXT}
                    className="flex-1 rounded-none border-0 border-b border-gray-300 focus:border-black focus:ring-0"
                  />
                )}
              />
            </div>
            <div className="mt-[42px] flex flex-col gap-[42px] lg:flex-row">
              <form.Field
                validators={{
                  onChange: ({ value }) => Validation.required(value),
                }}
                name="email"
                children={(field) => (
                  <KroTextField
                    id={field.name}
                    onChange={(e) => field.handleChange(e.target.value)}
                    name={field.name}
                    errorMessage={field.state.meta.errors.join(", ")}
                    label="Your email"
                    value={field.state.value}
                    placeHolder="name@email.com"
                    type={KroInputFieldType.TEXT}
                    className="flex-1 rounded-none border-0 border-b border-gray-300 focus:border-black focus:ring-0"
                  />
                )}
              />
              <form.Field
                validators={{
                  onChange: ({ value }) => Validation.required(value),
                }}
                name="phoneNumber"
                children={(field) => (
                  <KroTextField
                    id={field.name}
                    onChange={(e) => field.handleChange(e.target.value)}
                    name={field.name}
                    value={field.state.value}
                    errorMessage={field.state.meta.errors.join(", ")}
                    label="Your Phone number"
                    placeHolder="+234 xxx xxx xxxx"
                    type={KroInputFieldType.TEXT}
                    className="flex-1 rounded-none border-0 border-b border-gray-300 focus:border-black focus:ring-0"
                  />
                )}
              />
            </div>
            <form.Field
              name="enquiryType"
              children={(field) => (
                <div className="mt-[42px] flex flex-col">
                  <p className="text-md font-medium-regular mb-[20px] block text-left font-inter leading-[22px] tracking-[-0.005em]">
                    Enquiry
                  </p>
                  <div
                    id={field.name}
                    className="flex flex-wrap gap-[8px] gap-y-[20px]"
                  >
                    {Object.values(EnquiryTypes).map((enquiry, index) => (
                      <EnquiryPill
                        key={enquiry}
                        title={enquiry}
                        isSelected={field.state.value === enquiry}
                        onSelect={setSelectedPill}
                        field={field}
                      />
                    ))}
                  </div>
                </div>
              )}
            />
            <form.Field
              name="tellUsMore"
              validators={{
                onChange: ({ value }) => Validation.required(value),
              }}
              children={(field) => (
                <KroTextField
                  id={field.name}
                  value={field.state.value}
                  onTextAreaChange={(e) => field.handleChange(e.target.value)}
                  name={field.name}
                  errorMessage={field.state.meta.errors.join(", ")}
                  placeHolder="Something about your fundraiser..."
                  label="Tell us more"
                  className="mt-[42px]"
                  type={KroInputFieldType.TEXTAREA}
                />
              )}
            />
            <InnerFundRaisingButton
              isTransparent={false}
              title="Submit"
              isLoading={makeEnquiry.isPending}
              onClick={form.handleSubmit}
              className="w-[160px]"
            />
          </div>
        </div>
        <img
          src="/assets/images/contact-us-bg.svg"
          alt="contact-us-bg"
          className="z-0 mt-[56px] hidden w-full lg:mt-[120px]"
        />
        <div className="z-0 mt-[20px] flex max-h-[1414px] w-full flex-col bg-[#022928] pb-[30px] text-white">
          <FAQ iconClassName="invert filter" answerClassName="invert filter" />
        </div>
        <div className="z-999 absolute left-1/2 -mt-[1550px] hidden h-[175.23px] w-full max-w-[349.38px] -translate-x-1/2 flex-col items-center justify-center rounded-[42px] bg-[#FFFEFD] shadow-lg lg:-mt-[1800px] lg:h-[647px] lg:max-w-[1290px] lg:px-[75px]">
          <LocationCircleWithTooltip />

          <div className="absolute bottom-6 right-6 flex flex-col gap-[6px]">
            <button className="flex h-[18.42px] w-[18.42px] items-center justify-center rounded-full border-[2px] border-[#022928] lg:h-[68px] lg:w-[68px]">
              <img
                src="/assets/icons/plus.svg"
                alt="plus icon"
                className="h-[8px] w-[8px] lg:h-[24px] lg:w-[24px]"
              />
            </button>
            <button className="flex h-[18.42px] w-[18.42px] items-center justify-center rounded-full bg-[#FFF3ED] lg:h-[68px] lg:w-[68px]">
              <img
                src="/assets/icons/minus.svg"
                alt="minus icon"
                className="h-[8px] w-[8px] lg:h-[24px] lg:w-[24px]"
              />
            </button>
          </div>
        </div>
      </form>
      <WebsiteFooter />
    </>
  );
};

const EnquiryPill = ({
  title,
  isSelected,
  onSelect,
  field,
}: {
  title: string;
  isSelected: boolean;
  onSelect: (title: string) => void;
  field: FieldApi<any, any>;
}) => {
  return (
    <button
      type="button"
      className={`flex h-[45.83px] min-w-[86.74px] flex-row items-center justify-center rounded-[60px] ${isSelected ? "border-[2px]" : "border"} ${isSelected ? "border-[#022928]" : "border-[#D2D2D2]"} px-[23.87px] py-[15.91px]`}
      onClick={() => {
        onSelect(title);
        field.handleChange(title);
      }}
    >
      <p className="text-[14px] font-medium leading-[14px] tracking-[0%]">
        {title}
      </p>
      {isSelected && (
        <div className="ml-[7.96px] h-[8px] w-[8px] rounded bg-[#131313]" />
      )}
    </button>
  );
};
export default ContactUsPage;
