import { useForm } from "@tanstack/react-form";
import KroConstants from "common/constants/kroConstants";
import Validation from "common/utils/validation";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { CampaignVisibility } from "features/campaign/domain/models/updateCampaignInformationPayload";
import { useFetchCampaignCategories } from "features/campaign/presentation/hooks/useFetchCampaignCategories";
import { useUpdateCampaignInformation } from "features/campaign/presentation/hooks/useUpdateCampaignInformation";
import { KroButton } from "features/components/buttons/kroButton";
import KroDropdown from "features/components/dropdowns/kroDropdown";
import KroRadioButton from "features/components/inputs/kroRadioButton";
import KroTextField from "features/components/inputs/kroTextField";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import { TabController } from "features/components/tab/kroTabBar";
import { FC } from "react";
import useFormHandler from "../../hooks/useFormHandler";
import { queryClient } from "index";
import { toast } from "react-toastify";
import { getSessionUser } from "common/store/useAuthStore";

interface EditFundraiserDetailsForm {
  title: string;
  category: string;
  story: string;
  visibility: CampaignVisibility;
}

const FundingDetailsTab: FC<{
  campaign: CampaignModelMapper;
  tabController: TabController;
}> = ({ campaign, tabController }) => {
  // const { showSnackbar } = useSnackbar();

  const user = getSessionUser();

  const {
    categories,
    isLoading: isFetchingCategories,
    error: errorFetchCategories
  } = useFetchCampaignCategories();

  const updateCampaignMutation = useUpdateCampaignInformation(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: boolean) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaign?.id]
      });
      toast.success("Campaign updated successfully");
    }
  );

  const form = useForm<EditFundraiserDetailsForm>({
    defaultValues: {
      visibility:
        campaign?.visibility ??
        ((user?.privacySettings?.makeCampaignsPrivate ?? false)
          ? "private"
          : "public"),
      title: campaign?.title ?? "",
      story: campaign?.story ?? "",
      category: campaign?.category ?? ""
    },
    onSubmit: async (values) => {
      if (campaign?.status === "ended") {
        toast.warning("Campaign cannot be updated as it has ended");
        form.reset();
        return;
      }

      updateCampaignMutation.mutate({
        visibility: values.value.visibility,
        id: campaign?.id ?? "",
        beneficiaryName: campaign?.nameOfBeneficiary ?? "",
        title: values.value.title,
        category: values.value.category,
        story: values.value.story
      });
    }
  });
  useFormHandler(form, tabController);

  if (errorFetchCategories) {
    return (
      <KroLoadingIndicator
        error={errorFetchCategories?.message}
        onRetry={() => window.location.reload()}
      />
    );
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className="mt-9 max-w-[500px] justify-center"
    >
      <form.Field
        name="title"
        validators={{
          onChange: ({ value }) => Validation.required(value)
        }}
        children={(field) => {
          return (
            <KroTextField
              id={field.name}
              name={field.name}
              errorMessage={field.state.meta.errors.join(", ")}
              value={field.state.value ?? ""}
              type={KroInputFieldType.TEXT}
              onChange={(e) => field.handleChange(e.target.value)}
              label="Give your fundraiser a title"
              placeHolder="Donate to help..."
            />
          );
        }}
      />
      <form.Field
        name="category"
        validators={{
          onChange: ({ value }) => Validation.required(value)
        }}
        children={(field) => {
          return (
            <KroDropdown
              className="mb-6"
              id={field.name}
              errorMessage={field.state.meta.errors.join(", ")}
              options={
                !isFetchingCategories
                  ? (categories?.map((category) => category.name) ?? [])
                  : []
              }
              label="What type of fundraiser is it?"
              onDropdownItemClicked={(option) => field.handleChange(option)}
              placeHolder="Select fundraiser type"
              selectedValue={field.state.value ?? ""}
            />
          );
        }}
      />
      <form.Field
        name="story"
        validators={{
          onChange: ({ value }) => {
            const minLengthValidation = Validation.minLength(
              value,
              KroConstants.STORY_MAX_COUNT
            );
            if (minLengthValidation !== undefined) return minLengthValidation;
            return Validation.noGibberish(value);
          }
        }}
        children={(field) => {
          return (
            <KroTextField
              label="Tell Your Story"
              id="story"
              errorMessage={field.state.meta.errors.join(", ")}
              type={KroInputFieldType.TEXTAREA}
              placeHolder="Type here..."
              value={field.state.value ?? ""}
              showCount={true}
              maxCharacters={KroConstants.STORY_MAX_COUNT}
              ckEditorChange={(e) => {
                field.handleChange(e ?? "");
              }}
              useRichText={true}
            />
          );
        }}
      />
      <form.Field
        name="visibility"
        children={(field) => (
          <KroRadioButton
            tooltip="Private campaigns are only visible to those with the link"
            id={field.name}
            labelClassName="mt-[36px]"
            onToggle={(value) => {
              form.setFieldValue(field.name, value ? "private" : "public");
            }}
            value=""
            name={"visible"}
            isChecked={field.state.value === "private"}
            label={"Allow my fundraiser to be private"}
          />
        )}
      ></form.Field>
      <div className="mt-[84px] flex gap-4">
        <button
          type="reset"
          onClick={() => form.reset()}
          className="max-w-[120px] rounded-[32px] border border-neutral-200 bg-neutral-50 px-[29px] py-[14px] text-center font-inter text-[16px] font-medium leading-[24px] tracking-[-0.005em] text-[#0F0F0F] hover:bg-neutral-100"
        >
          Discard
        </button>
        <KroButton.Base
          isLoading={updateCampaignMutation.isPending}
          type="submit"
          className="px-[29px] py-[14px]"
          hasIcon={updateCampaignMutation.isPending}
          title="Save Changes"
        />
      </div>
    </form>
  );
};

export default FundingDetailsTab;
