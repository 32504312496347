import React, { useCallback, useEffect, useState } from "react";

interface TestimonialProps {
  author: {
    name: string;
  };
  content: string;
  className?: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
                                                   author,
                                                   content,
                                                   className
                                                 }) => {

  return (
    <div
      className={`max-h-full w-full rounded-[32px] border border-[#5A5A59] p-[32px] ${className}`}
    >
      <p className="text-lg text-white">{content}</p>

      <p className="mt-[56px] font-medium text-white">{author.name}</p>
    </div>
  );
};

const TestimonialGrid: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const testimonials = [
    {
      content:
        "Using the giv platform to collect Group Life insurance subscriptions fees from members of my alumni group, really made life easier for me. In the past, the monies would be transferred into a personal account, making identification and reconciliations quite difficult and time consuming. All I had to do this time was post a giv.ng campaign link on our WhatsApp group, while I sat back and watched the subscription pot grow. It was also useful to see a progress bar on the campaign profile, including the names of the members that had paid and the accompanying amounts. Thank you GIV.",
      author: {
        name: "Damien O"
      }
    },
    {
      content:
        "Giv has made it easier for me to break the ice with fundraising. It was easier to get donors when I could present something that clearly showed what I was raising money for. And I love the status bar! It's a nice feeling to be able to view your progress in real time - that's my favourite feature so far!",
      author: {
        name: "Bolaji"
      }
    },
    {
      content:
        "Partnering with Giv has really helped to enhance our finance for the last edition camping we hosted because some people we did not even know donated.Moreso, sharing the link on our social platform and  with friends and family showcase us better. This platform also helped us to have better financial  planning towards the programme; is like saving up money for a particular targeted thing.No any challenge in partnering with Giv. We just want to recommend/ suggest there should be platform to reach out back to the donors for appreciation. Thanks and God bless the brain and team behind Giv 🌺",
      author: {
        name: "Tunji and Abolade Adeleke, the convener of TREASURE HOUSE FAMILY MINISTRY INTERNATIONAL. "
      }
    }
  ];

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  }, [testimonials.length]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  useEffect(() => {
    if (!isMobile || isPaused) return;

    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [isMobile, isPaused, nextSlide]);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
    setIsPaused(true);

    setTimeout(() => {
      setIsPaused(false);
    }, 10000);
  };

  if (isMobile) {
    return (
      <div className="relative w-full px-4">
        <div className="overflow-hidden">
          <div
            className="mt-[42px] flex transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full flex-none px-2">
                <Testimonial
                  content={testimonial.content}
                  author={testimonial.author}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Dots */}
        <div className="mt-[24px] mb-[56px] flex justify-center gap-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`h-[12px] w-[12px] rounded-full transition-colors duration-200 ${
                currentIndex === index ? "bg-[#4FBA6D]" : "bg-white"
              }`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 lg:mt-16">
      <div className="mx-auto grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            content={testimonial.content}
            author={testimonial.author}
            className={`${index >= 2 && "md:hidden lg:block"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialGrid;
