import KroModal from "features/components/modals/kroModal";
import React from "react";
import ProfileShareComponent from "./profileShareComponent";

interface ProfileShareModalProps {
  profileId: string;
  onClose: () => void;
  isOpen: boolean;
}

console.log(window.location.origin);

const KroProfileShareModal: React.FC<ProfileShareModalProps> = ({
  isOpen,
  profileId,
  onClose,
}) => {
  return (
    <>
      <KroModal
        closeOnOutsideClick={true}
        lottieAnimation={false}
        isOpen={isOpen}
        onClose={onClose}
        className="px-3 py-3"
        showCloseButton={true}
        maxWidth={400}
        maxHeight={288}
      >
        <ProfileShareComponent slug={profileId} />
      </KroModal>
    </>
  );
};

export default KroProfileShareModal;
