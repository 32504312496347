import kroFee from "features/campaign/domain/models/kroFee";
import { FC, useEffect, useState } from "react";
import { useKroFeeCalculation } from "../../hooks/useKroFee";
import { fundraiserTargetForm } from "../../forms/fudraiserTargetForm";
import KroAmountInputField from "features/components/inputs/KroAmountInput";
import Validation from "common/utils/validation";
import KroRadioButton from "features/components/inputs/kroRadioButton";
import KroTooltip from "features/components/display/kroToolTip";
import { formatCurrency } from "common/utils/currencyFormater";

const FundraiserTarget: FC<{
  kroFee: kroFee | undefined;
  form: fundraiserTargetForm;
}> = ({ kroFee, form }) => {
  const feePercentage = kroFee?.percentage ?? 0;
  const {
    amountToReceive,
    targetAmount,
    serviceFee,
    handleTargetAmountChange,
    handleAmountToReceiveChange
  } = useKroFeeCalculation(feePercentage);

  const [isNoTarget, setIsNoTarget] = useState<boolean>(false);

  const validateAmountFields = () => {
    form.validateField("targetAmount", "change");
    form.validateField("amountToReceive", "change");
  };

  const handleAmountToRaiseChange = (value: string) => {
    if (!value) {
      form.setFieldValue("targetAmount", "");
      form.setFieldValue("amountToReceive", "");
    }
    const amount = parseFloat(value);
    handleTargetAmountChange(amount);
    validateAmountFields();
  };

  const handleAmountToReceive = (value: string) => {
    if (!value) {
      form.setFieldValue("targetAmount", "");
      form.setFieldValue("amountToReceive", "");
    }
    const amount = parseFloat(value);
    handleAmountToReceiveChange(amount);
    validateAmountFields();
  };

  const handleNoTargetToggle = (checked: boolean) => {
    setIsNoTarget(checked);
    form.state.values.targetAmount = "";
    form.state.values.amountToReceive = "";
    handleTargetAmountChange(0);
  };

  useEffect(() => {
    if (amountToReceive !== "0") {
      form.state.values.amountToReceive = amountToReceive;
    }
  }, [amountToReceive, form.state.values]);

  useEffect(() => {
    if (targetAmount !== "0") {
      form.state.values.targetAmount = targetAmount;
    }
  }, [targetAmount, form.state.values]);

  useEffect(() => {
    setIsNoTarget(form.state.values.hasNoTarget);
    if (form.state.values.targetAmount) {
      handleAmountToRaiseChange(form.state.values.targetAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form.Field
        name="targetAmount"
        validators={{
          onChange: ({ value }) => {
            if (form.state.values.hasNoTarget) return undefined;
            return Validation.amount(value);
          }
        }}
        children={(field) => {
          return (
            <KroAmountInputField
              id={field.name}
              name={field.name}
              errorMessage={field.state.meta.errors.join(", ")}
              title={"Target amount"}
              currency={"₦"}
              className="mt-7"
              value={field.state.value}
              placeHolder="0"
              onChange={(e) => {
                field.handleChange(e);
                return handleAmountToRaiseChange(e);
              }}
              readOnly={isNoTarget}
            />
          );
        }}
      />

      <div className="border-t border-neutral-300" />
      <form.Field
        name="amountToReceive"
        validators={{
          onChange: ({ value }) => {
            if (form.state.values.hasNoTarget) return undefined;
            return Validation.amount(value);
          }
        }}
        children={(field) => {
          return (
            <KroAmountInputField
              id={field.name}
              name={field.name}
              title={"You will receive"}
              currency={"₦"}
              value={field.state.value}
              className="mb-7"
              placeHolder="0"
              errorMessage={field.state.meta.errors?.join(", ")}
              onChange={(e) => {
                field.handleChange(e);
                return handleAmountToReceive(e);
              }}
              readOnly={isNoTarget}
            />
          );
        }}
      />

      <form.Subscribe
        children={(state) => {
          const hasNoTarget = state.values.hasNoTarget;
          return (
            <ServiceFeeComponent
              hasNoTarget={hasNoTarget}
              feePercentage={feePercentage}
              amount={serviceFee}
              currency="NGN"
            />
          );
        }}
      ></form.Subscribe>

      <form.Field
        name="hasNoTarget"
        children={(field) => {
          return (
            <KroRadioButton
              id={field.name}
              name={field.name}
              isChecked={field.state.value}
              value="noTarget"
              label="I don't have a target"
              onToggle={(value) => {
                field.handleChange(value);
                return handleNoTargetToggle(value);
              }}
            />
          );
        }}
      />
    </>
  );
};

export default FundraiserTarget;

const ServiceFeeComponent: FC<{
  feePercentage: number;
  hasNoTarget?: boolean;
  amount: number;
  currency: string;
}> = ({ feePercentage, amount, currency, hasNoTarget }) => {
  return (
    <div className="mb-7 flex items-center justify-between rounded-2xl border border-neutral-200 bg-neutral-50 p-3">
      <div className="flex items-center">
        {/* <img src="/assets/icons/service_icon.svg" alt="service" /> */}
        <span className="tracking-ultralTigh mx-[6px] text-body-md font-medium text-neutral-900">
          Giv Service Fee
        </span>
        <KroTooltip
          title="Giv Service Fee"
          subtitle={`Giv takes a ${feePercentage}% fee to maintain and improve the platform`}
        >
          <img src="/assets/icons/service_info_icon.svg" alt="info" />
        </KroTooltip>
      </div>

      {hasNoTarget ? (
        <span
          className="text-left font-inter text-[14px] font-normal leading-[22px] tracking-[-0.005em] text-neutral-500">
          {feePercentage}% of funds raised
        </span>
      ) : (
        <span className="tracking-ultralTigh text-body-md font-normal text-neutral-500">
          {formatCurrency(amount)} {currency}
        </span>
      )}
    </div>
  );
};