function formatCurrency(
  input: string | number,
  showCurrency: boolean = false,
  currencySymbol: string = "₦"
): string {
  if (input === undefined || input === null) {
    return "0.00";
  }

  if (typeof input === "number") {
    input = input.toString();
  } else {
    input = input.replace(/[^0-9.]/g, "");
  }

  let parts = input.split(".");
  if (parts.length > 2) {
    return input;
  }

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2);
  } else if (showCurrency) {
    parts.push("00");
  }

  return showCurrency ? `${currencySymbol}${parts.join(".")}` : parts.join(".");
}


function removeCommas(input: string): string {
  return input.replace(/,/g, "");
}

export { formatCurrency, removeCommas };
