

export function calculateProvidusAmount(amount: number) {
  const feeConfig = {
    income: {
      max: 500,
      flatPercent: 0.5
    },
    outcome: [
      {
        from: 0,
        to: 5000,
        fee: 10.75
      },
      {
        from: 5001,
        to: 50000,
        fee: 26.9
      },
      {
        from: 50001,
        to: 99999999999,
        fee: 53.75
      }
    ]
  };

  let fee = 0;
  let netAmount = amount;


    for (const tier of feeConfig.outcome) {
      if (amount >= tier.from && amount <= tier.to) {
        fee = tier.fee;
        break;
      }
    }
    // If amount exceeds all tiers, use the last tier's fee
    if (fee === 0) {
      fee = feeConfig.outcome[feeConfig.outcome.length - 1].fee;
    }
  

  netAmount = amount - fee;

  return {
    originalAmount: amount,
    fee: fee,
    netAmount: netAmount,
   
  };
}

// Example usage:
/*
const amount = 2000;
const result = calculateProvidusAmount(amount, TransactionType.INCOME);
console.log(result);
// Output:
// {
//   originalAmount: 2000,
//   fee: 10,          // 0.5% of 2000
//   netAmount: 1990,  // 2000 - 10
//   type: 'INCOME'
// }

const outcomeResult = calculateProvidusAmount(amount, TransactionType.OUTCOME);
console.log(outcomeResult);
// Output:
// {
//   originalAmount: 2000,
//   fee: 10.75,       // First tier fee
//   netAmount: 1989.25, // 2000 - 10.75
//   type: 'OUTCOME'
// }
*/
