import { useQuery } from "@tanstack/react-query";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useFetchUserCampaigns = (slug: string) => {
  const {
    data: campaigns,
    isLoading,
    error,
    refetch,
  } = useQuery<CampaignModelMapper[], Error>({
    queryKey: ["fetchCampaigns", slug],
    queryFn: () => DashboardService.fetchUserCampaigns(slug),
  });

  return { campaigns, isLoading, error, refetch };
};

export default useFetchUserCampaigns;
