export const validateImageFile = (file: File, minWidth: number, minHeight: number): Promise<boolean> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        resolve(img.width >= minWidth && img.height >= minHeight);
      };
    };
  });
};

export const allowedImageTypes = ['image/jpeg', 'image/png'];
export const maxFileSize = 5 * 1024 * 1024; // 5MB
