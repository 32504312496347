import React from "react";

const CategoriesComponent = () => {
  const categories = [
    "Travels",
    "Events",
    "Family",
    "Projects",
    "Medicals",
    "Education",
  ];

  return (
    <div className="mt-[56px] flex h-[78px] items-center bg-[#022928] lg:mt-[147px]">
      <p className="leading-tight ml-[20px] mr-[80px] whitespace-nowrap font-dm-sans text-[20px] tracking-[-0.03em] text-[#4FBA6D] lg:ml-[80px] lg:text-[24px] lg:font-semibold">
        PERFECT FOR
      </p>

      <div className="relative overflow-hidden">
        <div className="flex animate-marquee whitespace-nowrap">
          <div className="flex items-center space-x-20 pr-20">
            {categories.map((category) => (
              <React.Fragment key={`first-${category}`}>
                <span className="text-2xl leading-7 whitespace-nowrap text-left font-inter font-medium text-white">
                  {category}
                </span>
                <img
                  src="/assets/icons/pink-star.svg"
                  alt="star"
                  className="ml-20"
                />
              </React.Fragment>
            ))}
          </div>

          <div className="flex items-center space-x-20 pr-20">
            {categories.map((category) => (
              <React.Fragment key={`second-${category}`}>
                <span className="text-2xl leading-7 whitespace-nowrap text-left font-inter font-medium text-white">
                  {category}
                </span>
                <img
                  src="/assets/icons/pink-star.svg"
                  alt="star"
                  className="ml-20"
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesComponent;
