import KroClient from "common/io/client/kroClient";
import { KroEndpoints } from "common/io/endpoints";
import {
  CampaignModelMapper,
  mapCampaignModel,
} from "features/campaign/domain/models/campaignModel";
import { WithdrawalBanksResponse } from "../model/bankModel";
import {
  BankAccountDetailsResponse,
  BankAccountModel,
  BankAccountPayload,
  mapToBankAccountModel,
} from "../model/bankAccountModel";
import { WithdrawDonationPayload } from "../model/withdrawDonationPayload";
import { SayThanksPayload } from "../model/sayThanksPayload";
import {
  mapSayThanksResponse,
  SayThanksResponseMapper,
} from "../model/sayThanksResonse";
import { EnquiryPayload } from "../model/enquiryPayload";
import { UpdateSocialMediaLinksPayload } from "../model/updateSocialMediaLinksPayload";
import { mapProfile, ProfileModel } from "../model/profileModel";
import { UpdateProfilePayload } from "../model/updateProfilePayload";
import {
  UpdateNotificationSettingsPayload,
  UpdatePrivacySettingsPayload,
} from "../model/updateSettingsPayload";

const DashboardRepository = () => {
  const fetchCampaigns = async (): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchCampaigns);
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };

  const fetchUserCampaigns = async (
    slug: string,
  ): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(
        KroEndpoints.fetchUserCampaigns(slug),
      );
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };
  const fetchWithdrawalBanks = async (): Promise<
    Array<WithdrawalBanksResponse>
  > => {
    try {
      const response = await fetch(KroEndpoints.withdrawalFetchBanks);
      const data = await response.json();
      return data["banks"];
    } catch (error) {
      throw error;
    }
  };

  const fetchBankAccountInformation = async (
    payload: BankAccountPayload,
  ): Promise<BankAccountModel> => {
    const response = await fetch(KroEndpoints.fetchBankAccountInformation, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountNumber: payload.accountNumber,
        accountBank: payload.bankCode,
      }),
    });
    const data = await response.json();

    if (
      Object.keys(data).length === 0 ||
      data["accountName"] === null ||
      data["accountName"] === undefined ||
      data["accountName"] === "" ||
      data["accountName"] === "NA"
    ) {
      throw new Error(
        "Account Details not found, Please check the account number and bank name",
      );
    }
    return mapToBankAccountModel(data as BankAccountDetailsResponse);
  };

  const withdrawCampaignDonation = async (
    payload: WithdrawDonationPayload,
  ): Promise<boolean> => {
    const response = await KroClient.post(
      KroEndpoints.withdrawCampaignDonations,
      payload,
    );
    return response.data;
  };

  const fetchActivityLog = async (campaignId: string): Promise<[]> => {
    try {
      const response = await KroClient.get(
        KroEndpoints.fetchActivityLog(campaignId),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const sayThanks = async (
    payload: SayThanksPayload,
  ): Promise<SayThanksResponseMapper> => {
    try {
      const response = await KroClient.post(
        KroEndpoints.sayThanks(payload.activityId),
        {
          message: payload.message,
        },
      );

      if (!response || !response.data) {
        throw new Error("An Error Occurred");
      }

      console.log(response.data);
      const mappedResponse = mapSayThanksResponse(response.data);
      return mappedResponse;
    } catch (error) {
      throw error;
    }
  };

  const makeEnquiry = async (payload: EnquiryPayload): Promise<{}> => {
    try {
      const response = await KroClient.post(KroEndpoints.enquiry, payload);

      if (!response || !response.data) {
        throw new Error("An Error Occurred");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateSocialMediaLinks = async (
    payload: UpdateSocialMediaLinksPayload,
  ): Promise<{}> => {
    try {
      const cleanPayload = Object.fromEntries(
        Object.entries(payload).filter(
          ([_, value]) => value != null && value !== "",
        ),
      );
      const response = await KroClient.put(
        KroEndpoints.updateSocialMediaLink,
        cleanPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fetchProfile = async (slug: string): Promise<ProfileModel> => {
    try {
      const response = await KroClient.get(KroEndpoints.profile(slug));
      console.log(response);
      const mappedResponse = mapProfile(response.data);
      return mappedResponse;
    } catch (error) {
      throw error;
    }
  };

  const fetchUserProfile = async (): Promise<ProfileModel> => {
    try {
      const response = await KroClient.get(KroEndpoints.userProfile);
      const mappedResponse = mapProfile(response.data);
      return mappedResponse;
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = async (
    payload: UpdateProfilePayload,
  ): Promise<ProfileModel> => {
    try {
      const cleanPayload = Object.fromEntries(
        Object.entries(payload).filter(
          ([_, value]) => value != null && value !== "",
        ),
      );
      const response = await KroClient.put(
        KroEndpoints.userProfile,
        cleanPayload,
      );
      return mapProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const updateBannerPicture = async (file: File): Promise<ProfileModel> => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await KroClient.patch(
        KroEndpoints.updateBannerPicture,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return mapProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const updateProfilePicture = async (file: File): Promise<ProfileModel> => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await KroClient.patch(
        KroEndpoints.updateProfilePicture,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return mapProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const updateNotificationSettings = async (
    payload: UpdateNotificationSettingsPayload,
  ): Promise<ProfileModel> => {
    try {
      const response = await KroClient.patch(
        KroEndpoints.updateNotificationSettings,
        payload,
      );
      return mapProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const updatePrivacySettings = async (
    payload: UpdatePrivacySettingsPayload,
  ): Promise<ProfileModel> => {
    try {
      const response = await KroClient.patch(
        KroEndpoints.updatePrivacySettings,
        payload,
      );
      return mapProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchUserCampaigns,
    withdrawCampaignDonation,
    sayThanks,
    fetchCampaigns,
    fetchBankAccountInformation,
    fetchWithdrawalBanks,
    fetchActivityLog,
    makeEnquiry,
    updateSocialMediaLinks,
    fetchProfile,
    fetchUserProfile,
    updateProfile,
    updateBannerPicture,
    updateProfilePicture,
    updateNotificationSettings,
    updatePrivacySettings,
  };
};

export default DashboardRepository;
