import { formatCurrency } from "common/utils/currencyFormater";
import FundraiserInfo from "features/campaign/presentation/components/campaignDetails/campaignFundraiserInfo";
import MediaPreview from "features/campaign/presentation/components/campaignDetails/campaignMediaPreview";
import { KroButton } from "features/components/buttons/kroButton";
import { KroCategoryBadge } from "features/components/display/kroBadges";
import { EndCampaignHeader } from "../components/endCampaign/endCampaignHeader";
import useKroCountdown from "features/components/countdown/kroCountdown";
import TabBarScrollTo from "features/components/tab/kroScrollToTabBar";
import DonationsTable from "../components/endCampaign/tableComponent";
import { EndCampaignModal } from "../components/endCampaign/endCampaignModal";
import { useEffect, useState } from "react";
import { useEndCampaign } from "features/campaign/presentation/hooks/useEndCampaign";
import { CampaignModelMapper, isCampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Routes from "router/routes";
import useFetchCampaign from "features/campaign/presentation/hooks/useFetchCampaign";
import KroLoadingIndicator, { LoaderType } from "features/components/loader/kroCircularIndicator";
import { useFetchComments } from "features/campaign/presentation/hooks/useFetchComments";
import { Comments } from "features/campaign/presentation/components/campaignDetails/commentsComponent";
import KroShareModal from "features/components/modals/kroShareModal";
import WithdrawalModal from "../components/withdraw/withdrawalModal";
import { toast } from "react-toastify";
import { queryClient } from "index";

const EndCampaignPage = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const location = useLocation();

  //use action snackbar toshow error
  const {
    data: campaignData,
    isLoading,
    error
  } = useFetchCampaign(campaignId || "");

  const navigate = useNavigate();

  const campaign = (
    campaignData
      ? campaignData
      : isCampaignModelMapper(location.state)
        ? location.state
        : undefined
  ) as CampaignModelMapper | undefined;

  const countdown = useKroCountdown(campaign?.endDate);

  //TDOO:This will always be true
  useEffect(() => {
    if (!campaignId) {
      navigate(Routes.MY_CAMPAIGNS);
    } else {
    }
  }, [campaignId, navigate]);

  const [page] = useState(1);
  const { data } = useFetchComments(campaignId ?? "", page, 10);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState<boolean>(false);
  const canRequestWithdrawal =
    campaign?.status === "ended" && campaign.withDrawalRequested === false;

  const handleWithdrawModalOpen = () => {
    if (isLoading || error) return;
    if (!campaign || !canRequestWithdrawal) return;
    setWithdrawModalOpen(true);
  };

  const handleWithdrawModalClose = () => {
    setWithdrawModalOpen(false);
  };
  const [isShareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const handleShareModalOpen = () => {
    setShareModalOpen(true);
  };
  const [isEndCampaignSuccessModalOpen, setEndCampaignSuccessModalOpen] =
    useState<boolean>(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleEndCampaignSuccessModalClose = () => {
    setEndCampaignSuccessModalOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const tabs = [
    {
      name: "activity log",
      label: "Activity Log",
      targetId: "activity-log-section"
    },
    {
      name: "withdrawals",
      label: "Withdrawals",
      targetId: "withdrawals-section"
    },
    {
      name: "analytics",
      label: "Analytics",
      targetId: "analytics-section"
    },
    {
      name: "comments",
      label: "Comments",
      targetId: "comments-section",
      badge: data?.comments?.length
    }
  ];
  const media: { type: "image" | "video" | undefined; src: string }[] = [
    ...(campaign?.videoUrl
      ? [{ type: "video" as const, src: campaign?.videoUrl ?? "" }]
      : []),
    ...(campaign?.imageUrls?.map((image) => ({
      type: "image" as const,
      src: image
    })) || [])
  ];

  // const { showSnackbar } = useSnackbar();

  const endCampaign = useEndCampaign(
    (error: Error) => {
      toast.error(error.message);
    },
    (data: CampaignModelMapper) => {
      setOpen(false);
      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaignId]
      });
      setEndCampaignSuccessModalOpen(true);
    }
  );

  const handleEditCampaign = () => {
    if (!campaign) return;
    if (campaign?.slug) {
      navigate(Routes.EDIT_CAMPAIGN(campaign?.slug ?? ""), { state: campaign });
    } else {
      navigate(Routes.EDIT_CAMPAIGN(campaign?.id ?? ""), { state: campaign });
    }

  };

  if (isLoading && !campaign) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }

  return (
    <>
      <KroShareModal
        campaignId={campaign?.slug === null ? campaign?.id : campaign?.slug}
        isOpen={isShareModalOpen}
        onClose={handleShareModalClose}
      />
      <EndCampaignModal
        handleEndCampaignSuccessModalClose={handleEndCampaignSuccessModalClose}
        isEndCampaignSuccessModalOpen={isEndCampaignSuccessModalOpen}
        campaignId={campaign?.id ?? ""}
        isOpen={isOpen}
        handleClose={handleCloseModal}
        endCampaign={endCampaign}
      />
      {campaign && (
        <WithdrawalModal
          campaign={campaign}
          isModalOpen={isWithdrawModalOpen}
          onModalClose={handleWithdrawModalClose}
        />
      )}
      <div className="mx-auto mb-12 max-w-[1290px] p-6 lg:p-0">
        <EndCampaignHeader
          data={campaign}
          openCampaignModal={handleOpenModal}
        />
        <div
          className="mb-[42px] mt-6 flex w-full flex-col gap-[24px] rounded-3xl border border-neutral-200 p-2 pb-3 lg:flex-row">
          <div className="w-full pl-3 pr-3 pt-3">
            <MediaPreview
              media={media}
              campaignCategory={campaign?.category ?? ""}
            />
          </div>
          <div className="w-full self-start pt-3">
            <div className="mb-6">
              <h3
                className="mb-3 text-left font-inter text-[40px] font-semibold leading-[50px] tracking-[-0.005em] text-neutral-900">
                {campaign?.title}
              </h3>
              <div className="flex gap-2">
                <KroCategoryBadge category={campaign?.category ?? ""} />
                {/* <KroVerifiedBadge /> */}
              </div>
            </div>

            <div className="flex flex-col">
              <FundraiserInfo
                avatarUrl={campaign?.creatorAvatar ?? ""}
                fundraiserText={campaign?.creatorName ?? ""}
                amountRaised={formatCurrency(campaign?.amountRaised ?? "")}
                goalAmount={formatCurrency(campaign?.targetAmount ?? "")}
                daysLeft={
                  campaign?.status === "ended" ? "Campaign Ended" : countdown
                }
                numberOfDonors={campaign?.donations?.length ?? 0}
                isVerified={false}
                campaign={campaign}
              ></FundraiserInfo>

              <KroButton
                className="h-[56px]"
                shadow=""
                hasIcon={false}
                isDisabled={!canRequestWithdrawal}
                title={
                  campaign?.withDrawalRequested
                    ? "Funds Withdrawn"
                    : "Withdraw Funds"
                }
                onclick={handleWithdrawModalOpen}
              />
              <button
                onClick={handleShareModalOpen}
                className="mt-4 flex h-[56px] w-full flex-grow transform items-center justify-center space-x-2 rounded-full border border-neutral-200 bg-neutral-50 px-4 py-2 text-body-md font-medium text-[#0F0F0F] transition duration-300 ease-in-out hover:scale-105 hover:bg-neutral-200"
              >
                <span>Share</span>
                <img src="/assets/icons/share-fill.svg" alt="share" />
              </button>
            </div>
          </div>
        </div>
        <TabBarScrollTo tabs={tabs} />
        <div className="mt-4 rounded-xl border border-stroke-soft-200 pr-6">
          <div className="flex items-center justify-between">
            <div className="flex p-4">
              <img
                src="/assets/icons/activity_log.svg"
                alt=""
                className="hidden lg:flex"
              />
              <div className="flex flex-col pl-2">
                <p className="text-body-lg font-[500] text-strong-950 lg:text-body-sm">
                  Activity Log
                </p>
                <p className="text-body-md text-sub-600 lg:text-body-sm">
                  View recent donations made to your campaign.
                </p>
              </div>
            </div>
            <button className="text-body-md font-[500] text-neutral-900 lg:text-body-sm lg:text-warning-700">
              See all
            </button>
          </div>
          {/**table goes here **/}
          <DonationsTable campaignId={campaign?.id ?? ""} />
        </div>
        <ul id="comments-section" className="mt-4">
          <span className="text-body-lg font-bold text-black">Comments</span>

          {data?.comments && data.comments.length > 0 ? (
            data.comments.map((comment, index) => (
              <Comments
                full_name={comment.full_name}
                text={comment.text}
                key={index}
                createdAt={comment.createdAt}
              />
            ))
          ) : (
            <p className="mt-4 text-center text-body-lg text-sub-600">
              No Comments yet
            </p>
          )}
        </ul>
        <div className="bottom-0 left-0 right-0 flex items-center justify-between space-x-4 pt-12 lg:hidden">
          <button
            onClick={
              campaign?.status === "ended" ? () => {
              } : handleEditCampaign
            }
            className="h-[52px] w-[170.5px] flex-grow justify-items-center rounded-3xl border border-neutral-200 bg-neutral-50 px-4 py-2 text-body-sm text-[#0F0F0F] hover:bg-neutral-200"
          >
            Edit
          </button>

          <button
            onClick={campaign?.status === "ended" ? () => {
            } : handleOpenModal}
            className={`h-[52px] w-[170.5px] flex-grow justify-items-center rounded-3xl border border-negative-600 px-4 py-2 text-body-sm text-red-600 hover:bg-red-100 ${campaign?.status === "ended" ? "cursor-not-allowed opacity-50" : ""}`}
          >
            End Campaign
          </button>
        </div>
      </div>
    </>
  );
};

export default EndCampaignPage;
