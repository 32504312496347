import { useEffect, useState } from "react";

export const useGeoLocation = () => {
  const [location, setLocation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      setIsLoading(true);
      fetch("https://ipapi.co/json/")
        .then(response => response.json())
        .then(data => {
          setIsLoading(false);
          setLocation(data.country_code);
        })
        .catch(error => {
          setIsLoading(false);
          setError(error);
        });
    },
    []);


  return { location, isLoading, error };
};