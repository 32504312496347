import { CampaignCategoryModel } from "../models/campaignCategoryModel";
import {
  UpdateCampaignInformationPayload,
  UpdateCampaignInformationPayloadWithPrivacy
} from "../models/updateCampaignInformationPayload";
import { CampaignTypeResponse } from "../models/campaignTypeResponse";
import kroFeeModel from "../models/kroFee";
import CampaignRepository from "../repositories/campaignRepository";
import { SaveVideoPayload } from "../models/saveVideoPayload";
import { UpdateCampaignTargetAmountPayload } from "../models/updateCampaignTargetAmountPayload";
import { UpdateCampaignDurationPayload } from "../models/updateCampaignDurationPayload";
import { CampaignModelMapper } from "../models/campaignModel";
import { DonationResponseMapper, DonationResponseMapperWithAnon } from "../models/donationResponse";
import { InitializeDonationPayload } from "../models/initializeDonationPayload";
import { UpdateCampaignStatusPayload } from "../models/updateCampaignStatusPayload";
import { VerifyDonationPayload } from "../models/verifyDonationPayload";
import { DonationFeesResponse } from "../models/donationFeesResponse";
import { CommentPayload } from "../models/commentPayload";
import { PaginatedResponse } from "../models/paginatedResponse";
import { CommentsResponse } from "../models/comments";
import { CurrencyData } from "@/features/campaign/domain/models/currencies";

interface CampaignService {
  setCampaignType(campaignType: string): Promise<CampaignTypeResponse>;

  updateCampaignInformation(
    payload: UpdateCampaignInformationPayload
  ): Promise<boolean>;

  fetchCampaignCategories(): Promise<Array<CampaignCategoryModel>>;

  getKroFee(): Promise<kroFeeModel>;

  fetchDonationFees(): Promise<DonationFeesResponse>;

  handleSaveVideoLinkAndUploadFiles(
    payload: SaveVideoPayload,
    files: Array<File>
  ): Promise<boolean>;

  fetchCampaign(id: string): Promise<CampaignModelMapper>;

  initializeDonation(
    payload: InitializeDonationPayload
  ): Promise<DonationResponseMapperWithAnon>;

  verifyDonation(
    payload: VerifyDonationPayload
  ): Promise<DonationResponseMapper>;

  updateCampaignVisibility(id: string, payload: UpdateCampaignInformationPayloadWithPrivacy): Promise<boolean>;

  createComment(payload: CommentPayload): Promise<unknown>;

  fetchComments(
    campaignId: string,
    page: number,
    limit: number
  ): Promise<PaginatedResponse<CommentsResponse>>;

  updateFundraiserDetails(
    files: Array<File>,
    fundingPayload: UpdateCampaignTargetAmountPayload,
    durationPayload: UpdateCampaignDurationPayload
  ): Promise<CampaignModelMapper>;

  fetchSupportedCurrencies(): Promise<CurrencyData[]>;
}

export class CampaignServiceImpl implements CampaignService {
  fetchSupportedCurrencies(): Promise<CurrencyData[]> {
    return CampaignRepository().fetchSupportedCurrencies();
  }

  fetchComments(
    campaignId: string,
    page: number,
    limit: number
  ): Promise<PaginatedResponse<CommentsResponse>> {
    return CampaignRepository().fetchComments(campaignId, page, limit);
  }

  createComment(payload: CommentPayload) {
    return CampaignRepository().createComment(payload);
  }

  fetchDonationFees(): Promise<DonationFeesResponse> {
    return CampaignRepository().fetchDonationFees();
  }

  initializeDonation(
    payload: InitializeDonationPayload
  ): Promise<DonationResponseMapperWithAnon> {
    return CampaignRepository().initializeDonation(payload);
  }

  verifyDonation(
    payload: VerifyDonationPayload
  ): Promise<DonationResponseMapper> {
    return CampaignRepository().verifyDonation(payload);
  }

  fetchCampaign(id: string): Promise<CampaignModelMapper> {
    return CampaignRepository().fetchCampaign(id);
  }

  endCampaign(id: string): Promise<CampaignModelMapper> {
    return CampaignRepository().endCampaign(id);
  }

  handleSaveVideoLinkAndUploadFiles(
    payload: SaveVideoPayload,
    files: Array<File>
  ): Promise<boolean> {
    return CampaignRepository().handleSaveAndUpload(payload, files);
  }

  handleSaveVideoLink(payload: SaveVideoPayload): Promise<boolean> {
    return CampaignRepository().saveVideoLink(payload);
  }

  updateCampaignVisibility(id: string, payload: UpdateCampaignInformationPayloadWithPrivacy): Promise<boolean> {
    return CampaignRepository().updateCampaignPrivacy(id, payload.visibility);
  }

  async fetchCampaignCategories(): Promise<Array<CampaignCategoryModel>> {
    return CampaignRepository().fetchCampaignCategories();
  }

  async setCampaignType(campaignType: string): Promise<CampaignTypeResponse> {
    return CampaignRepository().setCampaignType(campaignType);
  }

  async updateCampaignInformation(
    payload: UpdateCampaignInformationPayloadWithPrivacy
  ): Promise<boolean> {
    try {
      await CampaignRepository().updateCampaignInformation(payload);
      await this.updateCampaignVisibility(payload.id, payload);

      return false;
    } catch (error) {
      throw error;
    }
  }

  async updateCampaignFunding(
    payload: UpdateCampaignTargetAmountPayload
  ): Promise<boolean> {
    return CampaignRepository().updateCampaignFunding(payload);
  }

  async updateCampaignDuration(
    payload: UpdateCampaignDurationPayload
  ): Promise<boolean> {
    return CampaignRepository().updateCampaignDuration(payload);
  }

  async fileUpload(files: Array<File>): Promise<boolean> {
    return CampaignRepository().fileUpload(files);
  }

  async fileUploadCampaign(id: string, files: Array<File>): Promise<boolean> {
    return CampaignRepository().fileUploadCampaign(id, files);
  }

  async updateFundraiserDetails(
    files: Array<File>,
    fundingPayload: UpdateCampaignTargetAmountPayload,
    durationPayload: UpdateCampaignDurationPayload
  ): Promise<CampaignModelMapper> {
    try {
      await this.updateCampaignFunding(fundingPayload);
      await this.updateCampaignDuration(durationPayload);
      await this.fileUpload(files);
      return this.fetchCampaign(fundingPayload.id);
    } catch (error) {
      throw error;
    }
  }

  async updateCampaignStatus(
    payload: UpdateCampaignStatusPayload
  ): Promise<boolean> {
    return CampaignRepository().updateCampaignStatus(payload);
  }

  getKroFee(): Promise<kroFeeModel> {
    return CampaignRepository().fetchKroFees();
  }
}
