import { cn } from "common/utils/utils";
import Routes from "router/routes";

const WebsiteFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="mx-auto mb-[10px] mt-[31px] w-full max-w-[1440px] rounded-[32px] bg-[#022928] px-6 py-16 lg:mt-[64px] lg:px-24">
      {/* Header Section */}
      <div>
        <p className="text-[20px] font-normal text-[#D2D2D2] lg:text-[30px] lg:font-medium">
          Got a need?
        </p>
        <h2 className="mt-4 text-[56px] font-medium leading-[1] tracking-[-0.03em] text-[#D2D2D2] lg:mt-5 lg:text-[100px]">
          Raise today!
        </h2>
      </div>

      <div className="mt-12 flex flex-col lg:mt-20 lg:flex-row lg:gap-20">
        {/* Navigation Section */}
        <div className="order-1 flex-1 lg:order-none">
          <div className="lg:gap-x-18 grid grid-cols-3 gap-x-4">
            {/* Company Column */}
            <div>
              <h3 className="text-base mb-3 font-medium text-[#4FBA6D]">
                Company
              </h3>
              <ul className="text-base space-y-2 text-[#FFFEFD]">
                <li>
                  <a
                    href={Routes.ABOUT}
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Who we are
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={Routes.HOW_TO_USE}
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Our services
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="/"
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Our clients
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={Routes.CONTACT_US}
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>

            {/* Product Column */}
            <div>
              <h3 className="text-base mb-3 font-medium text-[#4FBA6D]">
                Product
              </h3>
              <ul className="text-base space-y-2 text-[#FFFEFD]">
                <li>
                  {" "}
                  <a
                    href="/"
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Giv
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={Routes.CREATE_CAMPAIGN}
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Raise
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="/"
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Groups
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={Routes.CREATE_CAMPAIGN}
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Charity
                  </a>
                </li>
              </ul>
            </div>

            {/* Legal Column */}
            <div>
              <h3 className="text-base mb-3 font-medium text-[#4FBA6D]">
                Legal
              </h3>
              <ul className="text-base space-y-2 text-[#FFFEFD]">
                <li>
                  {" "}
                  <a
                    href="https://separate-soup-1a5.notion.site/Terms-and-Conditions-145278ebc2bb8049ae26e92d9fd7435d?pvs=4"
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://separate-soup-1a5.notion.site/Privacy-Policy-145278ebc2bb80fdb2cdc16843e10edb?pvs=4"
                    className="hover:text-[#4FBA6D]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/" className="hover:text-[#4FBA6D]">
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="order-2 mt-12 lg:mt-0 lg:w-[400px]">
          <h3 className="text-base mb-3.5 font-normal text-[#FFFEFD]">
            Subscribe newsletter
          </h3>
          <p className="text-base mb-6 text-[#D2D2D2]">
            Subscribe our newsletter to get the latest news and updates!
          </p>
          <div className="flex h-14 items-center rounded-[42px] bg-[#1C1C1C] px-4">
            <img
              src="/assets/icons/mail-plain.svg"
              alt="mail"
              className="mr-3 h-5 w-5 flex-shrink-0"
            />
            <input
              type="email"
              placeholder="Enter your email"
              className="text-base min-w-0 flex-1 bg-transparent text-[#D2D2D2] outline-none"
            />
            <button className="text-base ml-2 flex-shrink-0 whitespace-nowrap rounded-full bg-[#4FBA6D] px-4 py-2.5 font-medium text-[#FFFEFD] sm:px-6">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-16 lg:mt-20">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div className="mb-12 flex items-center gap-8 lg:mb-0">
            <a href={Routes.HOME} target="_blank" rel="noopener noreferrer">
              <img
                src="/assets/icons/giv_logo.svg"
                alt="giv logo"
                className="h-8 transition-transform duration-300 ease-in-out hover:scale-110"
              />
            </a>
            {/* <p className="text-base text-[#D2D2D2]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
          </div>

          <div
            className={cn(
              "text-sm flex flex-col gap-4 text-[#FFFEFD] lg:flex-row",
            )}
          >
            <div className="flex items-center">
              <span className="text-[12px] font-medium leading-[100%] tracking-[0%] text-[#B3B3B3] lg:text-[14px] lg:font-normal lg:leading-[140%] lg:text-[#FFFEFD]">
                Giv {currentYear}
              </span>
              <div className="mx-6 h-5 w-px bg-[#5A5A59]" />
              <span className="text-[12px] font-medium leading-[100%] tracking-[0%] text-[#B3B3B3] lg:text-[14px] lg:font-normal lg:leading-[140%] lg:text-[#FFFEFD]">
                All Rights Reserved
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-[12px] font-medium leading-[100%] tracking-[0%] text-[#B3B3B3] lg:text-[14px] lg:font-normal lg:leading-[140%] lg:text-[#FFFEFD]">
                Powered by
              </span>
              <a
                href="https://kro.ng"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/icons/kroLogoWhite.svg"
                  alt="kro logo"
                  className="ml-2 h-[18px] transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
