import React, { useRef } from "react";
import useFetchActivityLog from "../../hooks/useFetchActivityLog";
// import { useParams } from "react-router";
import { formatCurrency } from "common/utils/currencyFormater";
import { SayThanksModal } from "./sayThanksModals";
import { Activity } from "features/campaign/domain/models/activites";

function DonationsTable({ campaignId }: { campaignId: string }) {

  const { activities, isLoading } = useFetchActivityLog(campaignId ?? "");
  const [isSayThanksModalOpen, setSayThanksModalOpen] = React.useState(false);
  const selectedDonation = useRef<Activity | null>(null);
  const handleSayThanks = (donor: any) => {
    selectedDonation.current = donor;
    setSayThanksModalOpen(true);
  };

  console.log(selectedDonation.current);

  if (isLoading) {
    return <DonationsTableShimmer />;
  }
  if (!activities || activities.length === 0) {
    return (
      <p className="mb-4 mt-4 text-center text-body-lg text-sub-600">
        No activity yet
      </p>
    );
  }

  return (
    <>
      <SayThanksModal
        isModalOpen={isSayThanksModalOpen}
        onModalClose={() => setSayThanksModalOpen(false)}
        donation={selectedDonation.current}
      />

      <div className="mx-auto pl-5">
        <div className="overflow-x-auto rounded-lg">
          <table className="w-full border-collapse">
            <thead className="h-[36px]">
            <tr className="text-sm bg-weak-600 text-left text-sub-600">
              <th className="pl-6 text-body-sm font-thin text-sub-600">
                Donor
              </th>
              <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
                Comment
              </th>
              <th className="text-body-sm font-thin text-sub-600">Amount</th>
              <th className="text-body-sm font-thin text-sub-600">
                Date & Time
              </th>
              <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
                Payment Method
              </th>
              <th className="text-body-sm font-thin text-sub-600"></th>
            </tr>
            </thead>
            <tbody>
            {(activities ?? []).map((donation, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="mb-5 ml-4 mr-4 mt-6 flex items-center pl-2">
                  <p className="text-strong-600 text-body-sm">
                    {donation.donor}
                  </p>
                  {false && (
                    <span
                      className="ml-2 h-[16px] items-center rounded bg-warning-50 px-2 text-body-xs font-semibold text-warning-700">
                        NEW
                      </span>
                  )}
                </td>
                <td className="text-strong-600 hidden w-[325px] text-body-sm sm:table-cell">
                  {/* {donation.comment} */}
                </td>
                <td className="text-strong-600 w-[180px] text-body-sm">
                  {formatCurrency(donation.amount, true)}
                </td>
                <td className="text-strong-600 text-body-sm">
                  {new Date(donation.createdAt).toLocaleString()}
                </td>
                <td className="text-strong-600 hidden text-body-sm sm:table-cell">
                  {donation.paymentMethod ??
                    ""
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                </td>
                <td>
                  {!donation.is_thank_you_sent ||
                  donation.is_thank_you_sent === null ? (
                    <button
                      onClick={() => handleSayThanks(donation)}
                      className="w-[94px] rounded-[20px] border border-stroke-soft-200 pb-1 pl-2 pr-2 pt-1 text-body-sm text-sub-600 hover:text-blue-700 lg:rounded-lg"
                    >
                      Say thanks
                    </button>
                  ) : (
                    <button
                      className="flex w-[125px] flex-row items-center justify-center gap-2 rounded-[20px] bg-weak-600 pb-2 pl-2 pr-2 pt-2 lg:rounded-lg">
                      <p className="text-body-sm font-medium text-strong-950">
                        Thanks sent
                      </p>
                      <img
                        src="/assets/icons/heart-fill.svg"
                        alt="heart icon"
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default DonationsTable;

function DonationsTableShimmer() {
  return (
    <div className="mx-auto pl-5">
      <div className="overflow-x-auto rounded-lg">
        <table className="w-full border-collapse">
          <thead className="h-[36px]">
          <tr className="text-sm bg-weak-600 text-left text-sub-600">
            <th className="pl-6 text-body-sm font-thin text-sub-600">
              Donor
            </th>
            <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
              Comment
            </th>
            <th className="text-body-sm font-thin text-sub-600">Amount</th>
            <th className="text-body-sm font-thin text-sub-600">
              Date & Time
            </th>
            <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
              Payment Method
            </th>
            <th className="text-body-sm font-thin text-sub-600"></th>
          </tr>
          </thead>
          <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index} className="border-b hover:bg-gray-50">
              <td className="mb-5 ml-4 mr-4 mt-6 flex items-center pl-2">
                <div className="h-4 w-24 animate-pulse rounded bg-gray-200"></div>
              </td>
              <td className="text-strong-600 hidden w-[325px] text-body-sm sm:table-cell">
                <div className="h-4 w-48 animate-pulse rounded bg-gray-200"></div>
              </td>
              <td className="text-strong-600 w-[180px] text-body-sm">
                <div className="h-4 w-20 animate-pulse rounded bg-gray-200"></div>
              </td>
              <td className="text-strong-600 text-body-sm">
                <div className="h-4 w-32 animate-pulse rounded bg-gray-200"></div>
              </td>
              <td className="text-strong-600 hidden text-body-sm sm:table-cell">
                <div className="h-4 w-24 animate-pulse rounded bg-gray-200"></div>
              </td>
              <td>
                <div className="h-8 w-24 animate-pulse rounded bg-gray-200"></div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
