import { useQuery } from "@tanstack/react-query";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

export const useFetchComments = (
  campaignId: string | undefined,
  page: number,
  limit: number
) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["fetchComments", campaignId, page, limit],
    queryFn: () => campaignService.fetchComments(campaignId ?? "", page, limit)
  });

  return { data, error, isLoading };
};
