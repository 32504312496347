import { SettingsProps } from "features/dashboard/domain/model/settingsProps";
import KroToggleSwitch from "features/components/inputs/kroToggleSwitch";
import { TabController } from "features/components/tab/kroTabBar";
import { useState } from "react";
import { UserModel } from "common/models/userModel";
import { useUpdateNotificationSettings } from "../../hooks/useUpdateSettings";
import { toast } from "react-toastify";

const NotificationsTab = ({
  tabController,
  userProfile,
}: {
  tabController: TabController;
  userProfile: UserModel;
}) => {
  const [notificationSettings, setNotificationSettings] = useState({
    newsAndUpdates: userProfile.notificationSettings?.optInNewsUpdates ?? false,
    thankYouMessages:
      userProfile.notificationSettings?.optInThankYouEmails ?? false,
    campaignUpdates:
      userProfile.notificationSettings?.optInCampaignMilestoneUpdates ?? false,
  });

  const updateNotificationsMutation = useUpdateNotificationSettings(
    (error) => {
      toast.error(error.message);
    },
    () => {
      toast.success("Notification settings updated successfully");
    },
  );

  const handleToggle = (setting: string) => {
    const updatedSettings = {
      ...notificationSettings,
      [setting]:
        !notificationSettings[setting as keyof typeof notificationSettings],
    };
    setNotificationSettings(updatedSettings);

    const payload = {
      opt_in_news_updates: updatedSettings.newsAndUpdates,
      opt_in_thank_you_emails: updatedSettings.thankYouMessages,
      opt_in_campaign_milestone_updates: updatedSettings.campaignUpdates,
    };
    updateNotificationsMutation.mutate(payload);
  };

  const notificationsList: Array<SettingsProps> = [
    {
      title: "News and Updates",
      description:
        "Stay informed via text and email about the latest news, updates, and announcements.",
      enabled: notificationSettings.newsAndUpdates,
      key: "newsAndUpdates",
    },
    {
      title: "Thank you Emails/Messages",
      description: "Enable or disable thank you emails or messages.",
      enabled: notificationSettings.thankYouMessages,
      key: "thankYouMessages",
    },
    {
      title: "Campaign Milestone Updates",
      description: "Automate updates to donor about campaign milestones.",
      enabled: notificationSettings.campaignUpdates,
      key: "campaignUpdates",
    },
    {
      title: "Support Chat History",
      description: "Access to past interactions with platform support.",
      enabled: false,
    },
  ];

  return (
    <div className="min-h-[calc(100vh-400px)]">
      {notificationsList.map((notification, index) => (
        <div className="mt-[40px] flex flex-row gap-[24px]" key={index}>
          <div className="w-full flex-1">
            <p className="mb-[4px] font-dm-sans text-[14px] font-semibold leading-[20px] tracking-[-0.6%] text-strong-950">
              {notification.title}
            </p>
            <p className="font-dm-sans text-[14px] leading-[18px] tracking-[0%] text-sub-600">
              {notification.description}
            </p>
          </div>

          {notification.title === "Support Chat History" ? (
            <img src="/assets/icons/external-link-line.svg" alt="link line" />
          ) : (
            <div className="flex-2">
              <KroToggleSwitch
                isOn={notification.enabled}
                handleToggle={() => handleToggle(notification.key!)}
                disabled={updateNotificationsMutation.isPending}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NotificationsTab;
