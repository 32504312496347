import { FC, ReactNode } from "react";

//TODO: user <T> for dynamic type
interface RadioButtonOption {
  id: string;
  label: string;
  value: string;
}

const KroRadioButtonGroup: FC<{
  name: string;
  options: RadioButtonOption[];
  onChange?: (value: string) => void;
  selectedValue?: string;
  labelField?: ReactNode;
  direction?: "row" | "column";
}> = ({
        name,
        options,
        onChange,
        labelField,
        selectedValue = "",
        direction = "column"
      }) => {
  return (
    <div
      className={`flex ${direction === "column" ? "flex-col gap-2" : "flex-row gap-4"}`}
    >
      {options.map((option) => (
        <RadioButton
          key={option.id}
          id={option.id}
          name={name}
          value={option.value}
          label={option.label}
          onToggle={() => onChange && onChange(option.value)}
          inputClassName="group-radio-input"
          labelClassName="group-radio-label"
          labelField={labelField}
          isChecked={selectedValue === option.value}
        />
      ))}
    </div>
  );
};

export default KroRadioButtonGroup;

const RadioButton: FC<{
  id: string;
  name: string;
  value: string;
  label: string;
  onToggle?: (checked: boolean) => void;
  inputClassName?: string;
  labelClassName?: string;
  labelField?: ReactNode;
  isChecked?: boolean;
  useSquareCheckbox?: boolean;
}> = ({
        id,
        name,
        value,
        label,
        onToggle,
        labelField,
        inputClassName = "",
        labelClassName = "",
        isChecked = false,
        useSquareCheckbox = false
      }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (onToggle) {
      onToggle(checked);
    }
  };

  return (
    <div className="flex items-center">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        className={`hidden ${inputClassName}`}
        onChange={handleChange}
        checked={isChecked}
      />
      <label
        htmlFor={id}
        className={`flex cursor-pointer items-center text-body-md tracking-tight text-neutral-900 ${labelClassName} ${
          isChecked ? "text-neutral-900" : ""
        }`}
      >
        <div className="mr-2 flex-shrink-0">
          {useSquareCheckbox ? (
            <div
              className={`h-5 w-5 border-2 border-neutral-900 transition-all duration-300 ease-in-out ${
                isChecked ? "bg-neutral-900" : "bg-white"
              }`}
            >
              {isChecked && (
                <div className="flex h-full w-full items-center justify-center">
                  <div className="h-3 w-3 bg-white"></div>
                </div>
              )}
            </div>
          ) : (
            <img
              src={
                isChecked
                  ? "/assets/icons/radio_checked.svg"
                  : "/assets/icons/radio_unchecked.svg"
              }
              alt={isChecked ? "checked" : "unchecked"}
              className={`transition-transform duration-300 ease-in-out ${
                isChecked ? "scale-100" : "scale-95"
              }`}
            />
          )}
        </div>
        {labelField ? labelField : <div className="flex-grow">{label}</div>}
      </label>
    </div>
  );
};
