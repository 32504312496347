import React, { FC, useState } from "react";
import { ChevronDown } from "lucide-react";
import { CurrencyData, getCurrencyIcon } from "features/campaign/domain/models/currencies";

type CurrencyDropdownProps = {
  selectedCurrency: CurrencyData;
  onCurrencyChange: (currency: CurrencyData) => void;
  currencies: CurrencyData[];
  className?: string;
  disabled?: boolean;
}

const KroCurrencyDropDown: FC<CurrencyDropdownProps> = ({
                                                          selectedCurrency,
                                                          onCurrencyChange,
                                                          currencies,
                                                          className = "",
                                                          disabled = false
                                                        }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleCurrencySelect = (currency: CurrencyData) => {
    onCurrencyChange(currency);
    setIsOpen(false);
  };

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div className={`relative ${className}`}>
      <button
        type="button"
        className={`flex items-center justify-between py-2 min-w-[83px] text-left`}
        onClick={toggleDropdown}
        disabled={disabled}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <div className="flex items-center">
          <img className="mr-2" src={getCurrencyIcon(selectedCurrency.code)} alt={selectedCurrency.code} />
          <span className="text-sm">{selectedCurrency.code}</span>
        </div>
        <ChevronDown
          size={16}
          className={`transition-transform ml-1 ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={handleClickOutside}
            aria-hidden="true"
          ></div>
          <ul
            className="absolute z-20 mt-3 max-h-60 w-auto max-w-[83px] overflow-auto rounded-[12px] bg-white py-1 shadow-lg border border-[#E5E5E5]"
            role="listbox"
          >
            {currencies.map((currency) => (
              <li
                key={currency.code}
                role="option"
                aria-selected={selectedCurrency.code === currency.code}
                className={`p-[12px] cursor-pointer flex items-center justify-between ${
                  selectedCurrency.code === currency.code
                    ? "bg-neutral-100"
                    : "hover:bg-neutral-50"
                }`}
                onClick={() => handleCurrencySelect(currency)}
              >
                <div className="flex items-center">
                  <img className="mr-2" src={getCurrencyIcon(currency.code)} alt={currency.code} />
                  <span className="font-dm-sans text-[14px] leading-[100%] tracking-[-0.5%] text-neutral-900">
                    {currency.code}
                  </span>
                </div>
                {/*{selectedCurrency.code === currency.code && (*/}
                {/*  <Check size={16} className="text-brand-700 ml-4 flex-shrink-0" />*/}
                {/*)}*/}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default KroCurrencyDropDown;