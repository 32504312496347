import React, { useState } from "react";

interface FAQItem {
  id: string;
  number: string;
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    id: "item-1",
    number: "01",
    question: "What is Giv?",
    answer:
      "Giv is a fundraising platform that allows individuals, groups, and charities to create fundraising campaigns. It can be used for various causes, from charity events to personal projects and support among friends."
  },
  {
    id: "item-2",
    number: "02",
    question: "How does Giv work?",
    answer:
      "To start, users create a campaign on Giv with a specific goal and description of their cause. Supporters can then contribute by selecting preset donation amounts, making it easy and efficient to donate."
  },
  {
    id: "item-3",
    number: "03",
    question: "Who can use Giv to raise funds?",
    answer:
      "Giv is open to anyone who needs to raise funds for a legitimate cause, including charities, nonprofits, individuals, and community groups. Giv allows fundraisers to share their story and connect with supporters."
  },
  {
    id: "item-4",
    number: "04",
    question: "Are there any fees for using Giv?",
    answer:
      "Yes, Giv charges a small platform fee and standard transaction processing fees to cover operational costs. We are transparent about our fees, which will be displayed clearly when creating or donating to a campaign."
  },
  {
    id: "item-5",
    number: "05",
    question: "How do I set up a campaign on Giv?",
    answer:
      "Setting up a campaign on Giv is simple. Sign up or log in, then click “Start a Campaign.” From there, you can add details, set a goal, add images, and choose preset donation amounts for donors to select from."
  },
  {
    id: "item-6",
    number: "06",
    question: "Can I change my fundraising goal after my campaign has started?",
    answer:
      "Yes, you can adjust your fundraising goal if needed. However, we recommend setting a realistic target from the beginning to build trust and transparency with potential donors."
  },
  {
    id: "item-7",
    number: "07",
    question: "How do donors know their contributions are secure?",
    answer:
      "Giv uses a secure payment system and adheres to industry-standard security protocols. All donations are processed securely, and we prioritize data privacy to ensure your information and funds are protected."
  },
  {
    id: "item-8",
    number: "08",
    question: "What types of payments does Giv accept?",
    answer:
      "Giv accepts major debit cards, bank tranfers as well as other popular online payment methods. We’re continually working to expand our payment options to make donating as convenient as possible."
  },
  {
    id: "item-9",
    number: "09",
    question: "How will I receive the funds I raise on Giv?",
    answer:
      "Once your campaign is complete or reaches certain milestones, you can request a payout to your bank account. We provide an easy payout process and notify you of any fees associated with withdrawals."
  },
  {
    id: "item-10",
    number: "10",
    question: "How can I promote my Giv campaign to reach more donors?",
    answer:
      "You can share your campaign link on social media, email, and messaging apps. Giv also offers tips and tools to help you reach a wider audience, including sharing features directly on the platform."
  }
];

const FAQ = ({
               iconClassName,
               answerClassName
             }: {
  iconClassName?: string;
  answerClassName?: string;
}) => {
  const [openItem, setOpenItem] = useState<string | null>(null);

  const toggleItem = (itemId: string) => {
    setOpenItem(openItem === itemId ? null : itemId);
  };

  return (
    <div className="lg:mt-[120px] mt-[56px] px-4 lg:px-[65px]">
      <h1
        className="mb-12 text-center font-dm-sans text-[42px] font-medium leading-[42px] tracking-[-0.03em] lg:text-[72px] lg:leading-[72px]">
        Frequently Asked Questions
      </h1>

      <div className="mt-8 h-full w-full lg:mt-[64px]">
        {faqData.map((item) => (
          <div
            onClick={() => toggleItem(item.id)}
            className={`mb-[24px] mt-[20px] flex flex-row border-b py-4 transition-colors duration-200 ${
              openItem === item.id
                ? "border-stroke-soft-200"
                : "border-[#D2D2D2]"
            } `}
            key={item.id}
          >
            <div className="flex flex-1 flex-col">
              <div className="flex flex-row justify-between">
                <span className="text-lg lg:text-2xl flex-5 text-start font-normal">
                  {item.question}
                </span>
                <img
                  src={`/assets/icons/${openItem === item.id ? "up-arrow" : "arrow-right"}.svg`}
                  alt="arrow"
                  className={`${iconClassName} h-[24px] w-[24px]`}
                />
              </div>
              {openItem === item.id && (
                <div className="mt-4 flex flex-row">
                  <p
                    className={`text-base lg:text-lg leading-relaxed text-gray-600 ${answerClassName}`}
                  >
                    {item.answer}
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
