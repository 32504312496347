export const getYouTubeThumbnail = (videoUrl: string | null) => {
  if (!videoUrl) return null;

  if (videoUrl.includes("youtube.com")) {
    const videoId = videoUrl.split("v=")[1]?.split("&")[0];
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
  }

  if (videoUrl.includes("youtu.be")) {
    const videoId = videoUrl.split("youtu.be/")[1]?.split("?")[0];
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
  }

  return null;
};
