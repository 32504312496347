import React, { useRef, useState } from "react";
import KroModal from "features/components/modals/kroModal";

import KroIframeMessageListener from "common/hooks/useKroIframeListener";
import { VerifyDonationPayload } from "features/campaign/domain/models/verifyDonationPayload";
import { DonationFailed, ThankYouForDonating } from "./donateNow";
import { DonationResponseMapperWithAnon } from "features/campaign/domain/models/donationResponse";
import useVerifyDonation from "../../hooks/useVerifyDonation";
import { queryClient } from "index";

interface OnDonateModalProps {
  isOpen: boolean;
  campaign: any;
  campaignId: string;
  onClose: () => void;
  handlePaystackOpen: () => void;
}

const OnDonateModal: React.FC<OnDonateModalProps> = ({
                                                       isOpen,
                                                       onClose,
                                                       campaign,
                                                       campaignId,
                                                       handlePaystackOpen
                                                     }) => {
  const [isDonateSuccessModalOpen, setIsDonateSuccessModalOpen] =
    useState(false);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isDonateFailedModalOpen, setIsDonateFailedModalOpen] = useState(false);
  const [link] = useState("");
  const donorInformation = useRef<DonationResponseMapperWithAnon | null>(null);

  const verifyDonationMutation = useVerifyDonation(
    (error) => {
      handleOpenDonateFailedModal();
    },
    (data) => {
      handleCloseVerificationModal();
      handleOpenDonateSuccessModal();

      queryClient.invalidateQueries({
        queryKey: ["fetchCampaign", campaignId]
      });
    }
  );

  const handleOpenDonateFailedModal = () => {
    setIsDonateFailedModalOpen(true);
  };

  const handleCloseDonationFailedModal = () => {
    setIsDonateFailedModalOpen(false);
  };

  const handleCloseVerificationModal = () => {
    setIsVerificationModalOpen(false);
  };

  const handleOpenDonateSuccessModal = () => {
    setIsDonateSuccessModalOpen(true);
  };

  // const handlePaystack = (data?: DonationResponseMapperWithAnon) => {
  //   onClose();
  //   setLink(data?.paymentLink ?? "");
  //   if (data != null) setIsIframeOpen(true);
  //   donorInformation.current = data ?? null;
  // };

  const verifyDonation = (payload: VerifyDonationPayload) => {
    setIsIframeOpen(false);

    verifyDonationMutation.mutate(payload);
  };

  return (
    <>
      <KroModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        maxWidth={608}
        showCloseButton={false}
        className="px-0 pb-5"
      >
        {/*<CampaignDonate*/}
        {/*  campaignId={campaignId}*/}
        {/*  campaignModel={campaign}*/}
        {/*  onClose={handlePaystack}*/}
        {/*/>*/}
      </KroModal>

      <KroModal
        lottieAnimation={true}
        isOpen={isDonateSuccessModalOpen}
        onClose={() => setIsDonateSuccessModalOpen(false)}
        maxWidth={510}
        showCloseButton={true}
        className="px-0 pb-0"
      >
        <ThankYouForDonating
          donationInfor={donorInformation.current!}
          campaignId={campaign?.slug === null ? campaignId : campaign?.slug}
          shareLink={window.location.origin + window.location.pathname}
        />
      </KroModal>

      <KroModal
        isOpen={isIframeOpen}
        onClose={() => setIsIframeOpen(false)}
        maxWidth={550}
        maxHeight={900}
        className="m-0 px-1 py-14 sm:py-0"
        showCloseButton={true}
      >
        <KroIframeMessageListener
          url={link}
          onSuccess={(data) => {
            const payload: VerifyDonationPayload = {
              reference: data?.reference ?? "",
              id: campaignId ?? ""
            };
            verifyDonation(payload);
          }}
        />
      </KroModal>

      <KroModal
        isOpen={isVerificationModalOpen}
        onClose={handleCloseVerificationModal}
        maxWidth={400}
        maxHeight={400}
        showCloseButton={true}
        className="px-10 pb-5"
      >
        <div>Verification...</div>
      </KroModal>

      <KroModal
        isOpen={isDonateFailedModalOpen}
        onClose={handleCloseDonationFailedModal}
        maxWidth={608}
        showCloseButton={true}
        className="px-10 pb-5"
      >
        <DonationFailed
          onDone={handleCloseDonationFailedModal}
          retryPayment={() => {
            handleCloseDonationFailedModal();
            handlePaystackOpen();
          }}
        />
      </KroModal>
    </>
  );
};

export default OnDonateModal;
