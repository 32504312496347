import { useEffect, useRef } from "react";
import { useGeoLocation } from "common/hooks/useGeolocator";
import { CurrencyData } from "@/features/campaign/domain/models/currencies";

const countryCurrencyMap: Record<string, string> = {
  // North America
  "us": "USD", // United States
  "ca": "USD", // Canada
  "mx": "USD", // Mexico

  // Europe
  "gb": "GBP", // United Kingdom
  "de": "EUR", // Germany
  "fr": "EUR", // France
  "it": "EUR", // Italy
  "es": "EUR", // Spain
  "nl": "EUR", // Netherlands
  "be": "EUR", // Belgium
  "ie": "EUR", // Ireland
  "at": "EUR", // Austria
  "gr": "EUR", // Greece
  "pt": "EUR", // Portugal
  "fi": "EUR", // Finland

  // Africa
  "ng": "NGN", // Nigeria
  "za": "USD", // South Africa
  "eg": "USD", // Egypt
  "ke": "USD", // Kenya
  "gh": "USD", // Ghana

  // Asia
  "cn": "USD", // China
  "jp": "USD", // Japan
  "in": "USD", // India
  "sg": "USD", // Singapore
  "ae": "USD", // UAE

  // Oceania
  "au": "USD", // Australia
  "nz": "USD" // New Zealand
};

export const useCurrencySelection = (
  currencies: CurrencyData[],
  setCurrency: (currency: CurrencyData) => void,
  currentCurrencyCode?: string
) => {
  const geoLocation = useGeoLocation();
  const hasSetInitialCurrency = useRef(false);

  useEffect(() => {
    if (geoLocation.location && !hasSetInitialCurrency.current && !currentCurrencyCode) {
      const countryCode = geoLocation.location.toLowerCase();
      const currencyCode = countryCurrencyMap[countryCode] || "NGN";

      const matchedCurrency = currencies.find(curr => curr.code === currencyCode);

      if (matchedCurrency) {
        setCurrency(matchedCurrency);
        hasSetInitialCurrency.current = true;
      }
    }
  }, [geoLocation.location, currencies, currentCurrencyCode, setCurrency]);

  return {
    currentCountryCode: geoLocation.location,
    isLoading: geoLocation.isLoading,
    error: geoLocation.error
  };
};
