import KroShareModal from "features/components/modals/kroShareModal";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import KroProgressBar, { variant } from "features/components/progress/kroProgressBar";
import { formatCurrency } from "common/utils/currencyFormater";
import Routes from "router/routes";
import { getCampaignDefaultImage } from "common/utils/getCampaignDefauiltImage";
import { getYouTubeThumbnail } from "common/utils/youtubeThumbnail";

const CampaignTile = ({
                        campaigns,
                        isLoading,
                        isClosedCampaign,
                        isCurrentUser
                      }: {
  isCurrentUser?: boolean;
  isClosedCampaign?: boolean;
  campaigns?: CampaignModelMapper[];
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();
  const userCampaign = isClosedCampaign
    ? campaigns?.filter((campaign) => campaign.status === "closed")
    : campaigns?.filter((campaign) => campaign.status === "published");
  const [showCampaignDetailsShareModal, setShowCampaignDetailsShareModal] =
    useState<boolean>(false);
  if (userCampaign?.length === 0)
    return (
      <div className="mt-[30px] flex items-center justify-center">
        <p className="text-gray-400">No campaigns found</p>
      </div>
    );

  const handleCampaignDetailsShareModalOpen = () => {
    setShowCampaignDetailsShareModal(true);
  };
  return (
    <>
      {userCampaign?.map((campaign) => (
        <>
          <KroShareModal
            campaignId={campaign?.slug === null ? campaign?.id : campaign?.slug}
            isOpen={showCampaignDetailsShareModal}
            onClose={() => setShowCampaignDetailsShareModal(false)}
          />
          <div
            key={campaign.id}
            className={`${isLoading && "animate-pulse"} items-center-justify-center mt-[16px] flex min-h-[104px] w-[345px] flex-col rounded-[24px] ${isLoading ? "bg-gray-100" : "bg-[#F2F6F2]"} lg:w-[560px]`}
          >
            <div
              className={`] mb-[32px] ml-[16px] mt-[24px] flex h-full w-[313px] flex-col rounded-[24px] lg:min-h-[150px] ${!isLoading && "border"} ${!isLoading && "border-[#E1E4EA]"} ${!isLoading && "bg-white"} px-[12px] py-[13px] lg:my-[40px] lg:ml-[24px] lg:mr-[21px] lg:w-[515px] lg:px-[12px] lg:py-[12px]`}
            >
              {!isLoading && (
                <div className="mx-[12px] flex flex-row lg:my-[12px]">
                  {
                    <img
                      className="h-[80px] min-w-[85px] flex-1 rounded-[9px] object-cover lg:max-w-[85px]"
                      src={
                        getYouTubeThumbnail(campaign?.videoUrl ?? "") ??
                        (campaign?.imageUrls?.length === 0
                          ? getCampaignDefaultImage(campaign?.category)
                          : campaign?.imageUrls?.at(0) ?? "")
                      }
                      alt={campaign?.title}
                    />
                  }
                  <div className="ml-[16px] flex flex-col">
                    <p className="font-dm-sans text-[12px] font-semibold leading-[18.23px] tracking-[-0.5%]">
                      {campaign.title}
                    </p>

                    <div className="mt-[12px]">
                      <KroProgressBar
                        height="h-3"
                        customStyle={"lg:w-[360px] w-[180px]"}
                        varient={variant.gradient}
                        amountRaised={formatCurrency(
                          campaign?.amountRaised ?? ""
                        )}
                        amountToBeRasied={formatCurrency(
                          campaign?.targetAmount ?? ""
                        )}
                      />
                    </div>

                    <div className="mb-[2px] flex flex-row items-center justify-between">
                      <span className="text-[12px] font-semibold leading-[20px] tracking-[-0.006em] text-[#0E121B]">
                        <span>
                          {formatCurrency(campaign?.amountRaised ?? 0, true)}
                        </span>{" "}
                        <span className="font-normal text-neutral-400">
                          raised of
                        </span>{" "}
                        <span className="font-normal text-neutral-400">
                          {formatCurrency(campaign?.targetAmount ?? 0, true)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="mx-[12px] mb-[12px] mt-[12px] flex flex-row gap-[8px]">
                {isCurrentUser && !isClosedCampaign && (
                  <button
                    onClick={() => {
                      if (campaign?.slug) {
                        navigate(Routes.EDIT_CAMPAIGN(campaign?.slug ?? ""));
                      } else {
                        navigate(Routes.EDIT_CAMPAIGN(campaign?.id ?? ""));
                      }
                    }
                    }

                    className="h-[36px] w-[106px] items-center rounded-[32px] bg-[#4FBA6D] text-center font-dm-sans text-[12px] font-medium leading-[18px] tracking-[-0.5%] text-white"
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => {
                    if (campaign?.slug) {
                      navigate(Routes.CAMPAIGN_DETAILS(campaign?.slug ?? ""));
                    } else {
                      navigate(Routes.CAMPAIGN_DETAILS(campaign?.id ?? ""));
                    }
                  }
                  }
                  className="h-[36px] w-[106px] items-center rounded-[32px] border border-neutral-200 bg-neutral-50 text-center font-dm-sans text-[12px] font-medium leading-[18px] tracking-[-0.5%] text-[#0F0F0F]"
                >
                  View
                </button>
                <button
                  onClick={handleCampaignDetailsShareModalOpen}
                  className="flex h-[36px] w-[106px] flex-row items-center justify-center gap-[4px] rounded-[32px] border border-neutral-200 bg-neutral-50"
                >
                  <p
                    className="text-center font-dm-sans text-[12px] font-medium leading-[18px] tracking-[-0.5%] text-[#0F0F0F]">
                    Share
                  </p>
                  <img src="/assets/icons/share_large.svg" alt="share" />
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default CampaignTile;
