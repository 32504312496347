import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useShareUrlState = () => {
  const [searchParams] = useSearchParams();
  const [isFromShare, setIsFromShare] = useState(false);
  const [shouldShowShare, setShouldShowShare] = useState(false);

  useEffect(() => {
    const hasShareParam = searchParams.has('share');
    if (hasShareParam) {
      setIsFromShare(true);
      setShouldShowShare(true);
    }
  }, [searchParams]);

  return {
    isFromShare,
    shouldShowShare,
    setShouldShowShare
  };
};
