import Routes from "router/routes";
import { useNavigate } from "react-router-dom";

export const InnerFundRaisingButton: React.FC<{
  isTransparent: boolean;
  className?: string;
  title?: string;
  isLoading?: boolean;
  onClick?: () => void;
}> = ({ isTransparent, className, title, onClick, isLoading }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={onClick ? onClick : () => navigate(Routes.CREATE_CAMPAIGN)}
      className={`${className} flex h-[56px] w-[229px] ${isTransparent ? "border border-[#4FBA6D]" : ""} transform items-center justify-center rounded-[60px] ${
        isTransparent ? "bg-transparent" : "bg-[#022928]"
      } <div className="mb-8 lg:mt-[54px]"> <InnerFundRaisingButton isTransparent={false} /> </div> mt-8 text-white transition-transform hover:scale-105 active:scale-95 lg:flex lg:h-[72px] lg:min-w-[245px] ${className}`}
    >
      {isLoading ? (
        <div className="h-6 w-6 animate-spin rounded-full border-4 border-white border-t-transparent"></div>
      ) : (
        <>
          <p
            className={`text-lg mr-2 text-left font-inter font-medium leading-[18px] ${isTransparent ? "text-[#4FBA6D]" : "text-white"}`}
          >
            {title ? title : "Start Fundraising"}
          </p>
          <img
            className="h-5 w-5"
            src={`/assets/icons/${isTransparent ? "green-arrow.svg" : "Arrow.svg"}`}
            alt="arrow"
          />
        </>
      )}
    </button>
  );
};
