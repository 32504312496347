import { useForm } from "@tanstack/react-form";
import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";
import Validation from "common/utils/validation";
import KroAmountInputField from "features/components/inputs/KroAmountInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Routes from "router/routes";
import EmailVerificationModal from "features/onboarding/presentation/components/create_account/EmailVerificationModal";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import KroRadioButton from "features/components/inputs/kroRadioButton";

import { toast } from "react-toastify";
import { getSessionUser } from "common/store/useAuthStore";
import KroCurrencyDropDown from "features/components/dropdowns/kroCurrencyDropDown";
import { useCurrencySelection } from "features/campaign/presentation/hooks/useCurrencySelection";
import KroLoadingIndicator, { LoaderType } from "features/components/loader/kroCircularIndicator";
import useFetchCampaign from "features/campaign/presentation/hooks/useFetchCampaign";
import PresetAmounts from "features/campaign/presentation/components/campaignDetails/campaignDonatePresetAmount";
import useRateConverter from "features/campaign/presentation/hooks/useRateConverter";
import { useFetchSupportedCurrencies } from "features/campaign/presentation/hooks/useFetchSupportedCurrencies";
import { formatCurrency } from "common/utils/currencyFormater"; // Import the hook

const EnterAmountPage = () => {
  const { formData, setFormData } = useCampaignDonateForm();
  const navigate = useNavigate();
  const user = getSessionUser();

  const { id: campaignId } = useParams<{ id: string }>();
  const { data: campaign } = useFetchCampaign(campaignId || "");
  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.CAMPAIGN_DETAILS(campaignId ?? ""));
    }
  };


  const form = useForm({
    defaultValues: {
      amount: formData.amount ?? "",
      currencyCode: formData.currencyCode,
      firstName: formData.firstName ?? "",
      isEmailVerified: true,
      lastName: formData.lastName ?? "",
      email: formData.email ?? "",
      reference: formData.reference ?? "",
      paymentMethod: formData.paymentMethod ?? "",
      donateAnonymously: user?.privacySettings?.donateAnonymously === true ? true : formData.donateAnonymously
    },

    onSubmitInvalid: () => {
    },

    onSubmit: (values) => {
      if (!values.value.isEmailVerified) {
        toast.info("Please verify your email address to proceed");
        return;
      }


      setFormData({
        amount: values.value.amount,
        firstName: values.value.firstName,
        isEmailVerified: values.value.isEmailVerified,
        lastName: values.value.lastName,
        email: values.value.email,
        donateAnonymously: values.value.donateAnonymously
      });

      navigate(Routes.PAYMENT_SUMMARY(campaignId ?? ""), {
        state: { amount: values.value.amount, currencyCode: currency }
      });

    }
  });

  const handleIsAnonymousDonationToggle = (checked: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      donateAnonymously: checked
    }));
  };


  const { data: currencies, error, isLoading: isLoadingCurrencies, refetch } = useFetchSupportedCurrencies();

  if (error) {
    toast.error(
      ({ closeToast }) => (
        <div className="flex items-center justify-between gap-2">
          <span>{error?.message ?? ""}</span>
          <span
            onClick={() => {
              refetch();
              closeToast();
            }}
            className="text-sm cursor-pointer text-black underline hover:text-gray-700"
          >
            Reload
          </span>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false
      }
    );
  }


  const currency = form.useStore(
    (state) => state.values.currencyCode
  );

  const amount = form.useStore(
    (state) => state.values.amount
  );

  const { isLoadingRateConverter, errorLoadingRateConverter, ratesData } = useRateConverter({
    baseCurrency: currency?.code
  });

  const [ngnEquivalent, setNgnEquivalent] = useState<number | null>(null);

  useEffect(() => {
    if (ratesData && amount && currency && currency.code !== "NGN") {
      if (ratesData.rates && ratesData.rates.NGN) {
        const convertedAmount = parseFloat(amount) * ratesData.rates.NGN;
        setNgnEquivalent(convertedAmount);
      }
    } else {
      setNgnEquivalent(null);
    }
  }, [ratesData, amount, currency]);


  const { isLoading: isLoadingCurrency } = useCurrencySelection(
    currencies ?? [],
    (currency) => {
      form.setFieldValue("currencyCode", currency);
    },
    currency
  );

  useEffect(() => {
    if (user?.email && form.state.values.email === user.email) {
      form.setFieldValue("isEmailVerified", true);
    }
  }, [user?.email, form.state.values.email, form]);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleCloseVerificationModal = (isVerified: boolean) => {
    if (isVerified) form.setFieldValue("isEmailVerified", isVerified);
    setShowVerificationModal(false);
  };

  if (isLoadingCurrency || isLoadingCurrencies) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }


  return (
    <>
      <EmailVerificationModal
        type={EOTPUseCases.SIGN_IN}
        email={form.state.values.email}
        onClose={handleCloseVerificationModal}
        isOpen={showVerificationModal}
      />
      <div className="flex min-h-[calc(100vh-280px)] flex-col">
        <div className="flex flex-grow items-center justify-center px-4">
          <div className="w-full max-w-[608px]">
            <div className="mt-3 flex flex-col items-start rounded-3xl border border-neutral-200 p-6">
              <KroBackButton
                title="Enter amount"
                onclick={handleBackButton}
                classname="lg:flex hidden"
              />
              <p
                className="mb-4 flex text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] lg:hidden">
                Enter amount & details
              </p>

              <form.Field
                name="amount"
                validators={{
                  onChange: ({ value }) => Validation.required(value)
                }}
                children={(field) => {
                  return (
                    <>
                      <KroAmountInputField
                        id={field.name}
                        name={field.name}
                        errorMessage={field.state.meta.errors.join(", ")}
                        title={"Enter Amount"}
                        leadingAddon={
                          <form.Field name={"currencyCode"}
                                      children={(currencyField) => {
                                        return <KroCurrencyDropDown
                                          currencies={currencies ?? []}
                                          selectedCurrency={currencyField.state.value ?? ""}
                                          onCurrencyChange={(currency) => {
                                            currencyField.handleChange(currency);
                                            form.setFieldValue("currencyCode", currency);
                                            // setSelectedCurrency(currency);
                                          }}
                                        />;
                                      }} />}
                        className="mt-7 w-full rounded-[20px] border border-gray-300 bg-[#FAFAFA] px-3"
                        value={field.state.value}
                        placeHolder={"Enter amount"}
                        onChange={(e) => field.handleChange(e)}
                      />
                      {field.state.meta.errors ? (
                        <em role="alert">
                          {field.state.meta.errors.join(", ")}
                        </em>
                      ) : null}
                      {campaign?.targetAmount !== undefined &&
                        (campaign?.targetAmount ?? 0) > 0 && (
                          <PresetAmounts
                            className="mt-0 w-full"
                            currencySymbol={currency?.symbol}
                            targetAmount={campaign?.targetAmount ?? 0}
                            onPresetSelected={(amount) => {
                              form.setFieldValue("amount", amount);
                              field.handleChange(amount.toString());
                            }}
                          />
                        )}
                    </>
                  );
                }}
              />
              {currency && currency.code !== "NGN" && amount && (
                <div
                  className={`flex ${isLoadingRateConverter ? "animate-pulse" : "animate-fadeIn"} bg-[#EBF1FF] min-h-[40px] rounded-[20px] px-[20px] w-full py-[8px] max-w-full mt-[24px] mb-[10px]`}>
                  {isLoadingRateConverter ? (
                    <p className="text-[#0739A7] font-dm-sans font-medium text-[16px] leading-[24px] tracking-[-0.5%]">
                      Loading equivalent rate in NGN...
                    </p>
                  ) : errorLoadingRateConverter ? (
                    <p className="text-red-500 font-dm-sans font-medium text-[16px] leading-[24px] tracking-[-0.5%]">
                      Could not load conversion rate
                    </p>
                  ) : ngnEquivalent ? (
                    <p className="text-[#0739A7] font-dm-sans font-medium text-[16px] leading-[24px] tracking-[-0.5%]">
                      Fundraiser receives {formatCurrency(ngnEquivalent, true)}
                    </p>
                  ) : null}
                </div>
              )}
              <div className="mt-6 w-full border-t border-[px] border-stroke-soft-200">
                <p className="mt-6 align-middle font-dm-sans text-[18px] font-medium leading-[26px] tracking-[-0.5%]">
                  Donor Details
                </p>
              </div>


              <div
                className="mt-3 flex w-full flex-col gap-1 border-t border-[px] border-stroke-soft-200 pt-6 lg:flex-row lg:justify-between">
                <div className="flex w-full flex-col lg:w-[256px]">
                  <form.Field
                    name="firstName"
                    validators={{
                      onChange: ({ value }) => {
                        const isEmpty = Validation.required(value);
                        if (isEmpty !== undefined) return isEmpty;
                        return Validation.oneWord(value);
                      }
                    }}
                    children={(field) => {
                      return (
                        <KroTextField
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          errorMessage={field.state.meta.errors.join(", ")}
                          onChange={(e) => field.handleChange(e.target.value)}
                          labelClassName="text-body-sm text-neutral-700 mb-2"
                          label="First Name"
                          type={KroInputFieldType.TEXT}
                          placeHolder="First Name"
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex w-full flex-col lg:w-[256px]">
                  <form.Field
                    name="lastName"
                    validators={{
                      onChange: ({ value }) => {
                        const isEmpty = Validation.required(value);
                        if (isEmpty !== undefined) return isEmpty;
                        return Validation.oneWord(value);
                      }
                    }}
                    children={(field) => {
                      return (
                        <KroTextField
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          errorMessage={field.state.meta.errors.join(", ")}
                          onChange={(e) => field.handleChange(e.target.value)}
                          labelClassName="text-body-sm text-neutral-700 mb-2"
                          label="Last Name"
                          type={KroInputFieldType.TEXT}
                          placeHolder="Last Name"
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <form.Field
                name="email"
                validators={{
                  onChange: ({ value }) => Validation.email(value)
                }}
                children={(field) => {
                  return (
                    <KroTextField
                      margin="mb-0"
                      label="Email Address"
                      subtitle="(We will send a donation receipt here)"
                      type={KroInputFieldType.TEXTADDONS}
                      placeHolder={"e.g. john.doe@gmail.com"}
                      id={field.name}
                      className={"mb-3"}
                      name={field.name}
                      fillColor="bg-neutral-50"
                      errorMessage={field.state.meta.errors.join(", ")}
                      value={field.state.value}
                      onChange={(e) => {
                        return field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <div className="mb-[48px] mt-6">
                <form.Field
                  name="donateAnonymously"
                  children={(field) => (
                    <KroRadioButton
                      id={field.name}
                      label="Make my donation anonymous"
                      name={field.name}
                      isChecked={field.state.value}
                      value="donateAnonymously"
                      onToggle={(value) => {
                        field.handleChange(value);
                        return handleIsAnonymousDonationToggle(value);
                      }}
                      useSquareCheckbox={false}
                    />
                  )}
                />
              </div>

              <KroButton
                onclick={() => {
                  form.handleSubmit();
                }}
                title="View summary"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterAmountPage;