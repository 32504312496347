import { useState, FC } from "react";
import { Donations } from "features/campaign/domain/models/donations";
import { formatRelativeDate } from "common/utils/dateUtils";
import useDiceBear from "common/hooks/useDiceBear";

interface CampaignUpdateCardProps {
  donors: Donations[] | undefined;
  isLoading?: boolean;
}

const CampaignUpdateCard: FC<CampaignUpdateCardProps> = ({
  donors,
  isLoading,
}) => {
  const [visibleCount, setVisibleCount] = useState(10);

  const displayedDonors = [...(donors || [])].reverse().slice(0, visibleCount);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => {
      const remainingDonors = (donors?.length ?? 0) - prevCount;
      return prevCount + (remainingDonors < 10 ? remainingDonors : 10);
    });
  };

  return (
    <div className="min-w-[340px] gap-8 rounded-[32px] border border-[#E5E5E5] p-4 px-6 py-4 lg:max-w-[413px]">
      <header className="mb-4 h-8">
        <div className="flex items-center justify-between">
          <div className="flex gap-[6px]">
            <img src="/assets/icons/trophy-line.svg" alt="trophy" />
            <h2 className="text-left text-[16px] font-medium leading-[24px] tracking-[-0.011em] text-strong-950">
              Updates
            </h2>
          </div>
          <img src="/assets/icons/top_icon.svg" alt="icon" />
        </div>
      </header>
      <div className="border-t border-stroke-soft-200"></div>

      {isLoading ? (
        <div className="animate-pulse">
          {Array.from({ length: 10 }).map((_, index) => (
            <ListTile.Skeleton key={index} />
          ))}
        </div>
      ) : donors && donors.length > 0 ? (
        <ul className="animate-fadeIn">
          {displayedDonors.map((donor, index) => (
            <ListTile key={index} donor={donor} />
          ))}
        </ul>
      ) : (
        <p className="mt-4 text-center text-body-lg text-sub-600">
          No donations yet
        </p>
      )}

      {donors && visibleCount < donors.length && (
        <button
          className="mt-4 flex h-12 w-full items-center justify-center text-body-lg font-medium"
          onClick={handleShowMore}
        >
          See More
        </button>
      )}
    </div>
  );
};

const ListTile: FC<{ donor: Donations }> & { Skeleton: FC } = ({ donor }) => {
  const avatar = useDiceBear({
    seed: `${donor?.first_name ?? "A"} ${donor?.last_name ?? "A"}`,
  });

  return (
    <li className="mt-4 flex h-14 items-center justify-between">
      <div className="flex gap-4">
        <img
          className="rounded-full bg-slate-950"
          src={avatar}
          alt={
            donor.is_anonymous
              ? "Anonymous Donor"
              : `${donor.first_name} ${donor.last_name}`
          }
        />
        <div>
          <h4 className="mb-1 text-body-md font-medium leading-[20px] tracking-[-0.006em] text-strong-950">
            {donor.is_anonymous
              ? "Anonymous"
              : `${donor.first_name} ${donor.last_name?.at(0)?.toUpperCase()}.`}
          </h4>
          <p className="text-body-sm leading-[16px] text-sub-600">
            Donated ₦{donor.amount?.toLocaleString()}
          </p>
        </div>
      </div>
      <span className="text-left font-inter text-body-md font-normal leading-[22px] tracking-[-0.005em] text-[#525252]">
        {formatRelativeDate(new Date(donor.createdAt))}
      </span>
    </li>
  );
};

ListTile.Skeleton = () => {
  return (
    <li className="mt-4 flex h-14 animate-pulse items-center justify-between">
      <div className="flex flex-1 flex-shrink-0 gap-4">
        <div className="h-10 w-10 rounded-full bg-gray-100" />
        <div className="flex-1">
          <div className="mb-1 h-5 w-full rounded bg-gray-100"></div>
          <div className="h-[16px] w-full rounded bg-gray-100"></div>
        </div>
      </div>
      <span className="ml-2 h-[22px] w-10 rounded bg-gray-100"></span>
    </li>
  );
};

export default CampaignUpdateCard;
