import { useQuery } from "@tanstack/react-query";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useFetchCampaigns = () => {
  const {
    data: campaigns,
    isLoading,
    error,
    refetch,
  } = useQuery<CampaignModelMapper[], Error>({
    queryKey: ["fetchCampaigns"],
    queryFn: () => DashboardService.fetchCampaigns(),
  });

  return { campaigns, isLoading, error, refetch };
};

export default useFetchCampaigns;
