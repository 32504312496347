import { useMutation } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";
import { UpdateProfilePayload } from "../../domain/model/updateProfilePayload";
import { ProfileModel } from "../../domain/model/profileModel";

const DashboardService = new DashboardServiceImpl();

const useUpdateProfile = (
  onError?: (
    error: Error,
    variables: UpdateProfilePayload,
    context: unknown,
  ) => Promise<unknown> | unknown,
  onSuccess?: (
    data: ProfileModel,
    variables: UpdateProfilePayload,
    context: unknown,
  ) => Promise<ProfileModel> | unknown,
) => {
  return useMutation({
    mutationKey: ["updateProfile"],
    mutationFn: (payload: UpdateProfilePayload) =>
      DashboardService.updateProfile(payload),
    onError,
    onSuccess,
  });
};

export default useUpdateProfile;
